import {
  CoverageType,
  OccurrenceByUserCoverageQuery,
} from 'services/occurrence/types'
import OccurrenceDriver from 'services/occurrence'
import { useQuery } from '@tanstack/react-query'
import { useUserInfo } from 'shared/hooks'

async function fetchAllOccurrences(query?: OccurrenceByUserCoverageQuery) {
  const occurrences = await OccurrenceDriver.findAllOccurrencesByCoverage({
    ...query,
  })

  return occurrences
}

export const useGetAllOccurrencesByCoverage = (
  query?: OccurrenceByUserCoverageQuery,
) => {
  const { userInfo } = useUserInfo()

  return useQuery({
    queryKey: ['occurrences-management', query],
    queryFn: async () => {
      return await fetchAllOccurrences({
        userId: userInfo.id,
        coverageTypes: [
          CoverageType.TACTICAL_DISPLACEMENT_OPERATOR,
          CoverageType.OCCURRENCE_ATTENDANT,
          CoverageType.OCCURRENCE_MANAGEMENT,
        ].join(','),
        stateNames: [
          'AVAILABLE',
          'IN_PROGRESS',
          'QUEUED',
          'TRAVEL_WAITING',
          'TRAVEL_SENT',
          'TRAVEL_VIEWED',
          'TRAVEL_STARTED',
          'TRAVEL_FINISHED',
        ].join(','),
        ...query,
      })
    },
    staleTime: 0,
    refetchInterval: 10 * 3000,
    refetchIntervalInBackground: true,
  })
}
