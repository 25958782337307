import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

import { Loading, ServiceOrderForm } from '../../components'

import {
  ServiceOrderPayload,
  usePostServiceOrder,
} from 'services/serviceOrderV2'
import { CreateSoError } from 'services/serviceOrder'
import { useToast, useUserInfo } from 'shared/hooks'
import { formatDecimal } from 'utilities/date'

import styles from './styles.module.scss'
import { handleRenderState } from 'utilities/handleRenderState'

type StateType = {
  account?: {
    id: string
    aggregatedName: string
    code: string
  }
}

const Create = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as StateType

  const { mutate, isPending } = usePostServiceOrder()

  const { userInfo } = useUserInfo()

  const onSubmit = useCallback(
    (data: ServiceOrderPayload) => {
      mutate(
        {
          ...data,
          userId: userInfo.id,
          status: data.status || 'OPEN',
        },
        {
          onSuccess: () => {
            addToast({ message: 'Ordem de serviço criada com sucesso.' })
            navigate(-1)
          },
          onError: (error) => {
            const formatterError = (error as unknown as CreateSoError).response
            const message =
              formatterError?.status === 412
                ? `Esta conta já possui uma ordem de serviço (${formatDecimal(
                    formatterError.data?.number || 0,
                  )}).`
                : 'Não foi possível cadastrar a ordem de serviço. Tente novamente'
            addToast({ message, type: 'alert' })
          },
        },
      )
    },
    [addToast, navigate],
  )

  return (
    <main className={styles.main}>
      <div className={styles.title}>
        <span>Nova ordem de serviço</span>
        <hr />
      </div>
      {
        {
          view: (
            <ServiceOrderForm
              accountId={state?.account?.id}
              onSubmit={onSubmit}
            />
          ),
          loading: <Loading />,
          error: <></>,
          empty: <></>,
        }[
          handleRenderState({
            data: true,
            isError: false,
            isLoading: isPending,
            isEmpty: false,
          })
        ]
      }
    </main>
  )
}

export default Create
