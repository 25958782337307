import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'

import { ReactComponent as OccurrenceIcon } from 'assets/svg/occurrenceExclamation.svg'

import { HistoryEvent, HistoryIntervention } from 'services/attendance/types'
import {
  Button,
  ProtectedImage,
  Tooltip,
  Gallery,
  Icon,
  IconButton,
  ButtonV2,
  Tag,
} from 'components'
import { Loader as MainLoader } from 'domains/occurrence/components'
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg'
import { useGetOccurrenceHistory } from 'services/attendance'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { useTooltipVisibility, useTimer, useToggle } from 'shared/hooks'
import { formatDocument } from 'utilities/masks'
import { OccurrenceHistory } from '../../types'

import {
  getOccurrenceStateName,
  occurrenceTypesLabel,
  handleHistoryItems,
  onInspectAccount,
} from './utils'

import {
  EventCard,
  ChangeStateCard,
  InterventionCard,
  AutomaticRuleCard,
  ChangeStateKebab,
} from './components'
import { useGetOccurrenceImages } from 'services/occurrence'

interface HistoryProps {
  occurrence: OccurrenceHistory
  isUserInAttendance: boolean
  onClose: () => void
  isVisible: boolean
  canDisableHabitDeviation: boolean
}

const History: React.FC<HistoryProps> = ({
  occurrence,
  onClose,
  isVisible,
  isUserInAttendance,
  canDisableHabitDeviation,
}) => {
  const [selectedEventImage, setSelectedEventImage] = useState<string>()
  const { replaceUrl } = usePersistentTab('occurrence-management-tab')

  const galleryModal = useToggle()
  const navigate = useNavigate()

  const { data: occurrenceHistory, isLoading: isLoadingOccurrenceHistory } =
    useGetOccurrenceHistory(occurrence?.id)
  const { data: images } = useGetOccurrenceImages(occurrence?.id)

  const isLoading = (!occurrence?.id && isVisible) || isLoadingOccurrenceHistory

  const timer = useTimer({
    initialTime: occurrence?.createdAt ?? new Date().getTime(),
    finalTime: occurrence?.finishedAt,
  })

  const { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility()

  const handleView = useCallback(
    (occurrenceId: string) => {
      navigate(`/occurrence/attendance/${occurrenceId}`, {
        state: {
          readonly: true,
          from: 'management-occurrence',
        },
      })
    },
    [navigate],
  )

  const handleViewImage = useCallback(
    (imageId: string) => {
      setSelectedEventImage(imageId)
      galleryModal.show()
    },
    [galleryModal],
  )

  const handleRenderState = (isLoading: boolean, data?: OccurrenceHistory) =>
    !isLoading && data ? 'view' : 'loading'

  const serviceType = occurrence.patrimony?.serviceType?.name
  const serviceTypeColor = occurrence.patrimony?.serviceType?.color

  return (
    <>
      {galleryModal.isVisible && (
        <Gallery
          imageId={selectedEventImage || ''}
          onClose={galleryModal.hide}
          images={[
            ...(images || []),
            {
              id: occurrence.patrimony.imageId,
              url: '',
              creation: new Date(),
            },
          ]}
        />
      )}

      <div
        className={[
          styles.container,
          isVisible ? styles.show : styles.hide,
        ].join(' ')}
      >
        <>
          <header>
            <div className={styles.occurrenceHeader}>
              <h1 className={styles.headerTitle}>
                {occurrence && getOccurrenceStateName(occurrence.stateName)}
                {occurrence.number && ` #${occurrence?.number}`}
              </h1>
              <div className={styles.buttons}>
                <ChangeStateKebab
                  occurrence={occurrence}
                  canDisableHabitDeviation={canDisableHabitDeviation}
                  isUserInAttendance={isUserInAttendance}
                  onClose={onClose}
                />
                <IconButton onClick={onClose}>
                  <Icon name="close-xlg" color="element" />
                </IconButton>
              </div>
            </div>
            {occurrence && (
              <div
                className={[
                  styles.occurrenceInfo,
                  styles[occurrence?.type?.name || ''],
                  styles[occurrence?.stateName || ''],
                ].join(' ')}
              >
                <span
                  ref={ref}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <OccurrenceIcon />
                  {[
                    occurrenceTypesLabel[occurrence?.type?.name],
                    occurrence.title,
                  ]
                    .filter(Boolean)
                    .join(' - ')}
                </span>
                {isTooltipVisible && (
                  <Tooltip
                    type="informative"
                    isVisible={isTooltipVisible}
                    parentRef={ref}
                  >
                    {occurrence.title}
                  </Tooltip>
                )}
                <div>
                  <span>{timer}</span>
                </div>
              </div>
            )}
          </header>
          {
            {
              loading: <MainLoader />,
              view: (
                <>
                  {occurrence?.patrimony?.imageId && (
                    <section className={styles.imageBox}>
                      <ProtectedImage
                        height={130}
                        alt="patrimônio"
                        imageId={occurrence.patrimony.imageId}
                        onClick={() =>
                          handleViewImage(occurrence.patrimony.imageId)
                        }
                      />
                    </section>
                  )}
                  <div
                    className={[
                      styles.content,
                      occurrence?.patrimony?.imageId && styles.withImage,
                    ].join(' ')}
                  >
                    <section>
                      <h2>{occurrence?.customer?.name}</h2>
                      {occurrence?.customer?.secondName && (
                        <h3>{occurrence.customer.secondName}</h3>
                      )}
                      <h4>
                        {formatDocument(occurrence?.customer?.document || '')}
                      </h4>
                    </section>
                    <section>
                      <h3 className={styles.patrimonyName}>
                        {occurrence?.patrimony?.name}
                      </h3>
                      <h4>
                        {`${occurrence?.account?.code} - ${occurrence?.account?.name || ''}`}
                      </h4>
                      {!!serviceType && (
                        <Tag
                          color="green"
                          {...(serviceTypeColor && {
                            style: {
                              backgroundColor: serviceTypeColor,
                              color: serviceTypeColor,
                            },
                          })}
                          singleColor={!!serviceTypeColor}
                        >
                          {serviceType}
                        </Tag>
                      )}
                      <ButtonV2
                        appearance="tertiary"
                        onClick={() => onInspectAccount(occurrence, replaceUrl)}
                      >
                        <Icon name="open" width={12} />
                        Ver conta
                      </ButtonV2>
                    </section>
                    <div className={styles.separator} />
                    <section>
                      <h4 className={styles.addressTitle}>Endereço</h4>
                      <p className={styles.address}>
                        {`${occurrence?.patrimony?.address}, ${occurrence?.patrimony?.number}, ${occurrence?.patrimony?.district?.name}, ${occurrence?.patrimony?.city?.name}, ${occurrence?.patrimony?.state?.name}`}
                      </p>
                    </section>
                    {occurrence?.priority && (
                      <span className={styles.priority}>
                        Prioridade {occurrence.priority}
                      </span>
                    )}
                    <div className={styles.fullSeparator} />
                    <div className={styles.eventHeader}>
                      <h2>Últimos eventos</h2>
                      <Button
                        icon={LinkIcon}
                        type="tertiary"
                        buttonTitle="Ver ocorrência"
                        onClick={() => handleView(occurrence?.id || '')}
                      />
                    </div>
                    <ul>
                      {occurrenceHistory &&
                        handleHistoryItems({
                          ...occurrenceHistory,
                          interventions: occurrenceHistory.interventions.map(
                            (intervention) => {
                              return intervention
                            },
                          ),
                        }).map((intervention) => {
                          const options = {
                            EVENT: (
                              <EventCard
                                key={intervention.value.id}
                                onImage={handleViewImage}
                                event={intervention.value as HistoryEvent}
                              />
                            ),
                            INTERVENTION: (
                              <InterventionCard
                                key={intervention.value.id}
                                onImage={handleViewImage}
                                intervention={
                                  intervention.value as HistoryIntervention
                                }
                              />
                            ),
                            STATE_CHANGE: (
                              <ChangeStateCard
                                key={intervention.value.id}
                                intervention={
                                  intervention.value as HistoryIntervention
                                }
                              />
                            ),
                            AUTOMATIC_RULE: (
                              <AutomaticRuleCard
                                key={intervention.value.id}
                                intervention={
                                  intervention.value as HistoryIntervention
                                }
                              />
                            ),
                          }

                          return options[intervention.type]
                        })}
                    </ul>
                  </div>
                </>
              ),
            }[handleRenderState(isLoading, occurrence)]
          }
        </>
      </div>
    </>
  )
}

export default History
