import { FC, SVGProps, Suspense, forwardRef, lazy, useMemo } from 'react'

import styles from './styles.module.scss'
import { IconName } from './icon-names'
import joinClassNames from 'utilities/joinClassNames'

export const colors = {
  neutral: 'neutral',
  gray: 'gray',
  yellow: 'yellow',
  'medium-gray': 'medium-gray',
  'bold-gray': 'bold-gray',
  danger: 'danger',
  brand: 'brand',
  element: 'element',
  'element-light': 'element-light',
  'element-extralight': 'element-extralight',
  'element-medium': 'element-medium',
  'element-bold': 'element-bold',
}

export type Colors = keyof typeof colors
type SVGElement = SVGProps<SVGSVGElement>

export interface IconProps extends Omit<SVGElement, 'fill' | 'ref'> {
  name: IconName
  color?: Colors
}

const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ name, className, width = 16, height, color = 'brand', ...props }, ref) => {
    const IconComponent = useMemo(
      () =>
        lazy(async () => ({
          default: (await import(`./svg/${name}.svg`))
            .ReactComponent as FC<SVGElement>,
        })),
      [name],
    )

    return (
      <Suspense>
        <IconComponent
          ref={ref}
          {...props}
          role="img"
          width={width}
          height={height || width}
          style={{ minWidth: width, minHeight: height || width }}
          className={joinClassNames(styles[color], className)}
        />
      </Suspense>
    )
  },
)

Icon.displayName = 'Icon'

export default Icon
