import { useQuery } from '@tanstack/react-query'
import { DeviceDriver } from 'services/device'
import { DeviceTypeResponse } from 'services/device/types'

type UseGetDeviceTypeProfiles = {
  deviceType: DeviceTypeResponse[] | undefined
  isFetchingDeviceProfiles: boolean
}

async function getDeviceType(deviceTypeCode: string) {
  const result = await DeviceDriver.queryDeviceType({ code: deviceTypeCode })

  return result.data
}

export const useGetDeviceType = (
  deviceTypeCode: string,
  enabled?: boolean,
): UseGetDeviceTypeProfiles => {
  const { data: deviceType, isFetching: isFetchingDeviceProfiles } = useQuery({
    queryKey: ['device-type', deviceTypeCode],
    queryFn: () => getDeviceType(deviceTypeCode),
    enabled,
  })

  return { deviceType, isFetchingDeviceProfiles }
}
