import {
  forwardRef,
  ImgHTMLAttributes,
  useRef,
  useEffect,
  useState,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import ImageDriver from '../../services/image'
import Skeleton from 'components/Skeleton'

type ProtectedImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  imageId?: string
}

const fetchImage = async (imageId: string) => {
  const source = await ImageDriver.getImage(imageId)
  return source
}

export const ProtectedImage = forwardRef<HTMLImageElement, ProtectedImageProps>(
  ({ imageId, alt, ...rest }, ref) => {
    const [shouldLoad, setShouldLoad] = useState(false)
    const elementRef = useRef<HTMLDivElement>(null)

    const width = rest.width ?? 114
    const height = rest.height ?? 114

    useEffect(() => {
      const wrapper = elementRef.current
      if (!wrapper) return

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setShouldLoad(true)
              observer.disconnect()
            }
          })
        },
        {
          threshold: 0,
        },
      )

      observer.observe(wrapper)

      return () => observer.disconnect()
    }, [])

    const { data } = useQuery({
      queryKey: ['image', imageId],
      queryFn: () => fetchImage(imageId),
      enabled: shouldLoad,
      staleTime: 1000 * 60 * 60,
    })

    if (!data)
      return (
        <div ref={elementRef}>
          <Skeleton width={width} height={height} />
        </div>
      )

    return <img src={data} alt={alt} ref={ref} {...rest} loading="lazy" />
  },
)

ProtectedImage.displayName = 'ProtectedImage'
