import {
  CentralPartition,
  PartitionFragmentPayload,
  PeriodicEventControlType,
  TimeOption,
} from 'services/central/types'
import { ContactAccount, Phone, PhoneResponse } from 'services/contact/types'
import { Timestamps } from 'services/types'

export type Contact = {
  accounts?: ContactAccount[] | null
  customerId: string
  name: string
  email: string
  note?: string
  phones?: Phone[]
  userId?: string
}
export type ContactResponse = Contact & {
  id: string
  code: string
  phones: PhoneResponse[]
  partitions?: CentralPartition[]
} & Timestamps

export const centralCommunicationMethods = [
  { label: 'IP', value: 'IP' },
  { label: 'Linha telefônica', value: 'Linha telefônica' },
] as const

export type CentralCommunicationMethods =
  (typeof centralCommunicationMethods)[number]['value']

export const timeOptions: { name: string; value: TimeOption }[] = [
  { name: 'Horas', value: 'hours' },
  { name: 'Minutos', value: 'minutes' },
]

export type Commons = {
  time: number
  type?: TimeOption
}

export type Monitoring = {
  id?: string
  active?: boolean
  type?: PeriodicEventControlType
  interval?: Commons
  failEventRecurrenceInterval?: Commons
  tolerance?: Commons
}

export type MonitoringConfig = {
  keepAlive?: Monitoring | null
  autoTest?: Monitoring | null
}

export type Form = {
  central: {
    id?: string
    code?: string
    topicGroup?: string
    externalId?: string
    modelDescription?: string
    gprsModuleModel?: {
      id: string
      name: string
    }
    model: {
      id: string
      name: string
      isUbisafe: boolean
      hasCommandSupport: boolean
    }
    isUbisafe: boolean
    commandPassword?: string
    communicationMethod: CentralCommunicationMethods
    versionCode: number
    active: boolean
    manufacturer: {
      id: string
      name: string
    }
    gprs: boolean
    grpsManufacturer: {
      id: string
      name: string
    }
  }
  account: {
    id: string
    name: string
    aggregatedAccountName: string
  }
  contacts: Array<{
    id: string
    name: string
    code: string
  }>
  partitions: PartitionFragmentPayload[]
  monitoring?: MonitoringConfig
  image?: File
}
