import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CoverageInputRequest, CoverageDriver } from 'services/coverages'

function updateCoverage(data: CoverageInputRequest): Promise<void> {
  const { id, ...payload } = data
  return CoverageDriver.update(id || '', payload)
}

export const usePutCoverage = () => {
  const queryClient = useQueryClient()
  const { status, mutate } = useMutation({
    mutationFn: async (data: CoverageInputRequest) => {
      await updateCoverage(data)
    },
    onSuccess: (_, payload) =>
      queryClient.invalidateQueries({ queryKey: ['coverage', payload.id] }),
  })

  return {
    mutateUpdateCoverage: mutate,
    statusUpdateCoverage: status,
  }
}
