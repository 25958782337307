import Joi from '@hapi/joi'
import { Form } from 'domains/customer/components/CentralForm/types'

import { periodicEventControlType, timeOption } from 'services/central/types'

export const ALLOWED_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif']

const commonsSchema = Joi.object({
  time: Joi.any().required(),
  type: Joi.string()
    .valid(...Object.keys(timeOption))
    .required(),
})

const keepAliveSchema = Joi.object({
  id: Joi.string().allow(null, '').optional(),
  active: Joi.boolean().required(),
  type: Joi.string()
    .valid(...Object.keys(periodicEventControlType))
    .required(),
  interval: commonsSchema.required(),
  failEventRecurrenceInterval: commonsSchema.required(),
})

const autoTestSchema = Joi.object({
  id: Joi.string().allow(null, '').optional(),
  active: Joi.boolean().required(),
  type: Joi.string()
    .valid(...Object.keys(periodicEventControlType))
    .required(),
  interval: commonsSchema.required(),
  tolerance: commonsSchema.required(),
})

const monitoringSchema = Joi.object({
  keepAlive: keepAliveSchema.optional().allow(null, ''),
  autoTest: autoTestSchema.optional().allow(null, ''),
})

export const centralSchema = Joi.object<Form>({
  account: Joi.object({
    id: Joi.string().uuid().required(),
    aggregatedAccountName: Joi.string().optional().allow(null, ''),
  }),
  central: Joi.object({
    id: Joi.string().optional().allow(null, ''),
    active: Joi.boolean().optional(),
    gprs: Joi.boolean().optional(),
    gprsModuleModel: Joi.object({
      name: Joi.string().allow(null, '').optional(),
      id: Joi.when(Joi.ref('...gprs'), {
        is: true,
        then: Joi.string().uuid().required(),
        otherwise: Joi.string().uuid().allow(null, '').optional(),
      }),
    }),
    grpsManufacturer: Joi.object({
      name: Joi.string().allow(null, '').optional(),
      id: Joi.string().uuid().allow(null, '').optional(),
    })
      .allow(null, '')
      .optional(),
    model: Joi.object({
      hasCommandSupport: Joi.boolean().optional(),
      isUbisafe: Joi.boolean().optional(),
      name: Joi.string().allow(null, '').optional(),
      id: Joi.string().uuid().optional().allow(null, ''),
    }).required(),
    modelDescription: Joi.string().allow('').optional(),
    manufacturer: Joi.object({
      id: Joi.string().uuid().optional().allow(null, ''),
      name: Joi.string().allow(null, '').optional(),
    }).optional(),
    communicationMethod: Joi.string()
      .valid('IP', 'Linha telefônica')
      .optional(),
    code: Joi.string().allow(null, '').optional(),
    topicGroup: Joi.string().allow(null, '').optional(),
    externalId: Joi.string().allow(null, '').optional(),
  })
    .custom((value, helpers) => {
      const { code, topicGroup, externalId } = value

      if (!code && !topicGroup && !externalId) {
        return value
      }

      if (externalId && !code && !topicGroup) {
        return helpers.error('any.invalid', {
          message:
            'When has "externalId", should to enter "code" or "topicGroup".',
        })
      }

      if (code && !externalId) {
        return helpers.error('any.invalid', {
          message: 'When has "code", should to enter "externalId".',
        })
      }

      if (topicGroup && !externalId) {
        return helpers.error('any.invalid', {
          message: 'When has "topicGroup",should to enter "externalId".',
        })
      }

      return value
    })
    .required(),
  partitions: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().required(),
        code: Joi.string().required(),
        status: Joi.string().required(),
        id: Joi.string().allow(null, '').optional(),
        keyNumber: Joi.string().allow(null, '').optional(),
        externalId: Joi.string().allow(null, '').optional(),
      }),
    )
    .min(1)
    .required(),
  monitoring: monitoringSchema.optional(),
  image: Joi.object().optional().allow(null, ''),
  contacts: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().uuid().optional().allow(null, ''),
        name: Joi.string().optional().allow(null, ''),
        code: Joi.string().optional().allow(null, ''),
      }),
    )
    .optional()
    .allow(null, ''),
}).options({ stripUnknown: true })
