import { useForm } from 'react-hook-form'
import { ChangeEvent, useCallback, useState } from 'react'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { getPatrimonyFragment, useToast } from 'shared/hooks'
import { fetchAccounts } from 'shared/hooks/accounts/useGetAccounts'
import {
  ButtonV2,
  Checkbox,
  ComboBoxV3,
  Datepicker,
  FormItem,
  FormLabel,
  Icon,
  SelectV2,
  Tag,
} from 'components'

import styles from './styles.module.scss'
import { queryEventGroup } from 'shared/hooks/services/events'
import {
  accountDetailsReport,
  accountEventsReport,
  Form,
  parameterOptions,
  reports,
  ReportsType,
  reportTypes,
} from 'services/report'
import ButtonLoader from './components/ButtonLoader'

const Reports = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const customerId = localStorage.getItem('customerId') ?? ''

  const { setValue, watch, handleSubmit } = useForm<Form>({
    defaultValues: {
      parameter: ['contacts', 'devices', 'users'],
    },
  })

  const { account, type, patrimony, eventGroup, parameter } = watch()

  const handleGetPatrimonies = useCallback(
    (searchFilter: string, offset = 0) => {
      return getPatrimonyFragment(offset, searchFilter)
    },
    [getPatrimonyFragment],
  )

  const handleGetAccounts = useCallback(
    async (searchFilter: string, offset = 0) => {
      const response = await fetchAccounts(offset, 15, customerId, {
        ...(searchFilter && {
          code: searchFilter,
        }),
      })
      if (watch('patrimony.id')) {
        const filteredByPatrimony = response.data.filter(
          (account) => account.patrimony?.id === watch('patrimony.id'),
        )
        return {
          data: filteredByPatrimony,
          totalElements: filteredByPatrimony.length,
        }
      }
      return response
    },
    [fetchAccounts, customerId],
  )

  const handleGetEventGroups = useCallback(
    async (searchFilter: string, offset = 0) =>
      await queryEventGroup(offset, searchFilter),
    [queryEventGroup],
  )

  const handleAccountDetails = async () =>
    await accountDetailsReport({
      accountId: watch('account.id'),
      userId: customerId,
      showContacts: !!watch('parameter').find((param) => param === 'contacts'),
      showUsers: !!watch('parameter').find((param) => param === 'users'),
      showZones: !!watch('parameter').find((param) => param === 'devices'),
    })

  const handleAccountEvents = async () =>
    await accountEventsReport({
      accountId: watch('account.id'),
      userId: customerId,
      createdFrom: watch('startDate'),
      createdTo: watch('endDate'),
      eventGroupIds:
        watch('eventGroup')?.map((eventgroup) => eventgroup.id) || [],
    })

  const handleDownload = (
    url: string,
    type: ReportsType,
    accountId: string,
  ) => {
    const tempLink = document.createElement('a')
    tempLink.href = url
    tempLink.setAttribute(
      'download',
      `report_account_${accountId}_${type === 'account' ? 'details' : 'events'}.pdf`,
    )

    document.body.appendChild(tempLink)
    tempLink.click()

    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(url)
  }

  const onSubmit = async (data: Form) => {
    const submitType: Record<ReportsType, () => Promise<Blob | MediaSource>> = {
      account: () => handleAccountDetails(),
      event: () => handleAccountEvents(),
    }

    try {
      setLoading(true)
      const response = await submitType[data.type]()

      const url = URL.createObjectURL(response)
      handleDownload(url, data.type, data.account.id)
      addToast({
        message: 'PDF salvo com sucesso.',
        type: 'success',
      })
    } catch (error) {
      addToast({
        message: 'Erro ao Gerar PDF. Tente novamente.',
        error: true,
        type: 'alert',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChangeScheduleDateInput = useCallback(
    (formKey: keyof Form, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)

        setValue(formKey, date.getTime())
        return value
      } else {
        return value
      }
    },
    [setValue],
  )

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem>
          <FormLabel>Patrimônio</FormLabel>
          <ComboBoxV3.Root findOptions={handleGetPatrimonies} valueKey="id">
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={patrimony?.name || ''} />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map((option) => (
                    <ComboBoxV3.Option
                      key={option.id}
                      onClick={() => {
                        setValue(
                          'patrimony',
                          {
                            id: option.id,
                            name: option?.name,
                          },
                          {
                            shouldValidate: true,
                          },
                        )
                      }}
                    >
                      {option.name}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>

        <FormItem>
          <FormLabel>Conta</FormLabel>
          <ComboBoxV3.Root
            findOptions={handleGetAccounts}
            valueKey="aggregatedAccountName"
          >
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={account?.aggregatedName || ''} />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map((option) => (
                    <ComboBoxV3.Option
                      key={option.id}
                      onClick={() => {
                        setValue(
                          'account',
                          {
                            id: option.id,
                            code: option?.code || '',
                            aggregatedName: option.aggregatedAccountName,
                          },
                          {
                            shouldValidate: true,
                          },
                        )

                        if (
                          !!option.patrimony &&
                          (!watch('patrimony.id') ||
                            watch('patrimony.id') !== option.patrimony?.id)
                        )
                          setValue(
                            'patrimony',
                            {
                              id: option.patrimony?.id,
                              name: option?.patrimony?.name,
                            },
                            {
                              shouldValidate: true,
                            },
                          )
                        handleGetAccounts('', 0)
                      }}
                    >
                      {option.aggregatedAccountName}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>

        <FormItem>
          <FormLabel>Tipo de relatório</FormLabel>
          <SelectV2.Root data={reportTypes} valueKey="value">
            {({ unSelectedOptions }) => (
              <>
                <SelectV2.Field
                  value={type ? reports[type] : ''}
                  placeholder="Selecione um tipo de relatório"
                />
                <SelectV2.Group>
                  {unSelectedOptions?.map((option) => (
                    <SelectV2.Option
                      key={option.value}
                      onClick={() => {
                        setValue('type', option.value, {
                          shouldValidate: true,
                        })
                      }}
                    >
                      {option.name}
                    </SelectV2.Option>
                  ))}
                </SelectV2.Group>
              </>
            )}
          </SelectV2.Root>
        </FormItem>

        {type === 'event' && (
          <>
            <FormItem>
              <FormLabel>Tipo de evento</FormLabel>
              <ComboBoxV3.Root
                findOptions={handleGetEventGroups}
                valueKey="description"
              >
                {({ unSelectedOptions }) => {
                  return (
                    <>
                      <ComboBoxV3.Field
                        value={eventGroup?.map(
                          (group) => group.description || '',
                        )}
                      />
                      <ComboBoxV3.Group>
                        {unSelectedOptions
                          ?.filter(
                            (option) =>
                              !eventGroup?.find(
                                (group) => group.id === option.id,
                              ),
                          )
                          .map((option) => (
                            <ComboBoxV3.Option
                              key={option.id}
                              onClick={() => {
                                setValue(
                                  'eventGroup',
                                  [
                                    ...(watch('eventGroup') || []),
                                    {
                                      id: option.id,
                                      description: option.description,
                                    },
                                  ],
                                  {
                                    shouldValidate: true,
                                  },
                                )
                              }}
                            >
                              {option.description}
                            </ComboBoxV3.Option>
                          ))}
                      </ComboBoxV3.Group>
                      {!!eventGroup?.length && (
                        <>
                          <ComboBoxV3.Options>
                            {eventGroup.map((group) => (
                              <Tag key={group.id}>
                                <span>{group.description}</span>

                                <Icon
                                  name="close-xlg"
                                  width={8}
                                  height={8}
                                  onClick={() =>
                                    setValue(
                                      'eventGroup',
                                      eventGroup.filter(
                                        (item) => item.id !== group.id,
                                      ),
                                    )
                                  }
                                />
                              </Tag>
                            ))}
                          </ComboBoxV3.Options>
                          <ButtonV2
                            size="md"
                            appearance="tertiary"
                            onClick={() => setValue('eventGroup', [])}
                          >
                            <Icon name="delete" width={12} height={12} />
                            Remover tudo
                          </ButtonV2>
                        </>
                      )}
                    </>
                  )
                }}
              </ComboBoxV3.Root>
            </FormItem>

            <FormItem className={styles.options}>
              <FormLabel>Período</FormLabel>
              <div>
                <FormLabel>De</FormLabel>
                <Datepicker
                  id="startDate"
                  initialValueInput={
                    watch('startDate')
                      ? convertDateToString(new Date(watch('startDate')))
                      : undefined
                  }
                  initialDate={
                    watch('startDate')
                      ? new Date(watch('startDate'))
                      : undefined
                  }
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('startDate', event)
                  }
                  onChangeDate={(date) => {
                    const dateTime = date?.getTime()

                    if (dateTime) {
                      setValue('startDate', dateTime)
                    }
                  }}
                />
                <FormLabel>até</FormLabel>
                <Datepicker
                  id="endDate"
                  selectEnd
                  disabled={!watch('startDate')}
                  initialValueInput={
                    watch('endDate')
                      ? convertDateToString(new Date(watch('endDate')))
                      : undefined
                  }
                  initialDate={
                    watch('startDate')
                      ? new Date(watch('startDate'))
                      : undefined
                  }
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('endDate', event)
                  }
                  onChangeDate={(date) => {
                    const dateTime = date?.getTime()

                    if (dateTime) {
                      setValue('endDate', dateTime)
                    }
                  }}
                />
              </div>
            </FormItem>
          </>
        )}
        {type === 'account' && (
          <FormItem className={styles.options}>
            <FormLabel>Parâmetros</FormLabel>
            <div>
              {parameterOptions.map((option) => (
                <Checkbox
                  key={option.value}
                  id={option.value}
                  label={option.name}
                  checked={(parameter || [])?.includes(option.value)}
                  onChange={() => {
                    let value

                    if (parameter?.includes(option.value)) {
                      value = parameter.filter(
                        (param) => param !== option.value,
                      )
                    } else {
                      value = [...(parameter || []), option.value]
                    }

                    setValue('parameter', value, {
                      shouldValidate: true,
                    })
                  }}
                />
              ))}
            </div>
          </FormItem>
        )}

        <footer>
          <ButtonV2 type="submit" disabled={loading}>
            {loading && <ButtonLoader />}
            Gerar PDF
          </ButtonV2>
        </footer>
      </form>
    </div>
  )
}

export default Reports
