import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { ToastProvider, UserInfoProvider, useStorage } from 'shared/hooks'

import './App.css'
import Routes from './routes/Routes'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60000 * 5,
      refetchOnWindowFocus: false,
      placeholderData: (prev: unknown) => prev,
    },
    mutations: {
      retry: false,
    },
  },
})

const App = () => {
  useStorage()

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <UserInfoProvider>
          <ToastProvider>
            <Routes />
          </ToastProvider>
        </UserInfoProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
