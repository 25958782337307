import { ButtonV2, Icon, ModalV2 } from 'components'
import styles from './styles.module.scss'
import { useActivateCentral } from 'services/central'
import { useToast } from 'shared/hooks'

interface ActivateCentralModalProps {
  centralId: string
  isOpen: boolean
  onClose: () => void
}

const ActivateCentralModal = ({
  centralId,
  isOpen,
  onClose,
}: ActivateCentralModalProps) => {
  const { addToast } = useToast()
  const { mutate: activateCentral } = useActivateCentral(centralId)

  const handleDeactivate = () => {
    activateCentral(undefined, {
      onSuccess: () => {
        addToast({
          message: 'Central ativada com sucesso.',
          type: 'success',
        })
        onClose()
      },
      onError: () => {
        addToast({
          message: 'Erro ao ativar a central. Tente novamente.',
          type: 'alert',
        })
      },
    })
  }

  return (
    <ModalV2.Root isOpen={isOpen} onClose={onClose}>
      <ModalV2.Content size="sm" className={styles.container}>
        <ModalV2.Title>
          <Icon name="warning" width={19.79} height={17.5} />
          Ativar central
        </ModalV2.Title>
        Tem certeza que deseja ativar esta central? Ela começará a se comunicar
        com o sistema e a conta passará a ser monitorada.
        <ModalV2.Footer>
          <ModalV2.Close>
            <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
          </ModalV2.Close>
          <ButtonV2 onClick={() => handleDeactivate()}>Ativar</ButtonV2>
        </ModalV2.Footer>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}
export default ActivateCentralModal
