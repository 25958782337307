import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import * as Joi from '@hapi/joi'

import { TagComboBox, AttachModal, DiscardModal, TagModal } from './components'
import { ButtonV2, DropDown, Icon, IconButton, ModalV2, Tag } from 'components'
import joinClassNames from 'utilities/joinClassNames'
import { formatFileSize } from 'utilities/masks'

import { useRewriteSentence } from 'services/openIa'
import { handleUrlsToFile } from './utils'

import styles from './styles.module.scss'

const noteType = {
  PUBLIC_NOTE: 'PUBLIC_NOTE',
  PRIVATE_NOTE: 'PRIVATE_NOTE',
}

type NoteTypes = keyof typeof noteType

const noteLabel: Record<NoteTypes, string> = {
  PUBLIC_NOTE: 'Esta nota poderá ser visualizada pelo cliente',
  PRIVATE_NOTE: 'Esta nota ficará oculta para o cliente',
}

export interface Form {
  note: string
  hidden: boolean
  images?: File[]
  tags?: {
    id: string
    name: string
  }[]
}

interface FieldProps {
  onCancel?: () => void
  onSubmit: (values: Form) => void
  visibility?: 'public' | 'private'
  defaultValues?: Omit<Form, 'images'> & { images?: string[] }
}

const initialValues = {
  note: '',
  tags: [],
  images: [],
  hidden: true,
}

export const noteSchema = Joi.object({
  note: Joi.string().required(),
  tags: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().required(),
        name: Joi.string().required(),
      }),
    )
    .optional(),
  images: Joi.array().optional(),
  hidden: Joi.boolean().required(),
})

const Field = ({
  onSubmit,
  onCancel,
  visibility,
  defaultValues = initialValues,
}: FieldProps) => {
  const fieldRef = useRef<HTMLFormElement>(null)
  const expandSentenceRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [hasChildFocused, setHasChildFocused] = useState(false)

  const form = useForm<Form>({
    defaultValues: async () => ({
      tags: defaultValues.tags || [],
      hidden: visibility === 'private' || !!defaultValues.hidden,
      note: defaultValues.note || '',
      images: defaultValues.images
        ? await handleUrlsToFile(defaultValues.images)
        : [],
    }),
    resolver: joiResolver(noteSchema),
  })

  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = form

  const { note, hidden, tags, images } = watch()

  const handleOnSubmit = (data: Form) => {
    setRewrittenSentence('')
    onSubmit(data)
    reset(initialValues)
  }

  const [rewrittenSentence, setRewrittenSentence] = useState('')

  const {
    mutate: mutateRewriteSentence,
    isPending,
    isError,
  } = useRewriteSentence('serviceOrder')

  const handleExpandClick = () => {
    if (expandSentenceRef.current && textareaRef.current) {
      expandSentenceRef.current.style.display = 'none'
      textareaRef.current.style.display = 'block'
    }
  }

  const checkOverflow = () => {
    if (textareaRef.current) {
      const maxTextareaHeight = 508
      const combinedText = `${note}${rewrittenSentence ? ` ${rewrittenSentence}` : ''}`
      textareaRef.current.value = combinedText
      const isOverflowing = textareaRef.current.scrollHeight > maxTextareaHeight
      textareaRef.current.value = note
      return isOverflowing
    }
    return false
  }

  const handleMagicNote = {
    rewrite: () => {
      mutateRewriteSentence(note, {
        onSuccess: (data) => setRewrittenSentence(data.rewrittenSentence),
      })
    },
    accept: () => {
      setValue('note', rewrittenSentence, {
        shouldValidate: true,
      })
      setRewrittenSentence('')
    },
    complement: () => {
      setValue('note', `${note}. \n${rewrittenSentence}`, {
        shouldValidate: true,
      })
      setRewrittenSentence('')
    },
    discard: () => setRewrittenSentence(''),
  }

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (fieldRef.current && fieldRef.current.contains(event.target as Node)) {
        textareaRef.current?.focus()

        setHasChildFocused(true)
      } else {
        setHasChildFocused(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [setHasChildFocused])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [note])

  return (
    <FormProvider {...form}>
      <form ref={fieldRef} onSubmit={handleSubmit(handleOnSubmit)}>
        <div
          className={joinClassNames(
            styles.container,
            hasChildFocused && styles.focused,
            isError && styles.error,
          )}
        >
          <section>
            <div className={styles.type}>
              <TagComboBox
                value={hidden}
                onSelect={(option) => setValue('hidden', option.value)}
              />
              <span>{noteLabel[hidden ? 'PRIVATE_NOTE' : 'PUBLIC_NOTE']}</span>
            </div>
            <div className={styles.textareaContent}>
              {!!rewrittenSentence?.length && checkOverflow() && (
                <div
                  ref={expandSentenceRef}
                  className={joinClassNames(
                    styles.outdatedSentence,
                    rewrittenSentence && styles.hasSentence,
                  )}
                >
                  {note.length > 400 ? `${note.slice(0, 400)}... ` : note}
                  <ButtonV2
                    type="button"
                    color="element"
                    appearance="tertiary"
                    onClick={handleExpandClick}
                    className={styles.expandSentence}
                  >
                    Expandir
                  </ButtonV2>
                </div>
              )}

              <textarea
                rows={1}
                {...register('note')}
                value={note}
                ref={textareaRef}
                maxLength={5000}
                className={joinClassNames(
                  styles.outdatedSentence,
                  rewrittenSentence && styles.hasSentence,
                  !!rewrittenSentence?.length && checkOverflow()
                    ? styles.displayNone
                    : styles.displayBlock,
                )}
                onChange={(event) => {
                  if (rewrittenSentence?.length) {
                    setRewrittenSentence('')
                  }
                  setValue('note', event?.target.value, {
                    shouldValidate: true,
                  })
                }}
              />

              {!!rewrittenSentence?.length && note && (
                <>
                  <div className={styles.rewrittenSentence}>
                    <DropDown.Root isOpen key={rewrittenSentence}>
                      <DropDown.Trigger>
                        <IconButton className={styles.triggerContent}>
                          <>
                            <Icon name="magic-wand" width={14} />
                            <span>{rewrittenSentence}</span>
                          </>
                        </IconButton>
                      </DropDown.Trigger>
                      <DropDown.Content className={styles.dropDown}>
                        <DropDown.Item onClick={handleMagicNote.accept}>
                          Aceitar
                        </DropDown.Item>
                        <DropDown.Item onClick={handleMagicNote.discard}>
                          Descartar
                        </DropDown.Item>
                        <DropDown.Item onClick={handleMagicNote.rewrite}>
                          Tentar novamente
                        </DropDown.Item>
                        <DropDown.Item onClick={handleMagicNote.complement}>
                          Inserir abaixo
                        </DropDown.Item>
                      </DropDown.Content>
                    </DropDown.Root>
                  </div>
                </>
              )}
            </div>
          </section>
          {!!tags?.length && (
            <div className={styles.tags}>
              {tags.map((tag) => (
                <Tag key={tag.id} size="md">
                  {tag.name}
                  <Icon
                    name="close-bold"
                    width={8}
                    onClick={() =>
                      setValue(
                        'tags',
                        tags.filter((currentTag) => currentTag.id !== tag.id),
                        { shouldValidate: true },
                      )
                    }
                  />
                </Tag>
              ))}
            </div>
          )}
          {!!images?.length && (
            <div className={styles.images}>
              {images.map((image, index) => (
                <div key={index}>
                  <strong>{image.name}</strong>
                  {`(${formatFileSize(image.size)})`}
                  <Icon
                    name="close-bold"
                    color="element"
                    width={8}
                    onClick={() => {
                      setValue(
                        'images',
                        images.filter((_, i) => i !== index),
                        { shouldValidate: true },
                      )
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          <div className={styles.actions}>
            <div>
              <IconButton>
                <ModalV2.Root>
                  <ModalV2.Trigger>
                    <Icon
                      name="attach"
                      color="element-light"
                      width={20}
                      aria-label="Adicionar arquivos"
                    />
                  </ModalV2.Trigger>
                  <ModalV2.Content size="md">
                    <AttachModal />
                  </ModalV2.Content>
                </ModalV2.Root>
              </IconButton>
              <ModalV2.Root>
                <ModalV2.Trigger>
                  <IconButton>
                    <Icon
                      name={tags?.length ? 'tag' : 'tag-create'}
                      aria-label="Adicionar tags"
                      color="element-light"
                      width={20}
                    />
                  </IconButton>
                </ModalV2.Trigger>
                <ModalV2.Content>
                  <TagModal />
                </ModalV2.Content>
              </ModalV2.Root>
              <ModalV2.Root>
                <ModalV2.Trigger>
                  <IconButton>
                    <Icon
                      name="delete"
                      color="element-light"
                      width={20}
                      aria-label="Excluir dados preenchidos no formulário"
                    />
                  </IconButton>
                </ModalV2.Trigger>
                <ModalV2.Content>
                  <DiscardModal />
                </ModalV2.Content>
              </ModalV2.Root>
            </div>
            <div>
              <IconButton
                onClick={handleMagicNote.rewrite}
                isLoading={isPending}
                disabled={!note}
              >
                <Icon name="magic-wand" width={20} />
              </IconButton>

              {!!onCancel && (
                <ButtonV2 appearance="secondary" onClick={() => onCancel()}>
                  Cancelar
                </ButtonV2>
              )}
              <ButtonV2 type="submit" disabled={!isValid || isPending}>
                Enviar
              </ButtonV2>
            </div>
          </div>
        </div>
        {isError && (
          <span className={styles.errorMessage}>
            <Icon name="close-md" color="danger" />
            Não foi possível gerar uma sugestão de texto. Tente novamente.
          </span>
        )}
      </form>
    </FormProvider>
  )
}

export default Field
