import { ButtonV2, Icon, ModalV2 } from 'components'
import styles from './styles.module.scss'
import { useDeactivateCentral } from 'services/central'
import { useToast } from 'shared/hooks'

interface DeactivateCentralModalProps {
  centralId: string
  isOpen: boolean
  onClose: () => void
}

const DeactivateCentralModal = ({
  centralId,
  isOpen,
  onClose,
}: DeactivateCentralModalProps) => {
  const { addToast } = useToast()
  const { mutate: deactivateCentral } = useDeactivateCentral(centralId)

  const handleDeactivate = () => {
    deactivateCentral(centralId, {
      onSuccess: () => {
        addToast({
          message: 'Central desativada com sucesso.',
          type: 'success',
        })
        onClose()
      },
      onError: () => {
        addToast({
          message: 'Erro ao desativar a central. Tente novamente.',
          type: 'alert',
        })
      },
    })
  }

  return (
    <ModalV2.Root isOpen={isOpen} onClose={onClose}>
      <ModalV2.Content size="sm" className={styles.container}>
        <ModalV2.Title>
          <Icon name="warning" width={19.79} height={17.5} />
          Desativar central
        </ModalV2.Title>
        Tem certeza que deseja desativar esta central? Ela deixará de se
        comunicar com o sistema e a conta deixará de ser monitorada.
        <ModalV2.Footer>
          <ModalV2.Close>
            <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
          </ModalV2.Close>
          <ButtonV2 color="red" onClick={() => handleDeactivate()}>
            Desativar
          </ButtonV2>
        </ModalV2.Footer>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}
export default DeactivateCentralModal
