import * as Joi from '@hapi/joi'
import { Form, times } from './types'

export const schema = Joi.object<Form>({
  customer: Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
  }),
  account: Joi.object({
    id: Joi.string().required(),
    aggregatedName: Joi.string().required(),
  }),
  central: Joi.object({
    model: Joi.object({
      id: Joi.string().required(),
      name: Joi.string().required(),
    }).required(),
    manufacture: Joi.object({
      id: Joi.string().required(),
      name: Joi.string().required(),
    }).required(),
  }).required(),
  module: Joi.object({
    model: Joi.object({
      id: Joi.string().required(),
      name: Joi.string().required(),
    }).required(),
    manufacture: Joi.object({
      id: Joi.string().required(),
      name: Joi.string().required(),
    }).required(),
  }).optional(),
  keepAlive: Joi.object({
    interval: Joi.number().required(),
    type: Joi.string()
      .valid(...Object.keys(times))
      .required(),
    failEventRecurrenceInterval: Joi.object({
      time: Joi.number().required(),
      type: Joi.string()
        .valid(...Object.keys(times))
        .required(),
    }),
  }).optional(),
  automaticTest: Joi.object({
    interval: Joi.number().required(),
    type: Joi.string()
      .valid(...Object.keys(times))
      .required(),
    tolerance: Joi.object({
      time: Joi.number().required(),
      type: Joi.string()
        .valid(...Object.keys(times))
        .required(),
    }),
  }).optional(),
})
