import { GalleryV2 } from 'components'
import { RemoveImage } from './components'

import { useState } from 'react'
import { GalleryData } from 'services/image'

import { dateToMilliseconds } from 'utilities/date'

export const Gallery = ({ images, imageId, onClose }: GalleryData) => {
  const formattedImages = images.map((image) => ({
    ...image,
    creation: dateToMilliseconds(String(image.creation)) || 0,
  }))

  const selectedImageIndex = formattedImages.findIndex(
    (image) => image.id === imageId,
  )

  const [currentImageId, setCurrentImageId] = useState(imageId)
  const [galleryImages, setGalleryImages] = useState(formattedImages)

  return (
    <GalleryV2.Root
      isOpen
      initialIndex={selectedImageIndex}
      onClose={onClose}
      images={galleryImages}
      onImageChange={(index) => {
        setCurrentImageId(galleryImages[index].id)
      }}
    >
      <GalleryV2.Content>
        <GalleryV2.Header />
        <GalleryV2.ZoomPanImage>
          <GalleryV2.CurrentImage />
        </GalleryV2.ZoomPanImage>
        <GalleryV2.Navigation />

        <GalleryV2.Footer>
          <GalleryV2.Actions>
            <RemoveImage
              imageId={currentImageId}
              onFinish={() => {
                setGalleryImages((prev) => [
                  ...prev.filter((image) => image.id !== currentImageId),
                ])
              }}
            />
            <GalleryV2.Zoom />
            <GalleryV2.Rotate />
          </GalleryV2.Actions>
          <GalleryV2.SliderCarousel />
        </GalleryV2.Footer>
      </GalleryV2.Content>
    </GalleryV2.Root>
  )
}
