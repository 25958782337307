import { CentralInformation, DevicesInformation } from './components'
import { PartitionDevice } from 'services/partition/types'
import { Central, EquipmentsModel } from 'services/serviceOrder'
import { ServiceOrderImagePayload } from 'domains/serviceOrders/types'

import styles from './styles.module.scss'
import { useModal } from 'domains/serviceOrder/screens/Details/context'
import { OwnerName, useGetImage } from 'services/image'

interface EquipmentsInfoProps {
  centralInfo: Central
  model?: EquipmentsModel
  onPressGallery?: () => void
  serviceOrder: ServiceOrderImagePayload
  handleSelect?: (device: PartitionDevice) => void
}

const EquipmentsInfo = ({
  centralInfo,
  model,
  serviceOrder,
}: EquipmentsInfoProps) => {
  const { handleOpenModal, handleCloseModal } = useModal()

  const { data } = useGetImage({
    query: {
      owners: `${OwnerName.ServiceOrder}_${serviceOrder.serviceOrderId}`,
      sortType: 'desc',
    },
  })

  return (
    <div className={styles.wrapperEquipmentsValidation}>
      <section className={styles.centralInfo}>
        <CentralInformation
          central={centralInfo}
          model={model}
          onPressGallery={() => {
            const devices = centralInfo.partitions.flatMap(
              (partition) => partition.devices,
            )

            const formattedImages = data?.data.map((image) => {
              const deviceOwnerIds = image.owners
                ?.filter(
                  (owner) =>
                    owner.includes('quickView') ||
                    owner.includes('installedDevice'),
                )
                .map((owner) => owner.split('_')[1])

              return {
                ...image,
                title:
                  devices.find((device) => deviceOwnerIds?.includes(device.id))
                    ?.name || '',
              }
            })

            handleOpenModal({
              type: 'GALLERY',
              data: {
                imageId: data?.data[0].id || '',
                onClose: handleCloseModal,
                images: formattedImages || [],
              },
            })
          }}
          serviceOrder={serviceOrder}
        />
      </section>
      <section className={styles.devicesInfo}>
        <DevicesInformation
          partitions={centralInfo.partitions}
          handleSelect={(device) => {
            if (!!device.installationImage || !!device.quickViewImage) {
              const deviceImages = [
                device.installationImage?.id,
                device.quickViewImage?.id,
              ]

              const images = data?.data
                .filter((image) => deviceImages.includes(image.id))
                .map((image) => ({ ...image, title: device.name }))

              handleOpenModal({
                type: 'GALLERY',
                data: {
                  imageId:
                    device?.installationImage?.id ||
                    device.quickViewImage?.id ||
                    '',
                  onClose: handleCloseModal,
                  images: images || [],
                },
              })
            }
          }}
        />
      </section>
    </div>
  )
}

export default EquipmentsInfo
