import { useParams } from 'react-router-dom'
import { useGetAccount } from 'services/serviceOrderV2'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'
import { ScheduleServiceOrderModal } from 'components'

interface ScheduleProps {
  onClose: () => void
}

const Schedule = ({ onClose }: ScheduleProps) => {
  const { serviceOrderId } = useParams()
  const { serviceOrder } = useServiceOrderDetails()
  const { data } = useGetAccount(serviceOrder?.accountId)
  const contact = data?.contacts.find(
    (contact) => contact.id === serviceOrder?.contact?.id,
  )

  const serviceOrderForSchedule = {
    ...serviceOrder,
    id: serviceOrderId,
    ...(contact && {
      contact: {
        id: contact.id,
        name: contact.name,
        number: contact?.phone.number,
        countryCode: contact?.phone.countryCode,
        provinceCode: contact?.phone.provinceCode,
      },
    }),
    ...(data?.customer && { customer: data.customer }),
    ...(data?.account && {
      account: {
        id: data.account.id,
        code: data.account.code,
        aggregatedName: data.account.name,
      },
    }),
    type: serviceOrder?.serviceOrderType,
    note: '',
  }

  return (
    <ScheduleServiceOrderModal
      isVisible
      onClose={onClose}
      serviceOrder={serviceOrderForSchedule}
    />
  )
}

export default Schedule
