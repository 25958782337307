import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { endpoints } from 'services/patrol/endpoints'
import {
  AggregatedPatrol,
  PatrolQuery,
  PatrolRequest,
  PatrolResponse,
} from 'services/patrol/types'

import HttpClient from '../httpClient'
import buildUrl from 'utilities/buildUrl'
import { AxiosInstance } from 'axios'
import { Paging, Result } from 'services/types'

class PatrolDriverImpl {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}
  public async query(
    query: Partial<PatrolQuery> & Partial<Paging>,
  ): Promise<Result<PatrolResponse>> {
    const result = await this.httpClient.get<Result<PatrolResponse>>(
      endpoints.query,
      { params: query },
    )

    return result.data
  }
}

export const PatrolDriver = new PatrolDriverImpl()

const useFetchPatrol = (patrolId: string) =>
  useQuery({
    queryKey: ['find-patrol', patrolId],
    queryFn: async () => {
      const response = await HttpClient.get<AggregatedPatrol>(
        buildUrl({
          route: endpoints.find,
          params: { id: patrolId },
        }),
      )

      return response.data
    },
    enabled: !!patrolId,
  })

const useUpdatePatrol = (patrolId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['update-patrol', patrolId],
    mutationFn: async (payload: PatrolRequest) => {
      const response = await HttpClient.put<PatrolRequest>(
        buildUrl({
          route: endpoints.update,
          params: { id: patrolId },
        }),
        { ...payload },
      )
      return response.data
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['find-patrol', patrolId] }),
  })
}

const useCreatePatrol = () => {
  return useMutation({
    mutationKey: ['create-patrol'],
    mutationFn: async (payload: PatrolRequest) => {
      const response = await HttpClient.post<PatrolRequest>(
        buildUrl({
          route: endpoints.create,
        }),
        { ...payload },
      )
      return response.data
    },
  })
}

const removePatrol = async (patrolId = '') => {
  const response = await HttpClient.delete<PatrolResponse>(
    buildUrl({
      route: endpoints.delete,
      params: { id: patrolId },
    }),
  )

  return response.data
}

export const useRemovePatrol = (patrolId = '') => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['remove-patrol', patrolId],
    mutationFn: async (patrolId: string) => {
      await removePatrol(patrolId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-patrol', patrolId] })
    },
  })
}

export { useFetchPatrol, useCreatePatrol, useUpdatePatrol }
