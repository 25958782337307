import React, { useCallback, useEffect, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import {
  TabsAccountModalKeys,
  tabsAccountModalKeys,
  TABS_ACCOUNT_MODAL_KEYS_EN_PT,
} from 'domains/customer/types'
import {
  Account,
  AccountPartitions,
  AccountPayload,
  AccountTag,
  PatrimonyWithCustomerAndAccountPayload,
  PaymentMethodLabel,
  TagType,
} from 'services/account/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useDebounce, useGetAccountTags, useToast } from 'shared/hooks'
import { ServiceType } from 'services/serviceType/types'

import { ReactComponent as EditIcon } from 'assets/svg/listEditIcon.svg'

import { useGetServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { PatrimonyWithAccountRequest } from 'services/patrimony/types'
import { usePostAccount, usePutAccount } from 'services/account/hooks'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { Button, Input, Modal, RadioGroup, Textarea } from 'components'
import { joiResolver } from '@hookform/resolvers/joi'
import { accountPayloadSchema } from 'shared/schemas'
import { maskedDate } from 'utilities/date'

import styles from './AccountModal.module.scss'

type AccountModalProps = {
  readOnly: boolean
  onClose: () => void
  account?: Account
  patrimonyId?: string
  setReadOnly: () => void
}

const AccountModal: React.FC<AccountModalProps> = ({
  onClose,
  account,
  readOnly,
  patrimonyId,
  setReadOnly,
}) => {
  const [selectedTab, setSelectedTab] = useState<TabsAccountModalKeys>('main')

  const [accountFilters, setAccountFilters] = useState({
    serviceType: '',
    tag: '',
  })

  const handleFilter = useDebounce(
    (newFilters: Partial<typeof accountFilters>) =>
      setAccountFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters,
      })),
  )

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<AccountPayload>({
    mode: 'onChange',
    resolver: joiResolver(accountPayloadSchema),
  })

  const { register: registerPatrimonyForm, setValue: setValuePatrimonyForm } =
    useFormContext<
      PatrimonyWithCustomerAndAccountPayload | PatrimonyWithAccountRequest
    >()

  const [partitionSelected, setPartitionSelected] =
    useState<AccountPartitions>()

  const { mutate: mutateCreateAccount } = usePostAccount()
  const { mutate: mutateUpdateAccount } = usePutAccount()

  const {
    serviceTypes,
    fetchNextServiceTypesPage,
    isFetching: isFetchingServiceTypes,
  } = useGetServiceType(accountFilters.serviceType)

  const {
    accountTags,
    fetchNextTagsPage,
    isError: isErrorAccountTags,
    isFetching: isFetchingAccountTags,
  } = useGetAccountTags(accountFilters.tag, true)

  const { addToast } = useToast()

  const registerAndSetHasAccount = useCallback(() => {
    registerPatrimonyForm('hasAccount')
    setValuePatrimonyForm('hasAccount', true)
  }, [registerPatrimonyForm, setValuePatrimonyForm])

  const onSubmit = useCallback(
    (data: AccountPayload) => {
      const { createdAt, activeDateTime, ...rest } = data
      account
        ? mutateUpdateAccount(rest, {
            onSuccess: () => {
              addToast({
                message: 'Conta editada com sucesso.',
                type: 'success',
              })
              registerAndSetHasAccount()
              onClose()
            },
            onError: () =>
              addToast({
                message: 'Erro ao editar conta. Tente novamente.',
                error: true,
                type: 'alert',
              }),
          })
        : mutateCreateAccount(
            { ...data, patrimonyId },
            {
              onSuccess: () => {
                addToast({
                  message: 'Conta criada com sucesso.',
                  type: 'success',
                })
                registerAndSetHasAccount()
                onClose()
              },
              onError: () =>
                addToast({
                  message: 'Erro ao criar conta. Tente novamente.',
                  error: true,
                  type: 'alert',
                }),
            },
          )
    },
    [
      account,
      addToast,
      mutateCreateAccount,
      mutateUpdateAccount,
      onClose,
      patrimonyId,
      registerAndSetHasAccount,
    ],
  )

  const partitions = watch('partitions')

  useEffect(() => {
    if (partitions) {
      partitionSelected
        ? setPartitionSelected(
            partitions.find(
              (partition) => partition.id === partitionSelected.id,
            ),
          )
        : setPartitionSelected(partitions[0])
    }
  }, [partitionSelected, partitions])

  useEffect(() => {
    if (account) {
      register('id')
      register('note')
      register('active')
      register('partitions')
      register('patrimonyId')
      setValue('id', account.id)
      setValue('name', account.name)
      setValue('tags', account?.tags)
      setValue('note', account?.note)
      setValue('createdAt', account.createdAt)
      setValue('partitions', account.partitions)
      setValue('externalId', account?.externalId)
      setValue('serviceType', account?.serviceType)
      setValue('patrimonyId', account?.patrimonyId)
      setValue('observations', account?.observations)
      setValue('paymentMethod', account?.paymentMethod)
      setValue('securityAnswer', account?.securityAnswer)
      setValue('activeDateTime', account?.activeDateTime)
      setValue('securityQuestion', account?.securityQuestion)
      setValue('active', account.active ? 'ACTIVE' : 'INACTIVE')
      setValue('attendanceProfiles', account?.attendanceProfiles)
      setValue('securityCoercionAnswer', account?.securityCoercionAnswer)
      setValue('contractNumber', account?.contractNumber)
      trigger()
    }
  }, [account, setValue, register, trigger])

  return (
    <Modal
      isVisible
      size={'lg'}
      className={styles.modal}
      title={account ? `Conta ${account.name}` : 'Nova conta'}
    >
      <>
        {account && (
          <Button
            type="tertiary"
            icon={EditIcon}
            buttonTitle="Editar"
            disabled={!readOnly}
            onClick={setReadOnly}
            className={styles.editButton}
          />
        )}

        <div className={styles.modalContainer}>
          {account && (
            <>
              <section>
                <div className={styles.wizzard}>
                  {Object.values(tabsAccountModalKeys).map((value) => (
                    <h2
                      key={value}
                      className={[
                        styles.tabItem,
                        value === selectedTab && styles.tabItemSelected,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      onClick={() =>
                        setSelectedTab(value as TabsAccountModalKeys)
                      }
                    >
                      {
                        TABS_ACCOUNT_MODAL_KEYS_EN_PT[
                          value as TabsAccountModalKeys
                        ]
                      }
                    </h2>
                  ))}
                </div>
                <div className={styles.separator} />

                {selectedTab === 'main' && (
                  <>
                    <Input
                      label="Nome"
                      disabled={readOnly}
                      {...register('name')}
                      placeholder="Nome da conta"
                      value={watch('name') || ''}
                      onChange={(event) => {
                        setValue('name', event.target.value, {
                          shouldValidate: true,
                        })
                      }}
                    />
                    <div className={styles.tabMain}>
                      <section>
                        <div
                          className={[
                            styles.radios,
                            readOnly && styles.radioDisabled,
                          ]
                            .filter(Boolean)
                            .join(' ')}
                        >
                          <RadioGroup title="Situação">
                            <RadioButton
                              id={'active'}
                              name={'active'}
                              value={'Ativa'}
                              checked={watch('active') === 'ACTIVE'}
                              onChange={() =>
                                !readOnly &&
                                setValue('active', 'ACTIVE', {
                                  shouldValidate: true,
                                })
                              }
                            />
                            <RadioButton
                              id={'inactive'}
                              name={'active'}
                              value={'Inativa'}
                              checked={watch('active') === 'INACTIVE'}
                              onChange={() =>
                                !readOnly &&
                                setValue('active', 'INACTIVE', {
                                  shouldValidate: true,
                                })
                              }
                            />
                          </RadioGroup>
                          <Input
                            {...register('contractNumber')}
                            label="Contrato"
                            value={watch('contractNumber') || ''}
                            onChange={(event) =>
                              setValue('contractNumber', event.target.value, {
                                shouldDirty: true,
                                shouldValidate: true,
                              })
                            }
                            disabled={readOnly}
                          />
                        </div>
                        <Combobox
                          disabled={readOnly}
                          {...register('serviceType')}
                          isLoading={isFetchingServiceTypes}
                          label={{ text: 'Tipo de Serviço' }}
                          onEndReached={fetchNextServiceTypesPage}
                          value={watch('serviceType')?.name || ''}
                          onSearch={(search) =>
                            handleFilter({ serviceType: search })
                          }
                          items={parseDataToComboboxV2(
                            serviceTypes?.sort((a, b) =>
                              a.name.localeCompare(b.name),
                            ) || [],
                            'name',
                          )}
                          onChange={(selected) => {
                            const selectedServiceType =
                              selected as unknown as ComboboxItem<ServiceType>

                            setValue(
                              'serviceType',
                              {
                                id: selectedServiceType.value.id,
                                name: selectedServiceType.value.name,
                              },
                              { shouldValidate: true },
                            )
                          }}
                        />
                        <Input
                          {...register('paymentMethod')}
                          label="Método de pagamento"
                          value={
                            PaymentMethodLabel[
                              account.paymentMethod?.paymentMethodType ?? 'NONE'
                            ]
                          }
                          disabled
                        />
                        <Combobox
                          multiple
                          disabled={readOnly}
                          {...register('tags')}
                          label={{ text: 'Tags' }}
                          isError={isErrorAccountTags}
                          onEndReached={fetchNextTagsPage}
                          isLoading={isFetchingAccountTags}
                          onSearch={(search) => handleFilter({ tag: search })}
                          items={parseDataToComboboxV2(
                            accountTags.map((tag) => ({
                              id: tag.id,
                              name: tag.name,
                              type: tag.type,
                            })) || [],
                            'name',
                          )}
                          value={
                            watch('tags')?.map((item) => ({
                              label: 'name',
                              value: item,
                            })) || []
                          }
                          onChange={(selected) => {
                            const selectedTag =
                              selected as unknown as ComboboxItem<AccountTag>[]

                            setValue(
                              'tags',
                              selectedTag?.map(
                                (tag) => ({
                                  name: tag.value.name,
                                  type: tag.value.type || TagType.PRINCIPAL,
                                }),
                                { shouldValidate: true },
                              ),
                            )
                          }}
                        />
                      </section>
                      <section>
                        <Input
                          label={
                            account.active ? 'Ativo desde' : 'Inativo desde'
                          }
                          value={maskedDate(Number(account.activeDateTime))}
                          disabled
                        />
                        <Input
                          type="text"
                          label="ID externo"
                          disabled={readOnly}
                          {...register('externalId')}
                          value={watch('externalId') || ''}
                          onChange={(event) => {
                            setValue('externalId', event.target.value, {
                              shouldValidate: true,
                            })
                          }}
                        />
                        <Input
                          label="Cadastrado em"
                          value={
                            account.active
                              ? maskedDate(Number(account.createdAt))
                              : ' -- '
                          }
                          disabled
                        />
                        <div>
                          <label className={styles.observationsLabel}>
                            Observações (opcional)
                          </label>
                          <textarea
                            rows={8}
                            maxLength={2000}
                            disabled={readOnly}
                            id="account-observations"
                            className={[
                              styles.textArea,
                              readOnly && styles.textAreaDisabled,
                            ]
                              .filter(Boolean)
                              .join(' ')}
                            {...register('observations')}
                            value={watch('observations') || ''}
                            onChange={(event) =>
                              setValue('observations', event.target.value, {
                                shouldValidate: true,
                              })
                            }
                          />
                        </div>
                      </section>
                    </div>
                  </>
                )}

                {selectedTab === 'notes' && (
                  <>
                    <label className={styles.noteLabel} htmlFor="account-note">
                      Anotações da conta
                    </label>

                    <textarea
                      rows={10}
                      maxLength={6000}
                      id="account-note"
                      disabled={readOnly}
                      className={[
                        styles.notes,
                        readOnly && styles.textAreaDisabled,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      title="Anotações da conta"
                      value={watch('note') || ''}
                      onChange={(e) =>
                        setValue('note', e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                  </>
                )}

                {selectedTab === 'attendanceProfiles' && (
                  <div className={styles.attendanceProfilesTab}>
                    {!!account.attendanceProfiles?.tactical?.length && (
                      <>
                        <h1>Tático</h1>
                        <ul>
                          {account.attendanceProfiles.tactical.map(
                            (profile, index) => (
                              <li key={`${index} - ${profile}`}>{profile}</li>
                            ),
                          )}
                        </ul>
                      </>
                    )}

                    {!!account.attendanceProfiles?.technical?.length && (
                      <>
                        <h1>Técnico</h1>
                        <ul>
                          {account.attendanceProfiles?.technical.map(
                            (profile, index) => (
                              <li key={`${index} - ${profile}`}>{profile}</li>
                            ),
                          )}
                        </ul>
                      </>
                    )}

                    {!account.attendanceProfiles?.tactical?.length &&
                      !account.attendanceProfiles?.technical?.length && (
                        <>
                          <p>
                            Não há perfis de atendimento cadastrados <br /> para
                            esta conta.
                          </p>
                        </>
                      )}
                  </div>
                )}

                {selectedTab === 'passwords' && (
                  <>
                    <div className={styles.secrets}>
                      <Combobox
                        items={parseDataToComboboxV2(
                          watch('partitions') || [],
                          'name',
                        )}
                        label={{ text: 'Partição' }}
                        value={partitionSelected?.name || ''}
                        onChange={(selected) => {
                          const selectedPartition =
                            selected as unknown as ComboboxItem<AccountPartitions>

                          setPartitionSelected(selectedPartition.value)
                        }}
                      />

                      <div className={styles.secretsHeader}>
                        <h2 className={styles.secretsTitle}>Senhas</h2>
                        <span className={styles.secretsLabel}>
                          Palavras-chave do cliente definidas por partição
                        </span>
                      </div>

                      <div className={styles.secretsInputs}>
                        <div className={styles.secretInput}>
                          <Textarea
                            rows={4}
                            label="Senha interna"
                            {...register('securityQuestion')}
                            value={partitionSelected?.securityQuestion}
                            onChange={(event) =>
                              setValue(
                                'partitions',
                                watch('partitions')?.map((partition) =>
                                  partition.id === partitionSelected?.id
                                    ? {
                                        ...partition,
                                        securityQuestion: event.target.value,
                                      }
                                    : partition,
                                ),
                                { shouldValidate: true },
                              )
                            }
                          />
                        </div>
                        <div className={styles.secretInput}>
                          <Textarea
                            rows={4}
                            label="Senha cliente"
                            {...register('securityAnswer')}
                            value={partitionSelected?.securityAnswer}
                            onChange={(event) =>
                              setValue(
                                'partitions',
                                watch('partitions')?.map((partition) =>
                                  partition.id === partitionSelected?.id
                                    ? {
                                        ...partition,
                                        securityAnswer: event.target.value,
                                      }
                                    : partition,
                                ),
                                { shouldValidate: true },
                              )
                            }
                          />
                        </div>
                        <div className={styles.secretInput}>
                          <Textarea
                            rows={4}
                            label="Senha coação"
                            {...register('securityCoercionAnswer')}
                            value={partitionSelected?.securityCoercionAnswer}
                            onChange={(event) =>
                              setValue(
                                'partitions',
                                watch('partitions')?.map((partition) =>
                                  partition.id === partitionSelected?.id
                                    ? {
                                        ...partition,
                                        securityCoercionAnswer:
                                          event.target.value,
                                      }
                                    : partition,
                                ),
                                { shouldValidate: true },
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </section>
            </>
          )}
          {!account && (
            <>
              <section className={styles.newAccount}>
                <Input
                  label="Nome"
                  {...register('name')}
                  placeholder="Nome da conta"
                  value={watch('name') || ''}
                  onChange={(event) => {
                    setValue('name', event.target.value, {
                      shouldValidate: true,
                    })
                  }}
                />
                <Combobox
                  {...register('serviceType')}
                  isLoading={isFetchingServiceTypes}
                  label={{ text: 'Tipo de Serviço' }}
                  onEndReached={fetchNextServiceTypesPage}
                  value={watch('serviceType')?.name || ''}
                  placeholder="Selecione um tipo de serviço"
                  onSearch={(search) => handleFilter({ serviceType: search })}
                  items={parseDataToComboboxV2(
                    serviceTypes?.sort((a, b) =>
                      a.name.localeCompare(b.name),
                    ) || [],
                    'name',
                  )}
                  onChange={(selected) => {
                    const selectedServiceType =
                      selected as unknown as ComboboxItem<ServiceType>

                    setValue(
                      'serviceType',
                      {
                        id: selectedServiceType.value.id,
                        name: selectedServiceType.value.name,
                      },
                      { shouldValidate: true },
                    )
                  }}
                />
                <div className={styles.multiple}>
                  <Combobox
                    multiple
                    label={{ text: 'Tags' }}
                    isError={isErrorAccountTags}
                    onEndReached={fetchNextTagsPage}
                    isLoading={isFetchingAccountTags}
                    placeholder="Selecione uma ou mais tags"
                    onSearch={(search) => handleFilter({ tag: search })}
                    items={parseDataToComboboxV2(
                      accountTags.map((tag) => ({
                        id: tag.id,
                        name: tag.name,
                        type: tag.type,
                      })) || [],
                      'name',
                    )}
                    {...register('tags')}
                    value={watch('tags')?.map((item) => ({
                      label: 'name',
                      value: item,
                    }))}
                    onChange={(selected) => {
                      const selectedTag =
                        selected as unknown as ComboboxItem<AccountTag>[]

                      setValue(
                        'tags',
                        selectedTag?.map(
                          (tag) => ({
                            name: tag.value.name,
                            type: tag.value.type || TagType.PRINCIPAL,
                          }),
                          { shouldValidate: true },
                        ),
                      )
                    }}
                  />
                  <div>
                    <label className={styles.observationsLabel}>
                      Observações (opcional)
                    </label>
                    <textarea
                      rows={8}
                      maxLength={2000}
                      disabled={readOnly}
                      id="account-observations"
                      className={[
                        styles.textAreaNewAccount,
                        watch('tags') && styles.textAreaNewAccountExpanded,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      {...register('observations')}
                      value={watch('observations') || ''}
                      onChange={(event) =>
                        setValue('observations', event.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </>

      <Modal.Footer className={styles.footer}>
        <Button
          width="172px"
          type="secondary"
          onClick={onClose}
          buttonTitle={account ? 'Voltar' : 'Cancelar'}
        />
        <Button
          width="172px"
          type="primary"
          buttonTitle="Salvar"
          disabled={!isValid || readOnly}
          onClick={handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AccountModal
