import { Skeleton } from 'components'
import styles from './styles.module.scss'

const Loading = () => (
  <div className={styles.timelineItem}>
    <div className={styles.timelineContent}>
      <p className={styles.title}>
        <Skeleton height={16} width={100} /> -{' '}
        <Skeleton height={16} width={80} />
      </p>
      <div>
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className={styles.changeItem}>
            <p className={styles.changeTitle}>
              <Skeleton height={14} width={150} />
            </p>
            <div className={styles.changeContent}>
              <Skeleton height={14} width={80} />
              <Skeleton height={14} width={80} />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default Loading
