import { PeriodicEventControlDeactivateRequest } from 'services/central/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import HttpClient from '../httpClient'

import buildUrl from 'utilities/buildUrl'
import { endpoints } from './endpoints'

export const useDeactivatePeriodicEventControl = (
  centralId: string,
  eventId: string,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: PeriodicEventControlDeactivateRequest) => {
      const response = await HttpClient.post(
        buildUrl({
          route: endpoints.deactivate,
          params: { id: eventId },
        }),
        payload,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['central', centralId] })
    },
  })
}
