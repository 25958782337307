import { formatDateWithMonth } from 'utilities/date'
import {
  Intervention,
  SOFields,
  serviceOrderTypes,
} from 'services/serviceOrderV2'
import { Icon } from 'components'
import joinClassNames from 'utilities/joinClassNames'
import styles from './styles.module.scss'

interface ItemProps {
  intervention: Intervention
  context?: 'drawer' | 'modal'
}

const Item = ({ intervention, context }: ItemProps) => {
  if (
    !intervention.previousVersions?.length ||
    intervention.typeName !== 'SO_CHANGE'
  )
    return null

  const { datetime, user } = intervention
  const formattedDate = formatDateWithMonth(datetime)

  return (
    <li className={styles.container}>
      <strong>
        {formattedDate} - {user?.name}
      </strong>
      {intervention.previousVersions.map((change, index) => (
        <section
          key={index}
          className={joinClassNames(context === 'drawer' && styles.drawer)}
        >
          <p>Alteração em {`"${SOFields[change.field]}"`}</p>
          <div>
            <div className={styles.old}>
              <span>
                {change.field === 'serviceOrderType'
                  ? serviceOrderTypes[change.oldValue]
                  : change.oldValue}
              </span>
              <div className={styles.iconFrame}>
                <Icon
                  name="chevron-sm-right"
                  width={10}
                  color="element-medium"
                />
              </div>
            </div>
            <span>
              {change.field === 'serviceOrderType'
                ? serviceOrderTypes[change.updatedValue]
                : change.updatedValue}
            </span>{' '}
          </div>
        </section>
      ))}
    </li>
  )
}

export default Item
