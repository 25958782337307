export const reports = {
  event: 'Eventos',
  account: 'Visão geral do cliente',
}

export type ReportsType = keyof typeof reports

export const reportTypes: { name: string; value: ReportsType }[] = [
  { name: 'Eventos', value: 'event' },
  { name: 'Visão geral do cliente', value: 'account' },
]

const parameters = {
  contacts: 'Contatos',
  users: 'Usuários',
  devices: 'Dispositivos (Zonas)',
}

type AccountDetailsParameters = keyof typeof parameters

export const parameterOptions: {
  name: string
  value: AccountDetailsParameters
}[] = [
  {
    name: 'Contatos',
    value: 'contacts',
  },
  {
    name: 'Usuários',
    value: 'users',
  },
  {
    name: 'Dispositivos (Zonas)',
    value: 'devices',
  },
]

export interface Form {
  patrimony: {
    id: string
    name: string
  }
  account: {
    id: string
    code: string
    aggregatedName: string
  }
  eventGroup?: {
    id: string
    description: string
  }[]
  type: ReportsType
  endDate: number
  startDate: number
  parameter: AccountDetailsParameters[]
}
