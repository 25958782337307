import { useCallback, FC, useMemo, useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useNavigate } from 'react-router-dom'

import { DragAndDrop } from 'domains/attendancePolicy/components/AutomationList/components/DragAndDropAutomation/DragAndDropAutomation'
import { RadioButton } from 'components/RadioButton/RadioButton'
import {
  ProtectedImage,
  NumberField,
  RadioGroup,
  ComboBoxV3,
  TooltipV2,
  TextField,
  FormLabel,
  FormItem,
  ButtonV2,
  SelectV2,
  Toggle,
  Info,
  Icon,
  FormAddon,
  FormGroup,
  ModalV2,
} from 'components'
import {
  DisableMonitoringParametersModal,
  DeactivateCentralModal,
  AddPartitionModal,
  EnableMonitoringParametersModal,
  ActivateCentralModal,
} from './components'

import { centralSchema } from 'domains/customer/schemas/central/centralSchema'
import {
  CentralCommunicationMethods,
  centralCommunicationMethods,
  Form,
  timeOptions,
} from './types'
import { DeviceModelOutput } from 'services/deviceModel/types'
import { PartitionStatus } from 'services/patrimony/types'
import { getManufacturerById, getManufacturers } from 'services/manufacturer'
import { getDeviceModels } from 'services/deviceModel'
import {
  PartitionFragmentPayload,
  CentralFindByIdResult,
  timeOption,
} from 'services/central/types'

import { usePermissions, useToggle } from 'shared/hooks'
import { maskedDate } from 'utilities/date'
import { formatMACORIMEI } from 'utilities/masks/macAndImei/macAndImei'
import { fetchAccounts } from 'shared/hooks/accounts/useGetAccounts'
import { validateHexdecimalCharacter } from 'utilities/validation'
import { getPartitionStatusLabel } from 'domains/customer/utilities/partitions/getPartitionStatusLabel'
import { useGetDeviceType } from 'shared/hooks/services/device/useGetDeviceType'

import styles from './styles.module.scss'
import { useQueryCentralByAccounts } from 'services/central'
import { UbideskPermissions } from 'routes/types'

const CENTRAL_DEVICE_TYPE = '6'
const GPRS_DEVICE_TYPE = '10'

export const convertMinutesToTimeType = (totalTime: number) => {
  if (!totalTime || totalTime <= 0) {
    return { time: 0, type: 'minutes' } as const
  }

  if (totalTime % 60 === 0) {
    return { time: totalTime / 60, type: 'hours' } as const
  }

  return { time: totalTime, type: 'minutes' } as const
}

const getItem = (
  item: PartitionFragmentPayload,
): { id: string; code: string; title: string; subtitle: string } => ({
  id: item.id,
  code: item.code,
  title: `${item.code} - ${item.name}`,
  subtitle: getPartitionStatusLabel(item.status || PartitionStatus.DISARMED),
})

type CentralFormProps = {
  centralToUpdate?: CentralFindByIdResult
  onSubmit: (data: Form) => void
}

export const CentralForm: FC<CentralFormProps> = ({
  centralToUpdate,
  onSubmit,
}) => {
  const { hasAccess } = usePermissions()

  const [selectedImage, setSelectedImage] = useState<File | undefined>()
  const [selectedPartitionId, setSelectedPartitionId] = useState('')
  const [foundModelType, setFoundModelType] = useState<
    'central' | 'gprs' | undefined
  >()

  const localAccountId = localStorage.getItem('accountId') || ''
  const customerId = localStorage.getItem('customerId') || ''
  const localAggregatedAccountName =
    localStorage.getItem('aggregatedAccountName') || ''
  const isAllAccounts = localAggregatedAccountName === 'Todas'

  const deactivateCentralToggle = useToggle()
  const activateCentralToggle = useToggle()
  const disableKeepAliveModal = useToggle()
  const disableAutoTestModal = useToggle()
  const enablekeepAliveModal = useToggle()
  const enableAutoTestModal = useToggle()
  const partitionModal = useToggle()

  const [viewPassword, setViewPassword] = useState(true)

  const navigate = useNavigate()

  const { data: centralByAccount } = useQueryCentralByAccounts(
    localAccountId ? [localAccountId] : [],
  )

  const accountInUse = centralByAccount?.data?.some((central) => !!central.id)

  const defaultAccount = () => {
    if (centralToUpdate?.central?.account) {
      return {
        id: centralToUpdate.central.account.id,
        aggregatedAccountName:
          centralToUpdate.central.account.aggregatedAccountName || '',
      }
    }

    if (isAllAccounts || accountInUse) {
      return { id: '', aggregatedAccountName: '' }
    }

    return {
      id: localAccountId,
      aggregatedAccountName: localAggregatedAccountName,
    }
  }

  const keepAlive = useMemo(() => {
    return centralToUpdate?.monitoring?.keepAlive
  }, [centralToUpdate])

  const autoTest = useMemo(() => {
    return centralToUpdate?.monitoring?.autoTest
  }, [centralToUpdate])

  const form = useForm<Form>({
    mode: 'onChange',
    resolver: joiResolver(centralSchema),
    defaultValues: {
      central: {
        commandPassword: centralToUpdate?.central?.commandPassword || '',
        gprsModuleModel: centralToUpdate?.central?.gprsModuleModel || undefined,
        manufacturer: centralToUpdate?.central?.manufacturer || undefined,
        modelDescription: centralToUpdate?.central?.modelDescription || '',
        grpsManufacturer: centralToUpdate?.central?.gprsModuleManufacturer,
        versionCode: centralToUpdate?.central?.versionCode || undefined,
        topicGroup: centralToUpdate?.central?.topicGroup || undefined,
        externalId: centralToUpdate?.central?.externalId || undefined,
        gprs:
          (centralToUpdate &&
            !!centralToUpdate?.central?.gprsModuleModel?.id) ??
          true,
        model: {
          id: centralToUpdate?.central.model.id || undefined,
          name: centralToUpdate?.central.model.name || undefined,
        },
        active: (centralToUpdate && centralToUpdate.central?.active) ?? true,
        code: centralToUpdate?.central?.code
          ? formatMACORIMEI(centralToUpdate?.central?.code)
          : undefined,
        communicationMethod: centralToUpdate
          ? centralToUpdate?.central?.code
            ? 'IP'
            : 'Linha telefônica'
          : 'IP',
        id: centralToUpdate?.central?.id || '',
        isUbisafe:
          (centralToUpdate && centralToUpdate.central?.model.isUbisafe) ??
          false,
      },
      contacts:
        centralToUpdate?.contacts?.map(({ id, name, code }) => ({
          id,
          name,
          code,
        })) || [],
      account: defaultAccount(),
      partitions:
        centralToUpdate?.partitions?.map((partition) => ({
          externalId: partition?.externalId || '',
          keyNumber: partition?.keyNumber || '',
          code: partition?.code || '',
          status: partition?.status,
          name: partition?.name,
          id: partition?.id,
        })) || [],
      monitoring: centralToUpdate
        ? {
            ...(autoTest?.active && {
              autoTest: {
                id: autoTest.id,
                active: autoTest.active,
                interval: convertMinutesToTimeType(autoTest.interval),
                tolerance: convertMinutesToTimeType(autoTest.tolerance),
                type: autoTest.type,
                failEventRecurrenceInterval: convertMinutesToTimeType(
                  autoTest.failEventRecurrenceInterval,
                ),
              },
            }),
            ...(keepAlive?.active && {
              keepAlive: {
                id: keepAlive.id,
                active: keepAlive.active,
                interval: convertMinutesToTimeType(keepAlive.interval),
                tolerance: convertMinutesToTimeType(keepAlive.tolerance),
                failEventRecurrenceInterval: convertMinutesToTimeType(
                  keepAlive.failEventRecurrenceInterval,
                ),
                type: keepAlive.type,
              },
            }),
          }
        : {
            autoTest: {
              active: true,
              type: 'AUTOTEST',
              interval: {
                type: 'minutes',
              },
              tolerance: {
                type: 'minutes',
              },
            },
            keepAlive: {
              active: true,
              type: 'KEEPALIVE',
              interval: {
                type: 'minutes',
              },
              failEventRecurrenceInterval: {
                type: 'minutes',
              },
            },
          },
    },
  })

  const {
    formState: { isValid },
    handleSubmit,
    setValue,
    register,
    watch,
  } = form

  useEffect(() => {
    register('central.id')
    register('contacts')
  }, [register])

  useEffect(() => {
    if (centralToUpdate?.monitoring) {
      const converted = {
        autoTest: {
          id: centralToUpdate?.monitoring?.autoTest?.id,
          type: centralToUpdate?.monitoring?.autoTest?.type,
          active: centralToUpdate?.monitoring?.autoTest?.active,
          interval: convertMinutesToTimeType(
            centralToUpdate?.monitoring?.autoTest?.interval,
          ),
          tolerance: convertMinutesToTimeType(
            centralToUpdate?.monitoring?.autoTest?.tolerance,
          ),
          failEventRecurrenceInterval: convertMinutesToTimeType(
            centralToUpdate?.monitoring?.autoTest?.failEventRecurrenceInterval,
          ),
        },
        keepAlive: {
          id: centralToUpdate?.monitoring?.keepAlive?.id,
          type: centralToUpdate?.monitoring?.keepAlive?.type,
          active: centralToUpdate?.monitoring?.keepAlive?.active,
          interval: convertMinutesToTimeType(
            centralToUpdate?.monitoring?.keepAlive?.interval,
          ),
          tolerance: convertMinutesToTimeType(
            centralToUpdate?.monitoring?.keepAlive?.tolerance,
          ),
          failEventRecurrenceInterval: convertMinutesToTimeType(
            centralToUpdate?.monitoring?.keepAlive?.failEventRecurrenceInterval,
          ),
        },
      }
      setValue('monitoring', converted, {
        shouldValidate: false,
        shouldDirty: false,
      })
    }
  }, [centralToUpdate, setValue])

  useEffect(() => {
    if (centralToUpdate?.central) {
      setValue('central.active', centralToUpdate.central.active)
    }
  }, [centralToUpdate, setValue])

  const partitions = watch('partitions')

  const selectedPartition = useMemo(
    () => partitions?.find((partition) => partition.id === selectedPartitionId),
    [partitions, selectedPartitionId],
  )

  const { deviceType: centralDeviceType } = useGetDeviceType(
    CENTRAL_DEVICE_TYPE,
    true,
  )
  const { deviceType: gprsDeviceType } = useGetDeviceType(
    GPRS_DEVICE_TYPE,
    true,
  )

  const handleGetAccounts = useCallback(
    async (searchFilter: string, offset = 0) => {
      const response = await fetchAccounts(offset, 15, customerId, {
        ...(searchFilter && {
          code: searchFilter,
        }),
      })
      return response
    },
    [customerId],
  )

  const handleGetManufacturers = useCallback(
    async (searchFilter: string, offset = 0) =>
      await getManufacturers(offset, { name: searchFilter }),
    [],
  )

  const handleGetCentralModels = useCallback(
    async (searchFilter: string, offset = 0) =>
      await getDeviceModels(offset, {
        name: searchFilter,
        isUbisafe: false,
        deviceTypeId: centralDeviceType?.[0]?.id,
        manufacturerId: watch('central')?.manufacturer?.id,
      }),
    [centralDeviceType, watch],
  )

  const handleGetGPRSModels = useCallback(
    async (searchFilter: string, offset = 0) =>
      await getDeviceModels(offset, {
        isUbisafe: false,
        description: searchFilter,
        deviceTypeId: gprsDeviceType?.[0]?.id,
      }),
    [gprsDeviceType],
  )

  useEffect(() => {
    const model = centralToUpdate?.central?.modelDescription
    if (!model) return

    handleGetGPRSModels(model).then(async (res) => {
      const found = res.data?.[0]
      if (found?.id) {
        setFoundModelType('gprs')

        setValue('central.gprs', true)
        setValue('central.gprsModuleModel.id', found.id)
        setValue('central.gprsModuleModel.name', found.name)
        setValue('central.grpsManufacturer.id', found.manufacturerId)

        if (found.manufacturerId) {
          const manufacturer = await getManufacturerById(found.manufacturerId)
          if (manufacturer) {
            setValue('central.grpsManufacturer.name', manufacturer.name, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        }
      }
    })

    handleGetCentralModels(model).then(async (res) => {
      const found = res.data?.[0]
      if (found?.id) {
        setFoundModelType('central')

        setValue('central.model.id', found.id)
        setValue('central.model.name', found.name)
        setValue('central.manufacturer.id', found.manufacturerId)

        if (found.manufacturerId) {
          const manufacturer = await getManufacturerById(found.manufacturerId)
          if (manufacturer) {
            setValue('central.manufacturer.name', manufacturer.name, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        }
      }
    })
  }, [centralToUpdate, handleGetGPRSModels, handleGetCentralModels])

  const handleSelectCentralModel = useCallback(
    async (option: DeviceModelOutput) => {
      setValue('central.model.id', option.id, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setValue('central.model.name', option.name, {
        shouldValidate: true,
        shouldDirty: true,
      })

      setValue('central.manufacturer.id', option.manufacturerId, {
        shouldValidate: true,
        shouldDirty: true,
      })

      if (option.manufacturerId) {
        try {
          const manufacturer = await getManufacturerById(option.manufacturerId)
          if (manufacturer) {
            setValue('central.manufacturer.name', manufacturer.name, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    [setValue],
  )

  const handleSelectGprsModel = useCallback(
    async (option: DeviceModelOutput) => {
      setValue('central.gprsModuleModel.id', option.id, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setValue('central.gprsModuleModel.name', option.name, {
        shouldValidate: true,
        shouldDirty: true,
      })

      setValue('central.grpsManufacturer.id', option.manufacturerId, {
        shouldValidate: true,
        shouldDirty: true,
      })

      try {
        if (option.manufacturerId) {
          const manufacturer = await getManufacturerById(option.manufacturerId)
          if (manufacturer) {
            setValue('central.grpsManufacturer.name', manufacturer.name, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    [setValue],
  )

  const handleAddPartition = useCallback(
    (partitionPayload: PartitionFragmentPayload) => {
      setValue(
        'partitions',

        partitionPayload?.id
          ? [
              ...partitions.filter(
                (partition) => partition.id !== partitionPayload.id,
              ),
              partitionPayload,
            ]
          : [...partitions, partitionPayload],

        {
          shouldValidate: true,
          shouldDirty: true,
        },
      )

      partitionModal.hide()
    },
    [partitionModal, partitions, setValue],
  )

  const handleToggleChange = useCallback(() => {
    const isActive = watch('central.active')

    if (centralToUpdate) {
      isActive ? deactivateCentralToggle.show() : activateCentralToggle.show()
    } else {
      setValue('central.active', !isActive)
    }
  }, [watch, setValue, deactivateCentralToggle, activateCentralToggle])

  return (
    <>
      <FormProvider {...form}>
        {!!centralToUpdate?.central && (
          <>
            <DeactivateCentralModal
              centralId={centralToUpdate.central.id}
              onClose={deactivateCentralToggle.hide}
              isOpen={deactivateCentralToggle.isVisible}
            />
            <ActivateCentralModal
              centralId={centralToUpdate.central.id}
              onClose={activateCentralToggle.hide}
              isOpen={activateCentralToggle.isVisible}
            />
          </>
        )}
        {!!centralToUpdate && (
          <>
            <DisableMonitoringParametersModal
              isVisible={disableKeepAliveModal.isVisible}
              onClose={disableKeepAliveModal.hide}
              centralId={centralToUpdate.central.id}
              event={keepAlive}
            />
            <DisableMonitoringParametersModal
              isVisible={disableAutoTestModal.isVisible}
              onClose={disableAutoTestModal.hide}
              centralId={centralToUpdate.central.id}
              event={autoTest}
            />
            <EnableMonitoringParametersModal
              isVisible={enablekeepAliveModal.isVisible}
              onClose={enablekeepAliveModal.hide}
              centralId={centralToUpdate.central.id}
              event={keepAlive}
            />
            <EnableMonitoringParametersModal
              isVisible={enableAutoTestModal.isVisible}
              onClose={enableAutoTestModal.hide}
              centralId={centralToUpdate.central.id}
              event={autoTest}
            />
          </>
        )}
        {partitionModal.isVisible && (
          <AddPartitionModal
            onClose={partitionModal.hide}
            onSave={handleAddPartition}
            partition={selectedPartition}
            isVisible={partitionModal.isVisible}
            isUbisafe={centralToUpdate?.central.model.isUbisafe}
          />
        )}
        <form
          className={styles.container}
          onSubmit={(event) => event.preventDefault()}
        >
          <div>
            <header>
              <FormItem>
                <FormLabel htmlFor="account">Conta</FormLabel>
                <ComboBoxV3.Root
                  valueKey="aggregatedAccountName"
                  findOptions={handleGetAccounts}
                >
                  {({ unSelectedOptions }) => (
                    <>
                      <ComboBoxV3.Field
                        id="account"
                        value={watch('account')?.aggregatedAccountName || ''}
                        disabled={centralToUpdate?.central.model.isUbisafe}
                      />
                      <ComboBoxV3.Group>
                        {unSelectedOptions?.map((option) => (
                          <ComboBoxV3.Option
                            aria-disabled={!!option.central?.id}
                            key={option.id}
                            onClick={() => {
                              setValue('account', {
                                aggregatedAccountName:
                                  option.aggregatedAccountName,
                                id: option.id,
                                name: option.aggregatedAccountName,
                              })
                            }}
                          >
                            {option.aggregatedAccountName}
                            <span>{!!option.central?.id && 'Em uso'}</span>
                          </ComboBoxV3.Option>
                        ))}
                      </ComboBoxV3.Group>
                    </>
                  )}
                </ComboBoxV3.Root>
              </FormItem>
            </header>
            <div>
              <section>
                <header>
                  <span>1</span>
                  <div>
                    <Info.Root>
                      <Info.Title>Dados gerais</Info.Title>
                      <Info.Content>
                        Informações básicas sobre o equipamento
                      </Info.Content>
                    </Info.Root>
                  </div>
                </header>

                <main>
                  <div className={styles.toggle}>
                    <Toggle
                      disabled={centralToUpdate?.central.model.isUbisafe}
                      id="active"
                      checked={!!watch('central.active')}
                      onChange={handleToggleChange}
                    />
                    <FormLabel>Central ativa</FormLabel>
                  </div>
                  <FormItem>
                    <FormLabel htmlFor="centralManufacturer">
                      Fabricante da central
                    </FormLabel>
                    <ComboBoxV3.Root
                      valueKey="name"
                      findOptions={handleGetManufacturers}
                    >
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            id="centralManufacturer"
                            value={watch('central')?.manufacturer?.name}
                            disabled={
                              centralToUpdate?.central.model.isUbisafe ||
                              foundModelType === 'central'
                            }
                            placeholder="Selecione um fabricante"
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={() => {
                                  setValue(
                                    'central.manufacturer',
                                    {
                                      id: option.id,
                                      name: option.name,
                                    },
                                    {
                                      shouldValidate: false,
                                    },
                                  )
                                }}
                              >
                                {option.name}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>

                  <FormItem>
                    <FormLabel htmlFor="centralModel">
                      Modelo da central
                    </FormLabel>
                    <ComboBoxV3.Root
                      valueKey="name"
                      findOptions={handleGetCentralModels}
                    >
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            key={foundModelType}
                            id="centralModel"
                            value={watch('central')?.model.name}
                            disabled={
                              centralToUpdate?.central.model.isUbisafe ||
                              foundModelType === 'central'
                            }
                            placeholder="Selecione um modelo"
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={() => handleSelectCentralModel(option)}
                              >
                                {option.name}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>

                  <div className={styles.toggle}>
                    <Toggle
                      disabled={
                        centralToUpdate?.central.model.isUbisafe ||
                        foundModelType === 'gprs' ||
                        foundModelType === 'central'
                      }
                      id="gprs"
                      checked={
                        !!watch('central.gprs') && foundModelType !== 'central'
                      }
                      onChange={(e) => {
                        const checked = e.target.checked
                        setValue('central.gprs', checked, {
                          shouldValidate: true,
                          shouldDirty: true,
                        })

                        if (!checked) {
                          setValue('central.gprsModuleModel.id', '', {
                            shouldValidate: true,
                          })
                          setValue('central.gprsModuleModel.name', '', {
                            shouldValidate: true,
                          })
                          setValue('central.grpsManufacturer.id', '', {
                            shouldValidate: true,
                          })
                          setValue('central.grpsManufacturer.name', '', {
                            shouldValidate: true,
                          })
                        }
                      }}
                    />
                    <FormLabel htmlFor="gprs">Possui módulo GPRS</FormLabel>
                  </div>

                  {!!watch('central.gprs') && (
                    <>
                      <FormItem>
                        <FormLabel htmlFor="gprsManufacturer">
                          Fabricante do módulo GPRS
                        </FormLabel>
                        <ComboBoxV3.Root
                          valueKey="name"
                          findOptions={handleGetManufacturers}
                        >
                          {({ unSelectedOptions }) => (
                            <>
                              <ComboBoxV3.Field
                                id="gprsManufacturer"
                                value={watch('central')?.grpsManufacturer?.name}
                                placeholder="Selecione um fabricante"
                                disabled={
                                  centralToUpdate?.central.model.isUbisafe ||
                                  foundModelType === 'gprs'
                                }
                              />
                              <ComboBoxV3.Group>
                                {unSelectedOptions?.map((option) => (
                                  <ComboBoxV3.Option
                                    key={option.id}
                                    onClick={() => {
                                      setValue(
                                        'central.grpsManufacturer.name',
                                        option.name,
                                      )
                                    }}
                                  >
                                    {option.name}
                                  </ComboBoxV3.Option>
                                ))}
                              </ComboBoxV3.Group>
                            </>
                          )}
                        </ComboBoxV3.Root>
                      </FormItem>
                      <FormItem>
                        <FormLabel htmlFor="gprsModuleModelId">
                          Modelo do módulo GPRS
                        </FormLabel>
                        <ComboBoxV3.Root
                          valueKey="name"
                          findOptions={handleGetGPRSModels}
                        >
                          {({ unSelectedOptions }) => (
                            <>
                              <ComboBoxV3.Field
                                id="gprsModuleModelId"
                                key={foundModelType}
                                value={watch('central')?.gprsModuleModel?.name}
                                placeholder="Selecione um modelo"
                                disabled={
                                  centralToUpdate?.central.model.isUbisafe ||
                                  foundModelType === 'gprs'
                                }
                              />
                              <ComboBoxV3.Group>
                                {unSelectedOptions?.map((option) => (
                                  <ComboBoxV3.Option
                                    key={option.id}
                                    onClick={() =>
                                      handleSelectGprsModel(option)
                                    }
                                  >
                                    {option.name}
                                  </ComboBoxV3.Option>
                                ))}
                              </ComboBoxV3.Group>
                            </>
                          )}
                        </ComboBoxV3.Root>
                      </FormItem>
                    </>
                  )}
                  <FormItem>
                    <RadioGroup
                      title="Meio de comunicação"
                      customStyle={styles.communicationMethod}
                    >
                      {centralCommunicationMethods.map((method, key) => (
                        <RadioButton
                          disabled={centralToUpdate?.central.model.isUbisafe}
                          {...register('central.communicationMethod')}
                          checked={
                            watch('central.communicationMethod') ===
                            method.label
                          }
                          onChange={(e) => {
                            const value = e.target
                              .value as CentralCommunicationMethods
                            setValue('central.communicationMethod', value)

                            if (value === 'Linha telefônica') {
                              setValue('central.code', '')
                              setValue('monitoring.keepAlive', null, {
                                shouldValidate: true,
                              })
                            }
                          }}
                          value={method.label}
                          key={key}
                        />
                      ))}
                    </RadioGroup>
                  </FormItem>
                  {watch('central.communicationMethod') === 'IP' && (
                    <FormItem>
                      <FormLabel htmlFor="code">Código</FormLabel>
                      <TextField
                        disabled={centralToUpdate?.central.model.isUbisafe}
                        id="code"
                        {...register('central.code')}
                        onChange={(e) =>
                          setValue(
                            'central.code',
                            formatMACORIMEI(e.target.value),
                            { shouldValidate: true, shouldDirty: true },
                          )
                        }
                        placeholder="Digite o número do código MAC/IMEI da central"
                      />
                    </FormItem>
                  )}

                  <FormItem>
                    <FormLabel htmlFor="topicGroup">
                      Grupo de centrais
                    </FormLabel>
                    <TextField
                      disabled={centralToUpdate?.central.model.isUbisafe}
                      id="topicGroup"
                      maxLength={4}
                      {...register('central.topicGroup')}
                      onChange={(e) => {
                        const input = e.target.value || ''
                        if (
                          validateHexdecimalCharacter(input) ||
                          input === ''
                        ) {
                          setValue(
                            'central.topicGroup',
                            e.target.value.toUpperCase(),
                            { shouldValidate: true, shouldDirty: true },
                          )
                        }
                      }}
                      placeholder="Digite o número do grupo de centrais"
                    />
                  </FormItem>
                  <FormItem>
                    <FormLabel htmlFor="externalId">Conta da central</FormLabel>
                    <TextField
                      disabled={centralToUpdate?.central.model.isUbisafe}
                      id="externalId"
                      {...register('central.externalId')}
                      placeholder="Digite o número da conta da central"
                      maxLength={4}
                      onChange={(e) => {
                        const input = e.target.value || ''
                        if (
                          validateHexdecimalCharacter(input) ||
                          input === ''
                        ) {
                          setValue(
                            'central.externalId',
                            e.target.value.toUpperCase(),
                            { shouldValidate: true, shouldDirty: true },
                          )
                        }
                      }}
                    />
                  </FormItem>
                  {hasAccess(UbideskPermissions.CENTRAL_COMMAND_PASSWORD) && (
                    <FormItem>
                      <FormLabel htmlFor="commandPassword">
                        Senha para comandos
                        <TooltipV2.Root>
                          <TooltipV2.Trigger>
                            <Icon
                              name="information"
                              color="element-medium"
                              width={12}
                            />
                          </TooltipV2.Trigger>
                          <TooltipV2.Content position="top">
                            Chave de segurança para envio de comandos à central
                            de alarme.
                          </TooltipV2.Content>
                        </TooltipV2.Root>
                      </FormLabel>
                      <FormGroup>
                        <TextField
                          id="commandPassword"
                          type={viewPassword ? 'password' : 'text'}
                          {...register('central.commandPassword')}
                          autoComplete="new-password"
                          onChange={(event) => {
                            setValue(
                              'central.commandPassword',
                              event?.target.value,
                            )
                          }}
                        />
                        <FormAddon>
                          <Icon
                            onClick={() => setViewPassword((old) => !old)}
                            name={viewPassword ? 'view' : 'hide'}
                            color="element"
                          />
                        </FormAddon>
                      </FormGroup>
                    </FormItem>
                  )}
                  <div className={styles.imageWrapper}>
                    {!selectedImage &&
                      !centralToUpdate?.central?.installationImageId && (
                        <ButtonV2 appearance="tertiary">
                          <label htmlFor="central-image">
                            <Icon name="add" />
                            Adicionar foto da instalação (opcional)
                          </label>
                        </ButtonV2>
                      )}

                    {selectedImage ? (
                      <label htmlFor="central-image">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="Imagem selecionada da central"
                        />
                      </label>
                    ) : (
                      <>
                        {!!centralToUpdate?.central.installationImageId && (
                          <div>
                            <label
                              htmlFor="central-image"
                              className={styles.imageWrapper}
                            >
                              <ProtectedImage
                                className={styles.centralImage}
                                imageId={
                                  centralToUpdate.central.installationImageId
                                }
                              />
                            </label>
                          </div>
                        )}
                      </>
                    )}

                    <input
                      type="file"
                      id="central-image"
                      aria-label="central-image-input"
                      accept="image/*"
                      {...register('image')}
                      onChange={(e) => {
                        setSelectedImage(e.currentTarget.files?.[0])

                        setValue('image', e.currentTarget.files?.[0], {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }}
                    />
                  </div>
                </main>
              </section>
              <section>
                <header>
                  <span>2</span>
                  <div>
                    <Info.Root>
                      <Info.Title>Configurações de monitoramento</Info.Title>
                      <Info.Content>
                        Parâmetros do mecanismo &quot;keep-Alive&quot; e dos
                        testes automáticos
                      </Info.Content>
                    </Info.Root>
                  </div>
                </header>
                <main>
                  {watch('central.communicationMethod') === 'IP' && (
                    <div className={styles.toggle}>
                      <Toggle
                        disabled={centralToUpdate?.central.model.isUbisafe}
                        id="keepAlive"
                        checked={watch('monitoring')?.keepAlive?.active}
                        onChange={(e) => {
                          const checked = e.target.checked

                          if (keepAlive && !keepAlive?.active) {
                            enablekeepAliveModal.show()
                          } else if (
                            keepAlive &&
                            keepAlive?.active &&
                            !checked
                          ) {
                            disableKeepAliveModal.show()
                          } else {
                            setValue('monitoring.keepAlive.active', checked)
                            setValue('monitoring.keepAlive.type', 'KEEPALIVE')
                            setValue(
                              'monitoring.keepAlive.failEventRecurrenceInterval.type',
                              'minutes',
                            )
                            setValue(
                              'monitoring.keepAlive.interval.type',
                              'minutes',
                            )
                            if (!checked) {
                              setValue('monitoring.keepAlive', null, {
                                shouldValidate: true,
                              })
                            }
                          }
                        }}
                      />
                      <FormLabel htmlFor="keppAlive">
                        Habilitar &quot;keep-Alive&quot;
                      </FormLabel>
                    </div>
                  )}
                  {watch('central.communicationMethod') === 'IP' &&
                    !!watch(`monitoring.keepAlive.active`) && (
                      <section className={styles.flexColumn}>
                        <div>
                          <div>
                            <FormItem>
                              <FormLabel htmlFor="keepAliveInterval">
                                Tempo
                                <TooltipV2.Root>
                                  <TooltipV2.Trigger>
                                    <Icon
                                      name="information"
                                      width={10}
                                      color="element-medium"
                                    />
                                  </TooltipV2.Trigger>
                                  <TooltipV2.Content
                                    position="bottom"
                                    size="lg"
                                  >
                                    <span>
                                      Frequência de verificação da comunicação
                                      entre dispositivos conectados.
                                    </span>
                                  </TooltipV2.Content>
                                </TooltipV2.Root>
                              </FormLabel>

                              <NumberField
                                disabled={
                                  centralToUpdate?.central.model.isUbisafe
                                }
                                id="keepAliveInterval"
                                value={watch(
                                  `monitoring.keepAlive.interval.time`,
                                )}
                                onChange={(e) => {
                                  const value = e.target.value
                                  setValue(
                                    `monitoring.keepAlive.interval.time`,
                                    value,
                                    { shouldValidate: true, shouldDirty: true },
                                  )
                                }}
                              />
                            </FormItem>

                            <SelectV2.Root valueKey="value" data={timeOptions}>
                              {({ unSelectedOptions }) => (
                                <>
                                  <SelectV2.Field
                                    disabled={
                                      centralToUpdate?.central.model.isUbisafe
                                    }
                                    value={
                                      timeOption[
                                        watch(
                                          `monitoring.keepAlive.interval.type`,
                                        )
                                      ]
                                    }
                                  />
                                  <SelectV2.Group>
                                    {unSelectedOptions?.map((option) => (
                                      <SelectV2.Option
                                        key={option.value}
                                        onClick={() => {
                                          setValue(
                                            `monitoring.keepAlive.interval.type`,
                                            option.value,
                                            { shouldValidate: true },
                                          )
                                        }}
                                      >
                                        {option.name}
                                      </SelectV2.Option>
                                    ))}
                                  </SelectV2.Group>
                                </>
                              )}
                            </SelectV2.Root>
                          </div>
                          <div>
                            <FormItem>
                              <FormLabel htmlFor="failEventRecurrenceInterval">
                                Intervalo entre falhas
                                <TooltipV2.Root>
                                  <TooltipV2.Trigger>
                                    <Icon
                                      name="information"
                                      width={10}
                                      color="element-medium"
                                    />
                                  </TooltipV2.Trigger>
                                  <TooltipV2.Content
                                    position="bottom"
                                    size="lg"
                                  >
                                    <span>
                                      Tempo definido entre a geração de eventos
                                      em caso de falha de comunicação. Ao
                                      preencher &quot;0&quot;, o evento será
                                      gerado uma única vez.
                                    </span>
                                  </TooltipV2.Content>
                                </TooltipV2.Root>
                              </FormLabel>

                              <NumberField
                                disabled={
                                  centralToUpdate?.central.model.isUbisafe
                                }
                                id="failEventRecurrenceInterval"
                                value={watch(
                                  `monitoring.keepAlive.failEventRecurrenceInterval.time`,
                                )}
                                onChange={(e) => {
                                  const value = e.target.value
                                  setValue(
                                    `monitoring.keepAlive.failEventRecurrenceInterval.time`,
                                    value,
                                    { shouldValidate: true, shouldDirty: true },
                                  )
                                }}
                              />
                            </FormItem>

                            <SelectV2.Root valueKey="value" data={timeOptions}>
                              {({ unSelectedOptions }) => (
                                <>
                                  <SelectV2.Field
                                    disabled={
                                      centralToUpdate?.central.model.isUbisafe
                                    }
                                    value={
                                      timeOption[
                                        watch(
                                          `monitoring.keepAlive.failEventRecurrenceInterval.type`,
                                        )
                                      ]
                                    }
                                  />
                                  <SelectV2.Group>
                                    {unSelectedOptions?.map((option) => (
                                      <SelectV2.Option
                                        key={option.value}
                                        onClick={() => {
                                          setValue(
                                            `monitoring.keepAlive.failEventRecurrenceInterval.type`,
                                            option.value,
                                            { shouldValidate: true },
                                          )
                                        }}
                                      >
                                        {option.name}
                                      </SelectV2.Option>
                                    ))}
                                  </SelectV2.Group>
                                </>
                              )}
                            </SelectV2.Root>
                          </div>
                        </div>
                      </section>
                    )}
                  {!centralToUpdate?.central.model.isUbisafe &&
                    watch('central.communicationMethod') === 'IP' &&
                    !!keepAlive &&
                    !keepAlive?.active && (
                      <div className={styles.disabledByinfo}>
                        <span>
                          Desabilitado em {maskedDate(keepAlive?.updatedAt)} por{' '}
                          {keepAlive?.disabledBy?.name}{' '}
                        </span>{' '}
                        <ButtonV2
                          onClick={disableKeepAliveModal.show}
                          appearance="tertiary"
                          size="md"
                        >
                          <Icon name="open" />
                          Ver detalhes
                        </ButtonV2>
                      </div>
                    )}
                  <div className={styles.toggle}>
                    <Toggle
                      disabled={centralToUpdate?.central.model.isUbisafe}
                      id="autoTest"
                      checked={watch('monitoring')?.autoTest?.active}
                      onChange={(e) => {
                        const checked = e.target.checked

                        if (autoTest && !autoTest?.active) {
                          enableAutoTestModal.show()
                        } else if (autoTest && autoTest?.active && !checked) {
                          disableAutoTestModal.show()
                        } else {
                          setValue('monitoring.autoTest.active', checked)
                          setValue('monitoring.autoTest.type', 'AUTOTEST')
                          setValue(
                            'monitoring.autoTest.tolerance.type',
                            'minutes',
                          )
                          setValue(
                            'monitoring.autoTest.interval.type',
                            'minutes',
                          )
                          if (!checked) {
                            setValue('monitoring.autoTest', null, {
                              shouldValidate: true,
                            })
                          }
                        }
                      }}
                    />
                    <FormLabel htmlFor="autoTest">
                      Habilitar teste automático
                    </FormLabel>
                  </div>
                  {watch(`monitoring.autoTest.active`) && (
                    <div className={styles.flexColumn}>
                      <div>
                        <div>
                          <FormItem>
                            <FormLabel htmlFor="autoTestInterval">
                              Tempo
                              <TooltipV2.Root>
                                <TooltipV2.Trigger>
                                  <Icon
                                    name="information"
                                    width={10}
                                    color="element-medium"
                                  />
                                </TooltipV2.Trigger>
                                <TooltipV2.Content position="bottom" size="lg">
                                  <span>
                                    Intervalo programado para que um teste
                                    automático seja enviado para o sistema.
                                  </span>
                                </TooltipV2.Content>
                              </TooltipV2.Root>
                            </FormLabel>
                            <NumberField
                              disabled={
                                centralToUpdate?.central.model.isUbisafe
                              }
                              value={watch(`monitoring.autoTest.interval.time`)}
                              id="autoTestInterval"
                              onChange={(e) => {
                                const value = e.target.value
                                setValue(
                                  `monitoring.autoTest.interval.time`,
                                  value,
                                  { shouldValidate: true, shouldDirty: true },
                                )
                              }}
                            />
                          </FormItem>

                          <SelectV2.Root valueKey="value" data={timeOptions}>
                            {({ unSelectedOptions }) => (
                              <>
                                <SelectV2.Field
                                  disabled={
                                    centralToUpdate?.central.model.isUbisafe
                                  }
                                  value={
                                    timeOption[
                                      watch(`monitoring.autoTest.interval.type`)
                                    ]
                                  }
                                />
                                <SelectV2.Group>
                                  {unSelectedOptions?.map((option) => (
                                    <SelectV2.Option
                                      key={option.value}
                                      onClick={() => {
                                        setValue(
                                          `monitoring.autoTest.interval.type`,
                                          option.value,
                                          { shouldValidate: true },
                                        )
                                      }}
                                    >
                                      {option.name}
                                    </SelectV2.Option>
                                  ))}
                                </SelectV2.Group>
                              </>
                            )}
                          </SelectV2.Root>
                        </div>
                        <div>
                          <FormItem>
                            <FormLabel htmlFor="autoTestTolerance">
                              Tolerância
                              <TooltipV2.Root>
                                <TooltipV2.Trigger>
                                  <Icon
                                    name="information"
                                    width={10}
                                    color="element-medium"
                                  />
                                </TooltipV2.Trigger>
                                <TooltipV2.Content position="bottom" size="lg">
                                  <span>
                                    Período de espera pelo envio do teste
                                    automático. Se esse prazo for ultrapassado,
                                    será gerado um evento de falha de
                                    comunicação.
                                  </span>
                                </TooltipV2.Content>
                              </TooltipV2.Root>
                            </FormLabel>
                            <NumberField
                              disabled={
                                centralToUpdate?.central.model.isUbisafe
                              }
                              id="enableAutomaticTestTime"
                              value={watch(
                                `monitoring.autoTest.tolerance.time`,
                              )}
                              onChange={(e) => {
                                const value = e.target.value
                                setValue(
                                  `monitoring.autoTest.tolerance.time`,
                                  value,
                                  { shouldValidate: true, shouldDirty: true },
                                )
                              }}
                            />
                          </FormItem>

                          <SelectV2.Root valueKey="value" data={timeOptions}>
                            {({ unSelectedOptions }) => (
                              <>
                                <SelectV2.Field
                                  disabled={
                                    centralToUpdate?.central.model.isUbisafe
                                  }
                                  value={
                                    timeOption[
                                      watch(
                                        `monitoring.autoTest.tolerance.type`,
                                      )
                                    ]
                                  }
                                />
                                <SelectV2.Group>
                                  {unSelectedOptions?.map((option) => (
                                    <SelectV2.Option
                                      key={option.value}
                                      onClick={() => {
                                        setValue(
                                          `monitoring.autoTest.tolerance.type`,
                                          option.value,
                                          { shouldValidate: true },
                                        )
                                      }}
                                    >
                                      {option.name}
                                    </SelectV2.Option>
                                  ))}
                                </SelectV2.Group>
                              </>
                            )}
                          </SelectV2.Root>
                        </div>
                      </div>
                    </div>
                  )}
                  {!centralToUpdate?.central.model.isUbisafe &&
                    !!autoTest &&
                    !autoTest?.active && (
                      <div className={styles.disabledByinfo}>
                        <span>
                          Desabilitado em {maskedDate(autoTest?.updatedAt)} por{' '}
                          {autoTest?.disabledBy?.name}{' '}
                        </span>{' '}
                        <ButtonV2
                          onClick={disableAutoTestModal.show}
                          appearance="tertiary"
                          size="md"
                        >
                          <Icon name="open" />
                          Ver detalhes
                        </ButtonV2>
                      </div>
                    )}
                </main>
              </section>
              <section>
                <header>
                  <span>3</span>
                  <div>
                    <Info.Root>
                      <Info.Title>Particionamento</Info.Title>
                      <Info.Content>
                        Segmentação do sistema de alarme em áreas independentes
                      </Info.Content>
                    </Info.Root>
                  </div>
                </header>
                <main>
                  <div className={styles.partitioning}>
                    <header>
                      <h2>Partição</h2>
                      {!centralToUpdate?.central.model.isUbisafe && (
                        <ButtonV2
                          appearance="tertiary"
                          onClick={() => {
                            setSelectedPartitionId('')
                            partitionModal.show()
                          }}
                        >
                          <Icon name="add" />
                          Nova partição
                        </ButtonV2>
                      )}
                    </header>

                    {!!partitions?.length && !centralToUpdate && (
                      <DragAndDrop
                        items={partitions.sort((a, b) => {
                          const codeA = a.code.toLowerCase()
                          const codeB = b.code.toLowerCase()

                          return codeA < codeB ? -1 : codeA > codeB ? 1 : 0
                        })}
                        getItem={getItem}
                        {...register('partitions')}
                        onDelete={(partitionToDelete) =>
                          setValue(
                            'partitions',
                            partitions.filter(
                              (partition) =>
                                partition.code !== partitionToDelete.code,
                            ),
                            { shouldValidate: true, shouldDirty: true },
                          )
                        }
                        draggable={false}
                      />
                    )}
                    {!!partitions?.length && centralToUpdate && (
                      <DragAndDrop
                        items={partitions.sort((a, b) => {
                          const codeA = a.code.toLowerCase()
                          const codeB = b.code.toLowerCase()

                          return codeA < codeB ? -1 : codeA > codeB ? 1 : 0
                        })}
                        getItem={getItem}
                        onEdit={(item) => {
                          setSelectedPartitionId(item.id)
                          partitionModal.show()
                        }}
                        draggable={false}
                        canDelete={false}
                      />
                    )}
                  </div>
                </main>
              </section>
            </div>
          </div>
          <footer>
            <ButtonV2
              title="Voltar"
              appearance="tertiary"
              onClick={() => navigate('/account/management/central')}
            >
              Voltar à lista
            </ButtonV2>

            {centralToUpdate?.central.id &&
            centralToUpdate?.central.commandPassword &&
            watch('central').commandPassword !==
              centralToUpdate?.central.commandPassword ? (
              <ModalV2.Root>
                <ModalV2.Content>
                  <ModalV2.Title>
                    <Icon name="warning" color="danger" />
                    Alterar senha para comandos
                  </ModalV2.Title>
                  <p>
                    Tem certeza que deseja alterar a senha desta central? Se a
                    nova senha estiver incorreta, o envio de comandos remotos
                    será bloqueado.
                  </p>
                  <ModalV2.Footer>
                    <ModalV2.Close>
                      <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
                    </ModalV2.Close>
                    <ButtonV2 type="submit" onClick={() => onSubmit(watch())}>
                      Alterar
                    </ButtonV2>
                  </ModalV2.Footer>
                </ModalV2.Content>
                <ModalV2.Trigger>
                  <ButtonV2 appearance="primary" disabled={!isValid}>
                    Salvar
                  </ButtonV2>
                </ModalV2.Trigger>
              </ModalV2.Root>
            ) : (
              <ButtonV2
                appearance="primary"
                type="submit"
                disabled={!isValid}
                onClick={() => onSubmit(watch())}
              >
                Salvar
              </ButtonV2>
            )}
          </footer>
        </form>
      </FormProvider>
    </>
  )
}
