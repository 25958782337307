export type TransformValues = {
  scale: number
  rotate: number
  translateX: number
  translateY: number
}

export const extractTransformValues = (transform: string): TransformValues => {
  const values = {
    scale: 1,
    rotate: 0,
    translateX: 0,
    translateY: 0,
  }

  const scaleMatch = transform.match(/scale\(([^)]+)\)/)
  if (scaleMatch) values.scale = parseFloat(scaleMatch[1])

  const rotateMatch = transform.match(/rotate\(([^)]+)deg\)/)
  if (rotateMatch) values.rotate = parseFloat(rotateMatch[1])

  const translateMatch = transform.match(/translate\(([^,]+)px, ([^)]+)px\)/)
  if (translateMatch) {
    values.translateX = parseFloat(translateMatch[1])
    values.translateY = parseFloat(translateMatch[2])
  }

  return values
}

export const buildTransform = ({
  scale = 1,
  rotate = 0,
  translateX = 0,
  translateY = 0,
}: Partial<TransformValues>) =>
  `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg) scale(${scale})`

export const updateTransform = (
  image: HTMLImageElement | null,
  newTransform: Partial<TransformValues>,
): string => {
  if (!image) return ''

  const currentTransform = extractTransformValues(image.style.transform)
  const updatedTransform = buildTransform({
    ...currentTransform,
    ...newTransform,
  })

  return updatedTransform
}

export const calculateZoomFocus = (
  image: HTMLImageElement,
  clientX: number,
  clientY: number,
  newScale: number,
  translateRef: React.RefObject<{ x: number; y: number }>,
) => {
  const rect = image.getBoundingClientRect()
  const currentTransform = extractTransformValues(image.style.transform)

  const scaleChange = newScale / currentTransform.scale

  const offsetX =
    (clientX - rect.left - rect.width / 2) / currentTransform.scale
  const offsetY =
    (clientY - rect.top - rect.height / 2) / currentTransform.scale

  const newTranslate = {
    x:
      translateRef.current!.x -
      Math.round(offsetX * (scaleChange - 1) * newScale * 100) / 100,
    y:
      translateRef.current!.y -
      Math.round(offsetY * (scaleChange - 1) * newScale * 100) / 100,
  }

  return newTranslate
}
