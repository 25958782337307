import React, {
  ComponentProps,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import ReactDOM from 'react-dom'

import joinClassNames from 'utilities/joinClassNames'
import { useOnClickOutside } from 'shared/hooks/useOnClickOutside'

import styles from './styles.module.scss'

interface PopoverContextProps {
  isOpen: boolean
  toggle: () => void
  triggerRef: React.RefObject<HTMLButtonElement>
}

const PopoverContext = createContext<PopoverContextProps>({
  isOpen: false,
  toggle: () => null,
  triggerRef: { current: null },
})

type PopoverProps = ComponentProps<'div'>

const Root = ({ children, ...props }: PopoverProps) => {
  const [isOpen, toggle] = useReducer((isOpen) => !isOpen, false)
  const triggerRef = useRef(null)

  return (
    <PopoverContext.Provider value={{ isOpen, toggle, triggerRef }}>
      <div {...props}>{children}</div>
    </PopoverContext.Provider>
  )
}

type TriggerProps = ComponentProps<'button'> & {
  asChild?: boolean
}

const Trigger = ({ onClick, asChild, children, ...props }: TriggerProps) => {
  const { toggle, triggerRef, isOpen } = useContext(PopoverContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    toggle()
  }

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(children as React.ReactElement, {
      ref: triggerRef,
      'data-active': isOpen ? 'true' : false,
      onClick: handleClick,
    })
  }

  return (
    <button
      {...props}
      onClick={handleClick}
      ref={triggerRef}
      data-active={isOpen ? 'true' : false}
    >
      {children}
    </button>
  )
}

type Position = 'left' | 'right' | 'top' | 'bottom'

type ContentProps = ComponentProps<'div'> & {
  offsetX?: number
  offsetY?: number
  position?: Position
}

const Content = ({
  children,
  offsetX = 12,
  offsetY = -24,
  position = 'right',
  ...props
}: ContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { isOpen, triggerRef, toggle } = useContext(PopoverContext)

  useEffect(() => {
    const handleUpdatePosition = () => {
      if (triggerRef.current && contentRef.current) {
        const padding = 12

        const triggerRect = triggerRef.current.getBoundingClientRect()
        const contentRect = contentRef.current.getBoundingClientRect()

        const positions: Record<Position, { top: number; left: number }> = {
          left: {
            top: triggerRect.top + window.scrollY + offsetY,
            left:
              triggerRect.left + window.scrollX - contentRect.width - offsetX,
          },
          right: {
            top: triggerRect.top + window.scrollY + offsetY,
            left: triggerRect.right + window.scrollX + offsetX,
          },
          top: {
            top:
              triggerRect.top -
              triggerRect.height -
              window.scrollY -
              contentRect.height -
              offsetY,
            left: triggerRect.left + window.scrollX + offsetX,
          },
          bottom: {
            top:
              triggerRect.bottom +
              triggerRect.height +
              window.scrollY +
              offsetY,
            left: triggerRect.left + window.scrollX + offsetX,
          },
        }

        let { top, left } = positions[position]

        top = Math.max(
          padding,
          Math.min(top, window.innerHeight - contentRect.height - padding),
        )
        left = Math.max(
          padding,
          Math.min(left, window.innerWidth - contentRect.width - padding),
        )

        contentRef.current.style.top = `${top}px`
        contentRef.current.style.left = `${left}px`
      }
    }

    handleUpdatePosition()

    window.addEventListener('resize', handleUpdatePosition)
    window.addEventListener('scroll', handleUpdatePosition)

    return () => {
      window.removeEventListener('resize', handleUpdatePosition)
      window.removeEventListener('scroll', handleUpdatePosition)
    }
  }, [isOpen, triggerRef, position, offsetX, offsetY])

  useOnClickOutside(contentRef, toggle)

  return isOpen
    ? ReactDOM.createPortal(
        <div
          ref={contentRef}
          style={{ position: 'absolute', zIndex: 9999 }}
          {...props}
        >
          {children}
        </div>,
        document.body,
      )
    : null
}

type CloseProps = ComponentProps<'button'> & {
  asChild?: boolean
}

const Close = ({ children, onClick, asChild, ...props }: CloseProps) => {
  const { toggle } = useContext(PopoverContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    toggle()
  }

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(children as React.ReactElement, {
      onClick: handleClick,
    })
  }

  return (
    <button {...props} onClick={handleClick}>
      {children}
    </button>
  )
}

const Footer = ({ className, children, ...props }: ComponentProps<'div'>) => (
  <div {...props} className={joinClassNames(styles.footer, className)}>
    <hr />
    <div>{children}</div>
  </div>
)

const Popover = {
  Root,
  Trigger,
  Content,
  Close,
  Footer,
}

export default Popover
