import { ComponentProps, forwardRef } from 'react'
import styles from './styles.module.scss'

type ButtonSize = 'md' | 'sm'
type ButtonColor = 'brand' | 'red' | 'yellow' | 'element'
type ButtonAppearance = 'primary' | 'secondary' | 'tertiary'

export interface ButtonProps extends ComponentProps<'button'> {
  size?: ButtonSize
  color?: ButtonColor
  appearance?: ButtonAppearance
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      type = 'button',
      color = 'brand',
      appearance = 'primary',
      size,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        {...props}
        type={type}
        className={[
          styles.button,
          styles[color],
          styles[appearance],
          size && styles[size],
          className,
        ].join(' ')}
      />
    )
  },
)

Button.displayName = 'Button'

export default Button
