import { PartitionContact, PartitionDevice } from 'services/partition/types'
import { PatrimonyFragment, PatrimonyImage } from 'services/patrimony/types'
import { Paging, Sort, Timestamps } from '../types'
import {
  ServiceOrderLabelInput,
  ServiceOrderLabelOutput,
} from 'services/serviceOrderLabel/types'
import { DeviceTypeCodeOptions } from 'utilities/occurrence/data'
import { Owner } from 'services/image/types'

export type Evaluation = 'HAS' | 'HASNT'

export type AccountFragment = {
  id: string
  code: string
  centralId?: string
  serviceType?: {
    id: string
    name: string
  }
}

export enum ServiceOrderLabelType {
  ISSUE = 'ISSUE',
  ORIGIN = 'ORIGIN',
  PAUSE_REASON = 'PAUSE_REASON',
  REASON = 'REASON',
  RESOLUTION = 'RESOLUTION',
}

export type ServiceOrderLabelResponse = {
  id: string
  description: string
  type: ServiceOrderLabelType
}

export type CustomerFragment = {
  id: string
  name: string
}

export type TechnicianFragment = {
  id?: string
  name: string
}

export declare type EntityTag = {
  id: string
  name: string
  type: string
}

const schedulePeriod = {
  BUSINESS_HOURS: 'BUSINESS_HOURS',
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
}

export type SchedulePeriod = keyof typeof schedulePeriod

export enum SERVICE_ORDER_STATUS_ENUM {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  SCHEDULED = 'SCHEDULED',
  IN_VALIDATION = 'IN_VALIDATION',
  REPROVED = 'DISAPPROVED',
  RESCHEDULED = 'RESCHEDULED',
}

export enum SERVICE_ORDER_STATUS_PT_EN {
  'Aberta' = 'OPEN',
  'Agendada' = 'SCHEDULED',
  'Em andamento' = 'IN_PROGRESS',
  'Pausada' = 'PAUSED',
  'Em validação' = 'IN_VALIDATION',
  'Reprovada' = 'DISAPPROVED',
  'Finalizada' = 'FINISHED',
  'Cancelada' = 'CANCELED',
}

export enum SERVICE_ORDER_STATUS_EN_PT {
  'OPEN' = 'Aberta',
  'SCHEDULED' = 'Agendada',
  'IN_PROGRESS' = 'Em andamento',
  'PAUSED' = 'Pausada',
  'IN_VALIDATION' = 'Em validação',
  'DISAPPROVED' = 'Reprovada',
  'FINISHED' = 'Finalizada',
  'CANCELED' = 'Cancelada',
  'RESCHEDULED' = 'Reagendada',
}

export const serviceOrderStatusLabel = {
  Aberta: 'Aberta',
  Agendada: 'Agendada',
  'Em andamento': 'Em andamento',
  Pausada: 'Pausada',
  'Em validação': 'Em validação',
  Reprovada: 'Reprovada',
  Finalizada: 'Finalizada',
  Cancelada: 'Cancelada',
  Reagendada: 'Reagendada',
}

export type SERVICE_ORDER_STATUS_LABEL = keyof typeof serviceOrderStatusLabel

export enum SERVICE_ORDER_TYPE_ENUM {
  INSTALL = 'INSTALL',
  MAINTENANCE = 'MAINTENANCE',
  REMOTE_MAINTENANCE = 'REMOTE_MAINTENANCE',
  UNINSTALL = 'UNINSTALL',
}

export enum SERVICE_ORDER_TYPE_EN_PT {
  'INSTALL' = 'Instalação',
  'MAINTENANCE' = 'Manutenção',
  'REMOTE_MAINTENANCE' = 'Manutenção remota',
  'UNINSTALL' = 'Desinstalação',
}

export enum SERVICE_ORDER_TYPE_PT_EN {
  'Instalação' = SERVICE_ORDER_TYPE_ENUM.INSTALL,
  'Manutenção' = SERVICE_ORDER_TYPE_ENUM.MAINTENANCE,
  'Manutenção remota' = SERVICE_ORDER_TYPE_ENUM.REMOTE_MAINTENANCE,
  'Desinstalação' = SERVICE_ORDER_TYPE_ENUM.UNINSTALL,
}

export enum SERVICE_ORDER_SCHEDULE_PERIOD_ENUM {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  BUSINESS_HOURS = 'BUSINESS_HOURS',
}

export type ServiceOrderHistoryUser = {
  id: string
  name: string
}

export type ServiceOrderHistory = {
  status: SERVICE_ORDER_STATUS_ENUM
  datetime: number
  user: ServiceOrderHistoryUser
  note: string
  labels?: ServiceOrderLabelOutput[]
}

export type TagInput = {
  name: string
}

export type TagOutput = {
  id: string
  totalLinks: number
} & TagInput &
  Timestamps

export type ServiceOrderContact = {
  id: string
  name: string
  countryCode?: number
  provinceCode?: number
  number?: number
}

export declare type ServiceOrder = {
  accountId: string
  technicianId?: string | null
  userId: string
  note: string
  serviceOrderType?: SERVICE_ORDER_TYPE_ENUM
  status?: SERVICE_ORDER_STATUS_ENUM
  scheduleDate?: number
  schedulePeriod?: string
  contact?: ServiceOrderContact
  tags?: TagInput[]
  labels: ServiceOrderLabelInput[]
}

export type ServiceOrderStatus = {
  status: SERVICE_ORDER_STATUS_ENUM
  userId: string
  technicianId?: string
  note: string
  scheduleDate?: number
  schedulePeriod?: string
  contact?: ServiceOrderContact
}

export type ServiceOrderUpdateRequest = Pick<
  ServiceOrder,
  | 'accountId'
  | 'technicianId'
  | 'userId'
  | 'note'
  | 'serviceOrderType'
  | 'contact'
  | 'tags'
  | 'labels'
  | 'status'
  | 'scheduleDate'
  | 'schedulePeriod'
>

export type History = {
  status: SERVICE_ORDER_STATUS_ENUM
  datetime: number
  userId: string
  note: string
}

export type ServiceOrderResponse = {
  id: string
  number: number
  accountId: string
  technicianId: string
  status: SERVICE_ORDER_STATUS_ENUM
  history: History[]
} & Timestamps

export type AccountWithAggregatedName = AccountFragment & {
  aggregatedName: string
}

export type ServiceOrderAggregatedResponse = {
  id: string
  number: number
  status: SERVICE_ORDER_STATUS_ENUM
  serviceOrderType: SERVICE_ORDER_TYPE_ENUM
  scheduleDate?: number
  schedulePeriod?: SERVICE_ORDER_SCHEDULE_PERIOD_ENUM
  history?: ServiceOrderHistory[]
  contact?: ServiceOrderContact
  account?: AccountWithAggregatedName
  technician?: TechnicianFragment
  customer?: CustomerFragment
  note?: string
  patrimony?: Pick<PatrimonyFragment, 'id' | 'name'> & {
    address?: {
      address: string
      number: string
      district: string
      city: string
      acronym: string
    }
  }
  tags?: TagOutput[]
  reason?: string
  labels: ServiceOrderLabelInput[]
} & Timestamps

export type ServiceOrderQueryResponse = {
  id: string
  number: number
  accountId: string
  technicianId: string
  status: SERVICE_ORDER_STATUS_ENUM
} & Timestamps

export type CoverageType = 'SERVICE_ORDER_ATTENDANT'

const serviceOrderStatus = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
  IN_VALIDATION: 'IN_VALIDATION',
  DISAPPROVED: 'DISAPPROVED',
  RESCHEDULED: 'RESCHEDULED',
}
export type ServiceOrderStatuses = keyof typeof serviceOrderStatus

const serviceOrderTypes = {
  INSTALL: 'INSTALL',
  MAINTENANCE: 'MAINTENANCE',
  REMOTE_MAINTENANCE: 'REMOTE_MAINTENANCE',
  UNINSTALL: 'UNINSTALL',
}
export type ServiceOrderTypes = keyof typeof serviceOrderTypes

export type ServiceOrderQuery = Partial<{
  number: number
  userId: string
  accountIds: string[]
  technicianIds: string[]
  stationId: string
  coverageTypes: CoverageType[]
  applyAttendanceExclusivity: boolean
  status: ServiceOrderStatuses[]
  serviceOrderTypes: ServiceOrderTypes[]
  scheduleDateFrom: number
  scheduleDateTo: number
  closedAtFrom: number
  closedAtTo: number
  createdAtFrom: number
  createdAtTo: number
  labelIds: string[]
  serviceTypeId: string
  accountTagId: string
  hasTechnician: boolean
}> &
  Partial<Paging> &
  Partial<Sort>

export type ServiceOrderAggregatedQuery = {
  id: string
  number: number
  account: AccountFragment
  customer: CustomerFragment
  technician?: TechnicianFragment
  contact?: ServiceOrderContact
  patrimony: {
    id: string
    name: string
  }
  status: ServiceOrderStatuses[]
  scheduleDate: number
  schedulePeriod: SchedulePeriod
  type?: ServiceOrderTypes
  tags?: EntityTag[]
  labels: ServiceOrderLabelResponse[]
} & Timestamps

export type ServiceOrderAggregatedQueryResponse = {
  id: string
  number: number
  account: AccountFragment
  customer: CustomerFragment
  technician?: TechnicianFragment
  contact?: ServiceOrderContact
  patrimony: Pick<PatrimonyFragment, 'id' | 'name'>
  status: SERVICE_ORDER_STATUS_ENUM
  scheduleDate: number
  schedulePeriod: SchedulePeriod
  type?: SERVICE_ORDER_TYPE_ENUM
  tags?: EntityTag[]
  inProgressAt?: boolean
  labels: ServiceOrderLabelResponse[]
} & Timestamps

type TechnicianAdress = {
  postalCode: number
  district: string
  city: string
  state: string
  address: string
  number: string
  adjunct: string
  reference: string
}

export type Technician = {
  id: string
  name: string
  document: string
  phone: number
  address: TechnicianAdress
  tags?: { id: string; name: string }[]
  coverages?: { id: string; name: string }[]
}

export type AggregatedUserServiceOrders = Omit<
  ServiceOrderAggregatedQueryResponse,
  'technician' | 'patrimony'
> & {
  technician: Technician
  patrimony: {
    id?: string
    name?: string
    address?: {
      address: string
      number: string
      district: string
      city: string
      acronym?: string
    }
  }
}

export type CreateSoError = {
  response: {
    config: {
      baseURL: string
    }
    data?: ServiceOrderQueryResponse
    status: number
  }
}

export type Partitions = {
  id: string
  name: string
  code: string
  devices: PartitionDevice[]
  contacts: PartitionContact[]
  changedDevices?: PartitionDevice[]
  securityQuestion?: string
  securityAnswer?: string
  securityCoercionAnswer?: string
}

export enum PowerStatus {
  'ON' = 0,
  'OFF' = 1,
}

export enum ConnectionIPMode {
  'Indefinido' = 0,
  'DHCP' = 1,
  'Estático' = 2,
}

export type Ethernet = {
  connectionStatus: boolean
  ipMode: ConnectionIPMode
  ip: string
}

export enum CHANGE_TYPE {
  'REPLACED' = 'REPLACED',
  'ADDED' = 'ADDED',
  'REMOVED' = 'REMOVED',
}

export enum CHANGE_TYPE_EN_PT {
  'REPLACED' = 'Subtituído',
  'ADDED' = 'Adicionado',
  'REMOVED' = 'Removido',
}

export type Wifi = {
  connectionStatus: boolean
  signal: number
  ipMode: ConnectionIPMode
  ip: string
  ssid: string
}

export type GPRS = {
  connectionStatus: boolean
  telecom: string
  iccid: string
  signal: number
  moduleNumber: string
}

export type Central = {
  centralId: string
  firmwareVersionName: string
  powerStatus: PowerStatus
  ethernet: Ethernet
  wifi: Wifi
  gprs: GPRS
  partitions: Partitions[]
  changeType?: CHANGE_TYPE
  centralImage?: {
    id: string
    dateTime: string
  }
  model?: {
    id: string
    name: string
  }
  eventCount?: number
}

export type Contact = {
  id: string
  name: string
  countryCode: number
  provinceCode: number
  number: number
}

export type ServiceOrderFragment = {
  number: number
  type: SERVICE_ORDER_TYPE_ENUM
  status: SERVICE_ORDER_STATUS_ENUM
  schedule?: {
    date: number
    period: SERVICE_ORDER_SCHEDULE_PERIOD_ENUM
  }
  customer: CustomerFragment
  contact?: Contact
  technician?: TechnicianFragment
  account: AccountFragment
  patrimony: PatrimonyImage
  history: ServiceOrderHistory[]
  tags: EntityTag[]
  gallery?: {
    id: string
    creation: number
  }[]
}

export type DeviceRemoved = {
  name: string
  deviceTypeCode: DeviceTypeCodeOptions
  id?: string
  code?: string
}
export type EquipmentsModel = {
  id: string
  name: string
}

export type CentralInfo = {
  currentCentralId: string
  inProgressSnapshot?: Central
  finishedSnapshot?: Central
  currentShadow?: Central
  devicesRemoved?: DeviceRemoved[]
  hasCommandSupport?: boolean
  isUbisafe?: boolean
  model?: EquipmentsModel
}

export type ServiceOrderValidationInfo = {
  id: string
  serviceOrderInfo: ServiceOrderFragment
  centralInfo?: CentralInfo
} & Partial<Paging>

export type ServiceOrderTagQuery = {
  name?: string
  createdFrom?: number
  createdTo?: number
} & Partial<Paging>

export type ServiceOrderWithStatusUpdateRequest = Omit<
  ServiceOrderUpdateRequest,
  'accountId' | 'labels'
> &
  ServiceOrderStatus & {
    id?: string
    accountId: string
    central?: {
      id: string
      mode: 'defaultMode' | 'maintenanceMode'
    }
    labels?: ServiceOrderLabelInput[]
  }

export type ServiceOrderNoteInput = {
  userId: string
  description: string
}

export type ServiceOrderNoteQuery = {
  userId?: string
}
export type ServiceOrderNoteOutput = {
  id: string
  userName: string
  description: string
  createdAt: number
  updatedAt: number
}

export type PersistImagePayload = {
  images: File[]
  owners?: Owner[]
  principalOwner?: Owner
}
