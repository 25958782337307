import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'

import { AggregatedAccount } from 'services/account/types'

import styles from './Accounts.module.scss'
import {
  TableV2 as Table,
  Input,
  Button,
  PaginationV2 as Pagination,
  Icon,
  EmptyState,
  LoaderV2 as Loader,
} from 'components'
import { useToggle } from 'shared/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import {
  AccountFilters,
  accountFiltersSchema,
} from '../../schemas/searchAccountSchema'
import AccountDriver from 'services/account'

import { Filters } from './components/Filters/Filters'
import { CustomerPaths } from 'routes/constants/paths/customer'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import {
  getCentralBatteryIcon,
  getCentralCommunicationIcon,
  getCentralStatusIcon,
} from 'utilities/central'
import { validateHexdecimalCharacter } from 'utilities/validation'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const columnHelper = createColumnHelper<AggregatedAccount>()

export const Accounts = () => {
  const filterComponent = useToggle()

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { getFilters, setFilters } =
    usePersistentFilters<AccountFilters>('account')

  const form = useForm<AccountFilters>({
    resolver: joiResolver(accountFiltersSchema),
    defaultValues: getFilters(),
  })

  const { handleSubmit, setValue, register, watch } = form
  const onInspect = (item: AggregatedAccount) => {
    localStorage.setItem('accountId', item.id || '')
    localStorage.setItem('accountCode', item.code || '')
    localStorage.setItem(
      'aggregatedAccountName',
      item.aggregatedAccountName || '',
    )
    localStorage.setItem('centralId', item?.central?.id || '')
    localStorage.setItem('customerId', item.customer?.id || '')
    localStorage.setItem('patrimonyId', item.patrimony?.id || '')
    localStorage.setItem('customerName', item.customer?.name || '')
    navigate(CustomerPaths.Account)
  }

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '1',
    })
  }

  const code = watch('code')
  const state = watch('state')
  const city = watch('city')
  const address = watch('address')
  const customer = watch('customer')
  const district = watch('district')
  const createdTo = watch('createdTo')
  const createdFrom = watch('createdFrom')
  const externalId = watch('externalId')
  const accountTag = watch('accountTag')
  const centralCode = watch('centralCode')
  const contractNumber = watch('contractNumber')
  const customerBySecondName = watch('customerBySecondName')
  const patrimony = watch('patrimony')

  useEffect(() => {
    register('customer')
    register('customerBySecondName')
    register('accountTag')
    register('state')
    register('city')
    register('district')
    register('address')
    register('createdFrom')
    register('createdTo')
    register('active')
    register('serviceTypes')
    setValue('active', undefined)
  }, [register, setValue])

  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState({ data: [], totalElements: 0 })

  const fetchAccounts = useCallback(async () => {
    if (code) {
      setValue('code', code)

      setFilters({
        code,
        address,
        city,
        createdFrom,
        createdTo,
        customer,
        district,
        state,
        externalId,
        active: watch('active'),
        serviceTypes: watch('serviceTypes'),
        customerBySecondName,
        accountTag,
        contractNumber,
        centralCode,
        patrimony,
      })
    }
    setIsFetching(true)

    const page = Number(searchParams.get('page')) || 1
    const offset = (page - 1) * 15

    try {
      const response = await AccountDriver.queryAggregatedAccounts({
        offset,
        recordsPerPage: 15,
        ...(code && { code }),
        ...(customer && { customerId: customer.id }),
        ...(customerBySecondName && { customerId: customerBySecondName.id }),
        ...(createdFrom && { createdFrom }),
        ...(createdTo && { createdTo }),
        ...(district && { districtId: district.id }),
        ...(city && !district ? { cityId: city.id } : {}),
        ...(state && !city ? { stateId: state.id } : {}),
        ...(address && { address }),
        ...(externalId && { externalId }),
        ...(accountTag && { tagIds: accountTag.id }),
        ...(contractNumber && { contractNumber }),
        ...(centralCode && { centralCode: centralCode.replace(/:/g, ``) }),
        ...(patrimony && { patrimonyId: patrimony.id }),
        ...(!!watch('serviceTypes')?.length && {
          serviceTypeIds: watch('serviceTypes').map(
            (serviceType) => serviceType.id,
          ),
        }),
        active: watch('active'),
      })

      setData(response)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [
    code,
    setValue,
    setFilters,
    address,
    city,
    createdFrom,
    createdTo,
    customer,
    district,
    state,
    externalId,
    watch,
    customerBySecondName,
    accountTag,
    contractNumber,
    centralCode,
  ])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchAccounts()
    }
  }, [page, filter])

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'account',
        size: 100,
        header: 'Conta',
        cell: (info) => {
          const value = info.getValue()

          return `${value.code} ${
            value.externalId ? `(${value.externalId.split('-')[1] ?? ''})` : ''
          }${value.serviceType ? ` - ${value.serviceType.name}` : ''}`
        },
      }),
      columnHelper.accessor('customer', {
        header: 'Cliente',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('patrimony', {
        header: 'Patrimônio',
        cell: (info) => info.getValue()?.name,
      }),

      columnHelper.accessor('patrimony', {
        id: 'address',
        header: 'Endereço',
        cell: (info) => {
          const value = info.getValue()?.address

          return (
            value &&
            `${value.address}, ${value.number} - ${value.district}, ${value.city}`
          )
        },
      }),
      columnHelper.accessor('central', {
        size: 54,
        header: 'Status',
        cell: (info) => {
          const value = info.getValue()

          return (
            <div className={styles.iconsWrapper}>
              {getCentralBatteryIcon({
                isConnected: value?.connected || false,
                batteryLevel: value?.battery,
              })}
              {getCentralCommunicationIcon({
                isUbisafe: value?.isUbisafe || false,
                communicationType: value?.communicationType,
                isConnected: value?.connected || false,
              })}
              {getCentralStatusIcon(value)}
            </div>
          )
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 38,
        cell: (info) => (
          <Icon
            name="view"
            onClick={() => {
              onInspect(info.getValue())
            }}
            style={{ cursor: 'pointer' }}
          />
        ),
      }),
    ],
    [columnHelper],
  )
  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <>
      <FormProvider {...form}>
        <div
          className={[
            styles.container,
            filterComponent.isVisible && styles.noClickable,
          ].join(' ')}
        >
          <span className={styles.title}>Contas</span>
          <div className={styles.divider} />
          <form
            className={styles.inputsWrapper}
            onSubmit={handleSubmit(() => {
              handleResetPagination()
              fetchAccounts()
            })}
          >
            <div className={styles.innerWrapper}>
              <div className={styles.filtersButtons}>
                <Input
                  id="code"
                  placeholder="Digite o código da conta"
                  label="Conta"
                  autoComplete="off"
                  {...register('code')}
                  value={code?.toUpperCase() || ''}
                  onChange={(e) => {
                    const input = e.target.value || ''
                    if (validateHexdecimalCharacter(input) || input === '') {
                      setValue('code', input)
                    }
                  }}
                  disabled={filterComponent.isVisible}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  disabled={filterComponent.isVisible}
                  htmlType="submit"
                />
                <Button
                  buttonTitle="Nova conta"
                  type="secondary"
                  icon={PlusIcon}
                  onClick={() => navigate('/account/new')}
                />
                <Button
                  buttonTitle={
                    Object.values(watch())
                      .slice(2, 7)
                      .some((value) => value)
                      ? 'Editar filtros'
                      : 'Adicionar filtros'
                  }
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterComponent.show}
                />
              </div>
            </div>
            <Filters
              isVisible={filterComponent.isVisible}
              onClose={filterComponent.hide}
              onApplyFilters={handleSubmit(() => {
                if (code) {
                  form.setValue('code', '')
                }
                setFilters(watch())
                handleResetPagination()
                fetchAccounts()
                filterComponent.hide()
              })}
            />
          </form>
          {
            {
              view: (
                <Table.Root>
                  <Table.Header>
                    {table?.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Table.ResizableColumn
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.column.getSize() }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Table.ResizableColumn>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Header>
                  <Table.Body>
                    {table?.getRowModel().rows.map((row) => (
                      <Fragment key={row.id}>
                        <Table.Row active={row.original.active}>
                          {row.getVisibleCells().map((cell) => (
                            <Table.Cell
                              key={cell.id}
                              className={styles[cell.column.id]}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </Fragment>
                    ))}
                  </Table.Body>
                </Table.Root>
              ),
              empty: <EmptyState type="EmptyDataFromBFF" />,
              error: <EmptyState type="ErrorToLoadInfos" />,
              loading: (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ),
            }[handleRender(data?.data, isError, isFetching)]
          }
          <Pagination
            className={styles.pagination}
            totalElements={data?.totalElements}
          />
        </div>
      </FormProvider>
    </>
  )
}

export default Accounts
