import { useCallback, useEffect, useRef, useState } from 'react'
import { Column } from 'react-table'

import { useToggle } from 'shared/hooks'

import {
  SERVICE_ORDER_STATUS_ENUM,
  ServiceOrderFragment,
} from 'services/serviceOrder'
import { OccurrenceEvent } from 'services/event/types'
import EventDriver from 'services/event'

import { Loader, Table, TableRef } from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'

import {
  ALL_ORIGIN_NAMES,
  emptyStateProps,
} from 'domains/serviceOrders/data/constants'

import { getEventDescription, getEventDevice } from 'utilities/event'
import { maskedDateTime } from 'utilities/date'

import styles from './SearchEquipmentsEvent.module.scss'

interface SearchEquipmentsEventProps {
  serviceOrderInfo: ServiceOrderFragment
  accountId: string
  deviceId: string
}

const columns: Column<OccurrenceEvent>[] = [
  {
    id: 'event',
    Header: 'Evento',
    accessor: (row) =>
      getEventDescription(
        row.eventType.code,
        row.eventType.description,
        row.device?.deviceTypeCode,
        row.device?.sensorType,
      ),
  },
  {
    id: 'origin',
    Header: 'Origem',
    accessor: (row) => row.originName || '--',
  },
  {
    id: 'equipment',
    Header: 'Equipamento',
    accessor: (row) =>
      getEventDevice(row.device?.deviceTypeCode, row.command?.id),
  },
  {
    id: 'partition',
    Header: 'Partição',
    accessor: (row) => row?.partition?.code || '--',
  },
  {
    id: 'createdAt',
    Header: 'Data e Hora',
    accessor: (row) => maskedDateTime(row.datetime),
  },
]

export const SearchEquipmentsEvent = ({
  serviceOrderInfo,
  accountId,
  deviceId,
}: SearchEquipmentsEventProps): JSX.Element => {
  const loader = useToggle()

  const tableRef = useRef<TableRef>(null)
  const [originNames, setOriginNames] = useState([ALL_ORIGIN_NAMES])
  const [originEventSelected, setOriginEventSelected] = useState('')

  const fetchEvents = useCallback(async () => {
    loader.show()

    const inProgressDateTime = serviceOrderInfo.history.find(
      (historyItem) =>
        historyItem.status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
    )?.datetime
    const inValidationDateTime = serviceOrderInfo.history.find(
      (historyItem) =>
        historyItem.status === SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION,
    )?.datetime

    try {
      const events = await EventDriver.queryEvents({
        accountId,
        createdFrom: inProgressDateTime,
        createdTo: inValidationDateTime || Date.now(),
      })

      const centralOriginName = 'Central'

      const originNames = [
        ALL_ORIGIN_NAMES,
        ...events.data
          .map((item) => item.originName || centralOriginName)
          .filter((nome, index, array) => array.indexOf(nome) === index),
      ]

      setOriginNames(originNames)

      const eventsOfDeviceSelected = events.data.filter(
        (event) => !deviceId || event.device?.id === deviceId,
      )

      const result = {
        data: deviceId ? eventsOfDeviceSelected : events.data,
        totalElements: deviceId
          ? eventsOfDeviceSelected.length
          : events.totalElements,
      }

      const filteredByOriginEvents = result.data.filter((event) => {
        if (originEventSelected !== ALL_ORIGIN_NAMES) {
          return originEventSelected !== centralOriginName
            ? event.originName === originEventSelected
            : !event.originName
        }

        return true
      })

      const resultFilteredByOriginEvents = {
        data: filteredByOriginEvents,
        totalElements: filteredByOriginEvents.length,
      }

      return originEventSelected ? resultFilteredByOriginEvents : result
    } finally {
      loader.hide()
    }
  }, [
    accountId,
    loader,
    deviceId,
    originEventSelected,
    serviceOrderInfo.history,
  ])

  useEffect(() => tableRef.current?.handleFetchPage(), [originEventSelected])

  return (
    <>
      <Loader isVisible={loader.isVisible} />
      <div className={styles.container}>
        <div className={styles.innerWrapper}>
          <Combobox
            label={{
              text: 'Evento por origem',
            }}
            items={originNames}
            value={originEventSelected || originNames[0]}
            onChange={(selected) => {
              setOriginEventSelected(selected as string)
            }}
          />
        </div>
        <Table
          innerRef={tableRef}
          columns={columns}
          fetcher={fetchEvents}
          resetPagination
          emptyState={emptyStateProps}
        />
      </div>
    </>
  )
}
