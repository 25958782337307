import { useMutation } from '@tanstack/react-query'
import HttpClient from 'services/httpClient'

import { rewriteSentenceContext, RewriteSentenceContext } from './types'
import { endpoints } from './endpoints'

export const useRewriteSentence = (context: RewriteSentenceContext) =>
  useMutation({
    mutationFn: async (sentence: string) => {
      const response = await HttpClient.post(endpoints.rewriteSentence, {
        context: rewriteSentenceContext[context],
        sentence,
      })

      return response.data
    },
  })
