import { useMutation, useQueryClient } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { EnableCentralPayload } from './types'

const enableCentral = async (payload: EnableCentralPayload) => {
  const response = await HttpClient.post(endpoints.enableCentral, payload)

  return response.data
}

export const useEnableCentral = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: EnableCentralPayload) => enableCentral(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-central-refused'] })
      queryClient.invalidateQueries({ queryKey: ['queryCentralByAccounts'] })
    },
  })
}
