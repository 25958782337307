import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import {
  AggregatedUserServiceOrders,
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_TYPE_ENUM,
  SERVICE_ORDER_TYPE_EN_PT,
  useGetServiceOrderHistory,
  ServiceOrderDriver,
} from 'services/serviceOrder'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

import {
  LoaderV2,
  Input,
  Button,
  ServiceOrderFilters,
  Popover,
  PaginationV2 as Pagination,
  TableV2 as Table,
  EmptyState,
  Icon,
  Droplist,
  ScheduleServiceOrderModal,
} from 'components'

import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useToggle, useUserInfo } from 'shared/hooks'

import { UserServiceOrderList } from 'domains/serviceOrders/screens/list/InProgressServiceOrders/types'

import { usePersistentTab } from 'domains/occurrence/hooks'

import { CancelServiceOrder } from '../../../components/CancelServiceOrder/CancelServiceOrder'

import {
  filterSchema,
  ServiceOrderFiltersSchema,
} from 'components/ServiceOrderFilters/schemas/searchServiceOrderSchema'

import {
  useOSFilter,
  handleStatusLabel,
  isServiceOrderCancelable,
  isServiceOrderSchedulable,
  isServiceOrderVisible,
  isServiceOrderReschedulable,
} from 'domains/serviceOrders/utilities'

import { maskedDateTime, maskedDateTimeWithPeriod } from 'utilities/date'

import { RichTooltip } from '../../components'

import styles from './styles.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UbideskPermissions } from 'routes/types'
import { ServiceOrderPayload } from 'domains/serviceOrders/types'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TooltipInfo } from './components'

const emptyServiceOrder: AggregatedUserServiceOrders = {
  id: '',
  number: 0,
  account: { id: '', code: '' },
  customer: { id: '', name: '' },
  technician: {
    id: '',
    name: '',
    phone: 0,
    document: '',
    address: {
      address: '',
      adjunct: '',
      reference: '',
      number: '',
      postalCode: 0,
      districtName: '',
    },
  },
  contact: { id: '', name: '', countryCode: 0, provinceCode: 0, number: 0 },
  patrimony: { id: '', name: '' },
  status: SERVICE_ORDER_STATUS_ENUM.OPEN,
  scheduleDate: 0,
  schedulePeriod: 'AFTERNOON',
  type: SERVICE_ORDER_TYPE_ENUM.INSTALL,
  createdAt: 0,
  updatedAt: 0,
  labels: [],
}

const columnHelper = createColumnHelper<UserServiceOrderList>()

export const InProgressServiceOrders: FC = () => {
  const navigate = useNavigate()

  const [selectedServiceOrder, setSelectedServiceOrder] =
    useState<AggregatedUserServiceOrders>(emptyServiceOrder)

  const filterComponent = useToggle()
  const { userInfo } = useUserInfo()
  const cancelServiceOrderComponent = useToggle()
  const scheduleServiceOrderComponent = useToggle()
  const { replaceUrl } = usePersistentTab('view-service-order')
  const [searchParams, setSearchParams] = useSearchParams()

  const [serviceOrderId, setServiceOrderId] = useState('')
  const [serviceOrderSchedule, setServiceOrderSchedule] =
    useState<ServiceOrderPayload>()

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '1',
    })
  }

  const { filters, handleResetFilter } = useOSFilter()

  const form = useForm<ServiceOrderFiltersSchema>({
    resolver: joiResolver(filterSchema),
    defaultValues: { applyAttendanceExclusivity: true, ...filters },
  })

  const { reset, watch, getValues } = form

  const [readonlySchedule, setReadonlySchedule] = useState(false)

  const { number, applyAttendanceExclusivity } = watch()

  const { data: serviceOrderHistory } =
    useGetServiceOrderHistory(serviceOrderId)

  const hasInProgress = serviceOrderHistory?.some(
    (historyEntry) =>
      historyEntry.status === SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
  )

  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState({ data: [], totalElements: 0 })

  const userId = userInfo.id

  const fetchServiceOrders = useCallback(async () => {
    const number = getValues('number')
    const customer = watch('customer')
    const patrimony = watch('patrimony')
    const account = watch('account')
    const serviceOrderTypes = watch('type')
    const technicians = watch('technicians')
    const status = watch('status')
    const createdAtFrom = watch('createdAtFrom')
    const createdAtTo = watch('createdAtTo')
    const scheduleDateFrom = watch('scheduleDateFrom')
    const scheduleDateTo = watch('scheduleDateTo')
    const closedAtFrom = watch('closedAtFrom')
    const closedAtTo = watch('closedAtTo')
    const tag = watch('tag')
    const applyAttendanceExclusivity = watch('applyAttendanceExclusivity')

    if (number) {
      reset({
        applyAttendanceExclusivity: watch('applyAttendanceExclusivity'),
      })
    }

    const origin = watch('origin')
    const reason = watch('reason')
    const pauseReason = watch('pauseReason')
    const issue = watch('issue')
    const resolution = watch('resolution')

    const labels = [origin, reason, pauseReason, issue, resolution].reduce(
      (acc, array) => [...acc, ...(array || [])],
      [],
    )

    setIsFetching(true)

    try {
      const serviceOrders =
        await ServiceOrderDriver.getInProgressServiceOrdersPost({
          userId,
          status: [
            SERVICE_ORDER_STATUS_ENUM.OPEN,
            SERVICE_ORDER_STATUS_ENUM.IN_PROGRESS,
            SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION,
            SERVICE_ORDER_STATUS_ENUM.REPROVED,
            SERVICE_ORDER_STATUS_ENUM.PAUSED,
            SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
            SERVICE_ORDER_STATUS_ENUM.RESCHEDULED,
          ],
          applyAttendanceExclusivity,
          coverageTypes: ['SERVICE_ORDER_ATTENDANT'],
          ...(number && { number }),
          ...(account && { accountIds: [account.id] }),
          ...(technicians && {
            technicianIds: technicians.map((technician) => technician.id),
          }),
          ...(createdAtFrom && { createdAtFrom }),
          ...(createdAtTo && { createdAtTo }),
          ...(scheduleDateFrom && { scheduleDateFrom }),
          ...(scheduleDateTo && {
            scheduleDateTo: new Date(
              new Date(scheduleDateTo).setHours(23),
            ).setMinutes(59),
          }),
          hasTechnician: watch('hasTechnician'),
          ...(!!watch('serviceType') && {
            serviceTypeId: watch('serviceType').id,
          }),
          ...(!!watch('accountTag') && {
            accountTagId: watch('accountTag').id,
          }),
          ...(closedAtFrom && { closedAtFrom }),
          ...(closedAtTo && { closedAtTo }),
          ...(labels?.length && { labelIds: labels.map((label) => label.id) }),
          ...(tag && { tagId: tag.id }),
          ...(serviceOrderTypes && { serviceOrderTypes }),
          ...(patrimony && !account
            ? { accountIds: [patrimony.accountId] }
            : {}),
          ...(customer && !account && !patrimony
            ? {
                accountIds: customer.accounts.length
                  ? customer.accounts.map((account) => account.id)
                  : [customer.id],
              }
            : {}),
          ...(!!status?.length && { status }),
        })

      const formatted = {
        data: serviceOrders?.data
          .sort((a, b) => {
            const techNameA = a.technician?.name || ''
            const techNameB = b.technician?.name || ''

            return (
              (a.technician ? 0 : -1) - (b.technician ? 0 : -1) ||
              techNameA.localeCompare(techNameB)
            )
          })
          .map((so) => ({
            ...so,
            technicianName: so.technician?.name || 'SEM TÉCNICO DEFINIDO',
          })),
        totalElements: serviceOrders?.totalElements,
      }

      setData(formatted)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [getValues, reset, watch])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchServiceOrders()
    }
  }, [page, filter])

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        Icon: 'view',
        handler: (serviceOrder) => {
          setServiceOrderId(serviceOrder.id)
          replaceUrl(`/so/${serviceOrder.id}`)
        },
      },
      {
        label: 'Editar',
        Icon: 'edit',
        handler: (item) => {
          replaceUrl(`/so/update/${item.id}`)
        },
        isVisible: (item) => isServiceOrderVisible(item.status),
      },
      {
        label: 'Cancelar',
        Icon: 'close-xlg',
        handler: (item) => {
          setSelectedServiceOrder(item)
          cancelServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderCancelable(item.status),
        permission: {
          name: hasInProgress
            ? UbideskPermissions.CANCEL_EXECUTED_SO
            : undefined,
          message: 'Não é possível cancelar esta OS, pois já possui execuções.',
        },
      },
      {
        label: 'Agendar',
        Icon: 'calendar',
        handler: (serviceOrder) => {
          setServiceOrderSchedule({ ...serviceOrder, note: '' })
          scheduleServiceOrderComponent.show()
        },
        isVisible: (item) =>
          isServiceOrderSchedulable(item.status) &&
          item.status !== SERVICE_ORDER_STATUS_ENUM.SCHEDULED,
      },
      {
        label: 'Reagendar',
        Icon: 'calendar',
        handler: (serviceOrder) => {
          setServiceOrderSchedule({ ...serviceOrder, note: '' })
          scheduleServiceOrderComponent.show()
        },
        isVisible: (item) => isServiceOrderReschedulable(item.status),
      },
      {
        label: 'Validar',
        Icon: 'success',
        isVisible: (item) =>
          item.status === SERVICE_ORDER_STATUS_ENUM.IN_VALIDATION,
        handler: (serviceOrder) => {
          navigate(`/so/${serviceOrder.id}`)
        },
      },
    ],
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'id',
        header: 'Número',
        maxSize: 100,
        cell: (info) => {
          const { id, number, status, patrimony } = info.getValue()

          return (
            <div className={styles.rowContainer}>
              <div className={styles.statusBox}>
                <span>{number}</span>
                <Popover.Root>
                  <Popover.Content>
                    <RichTooltip serviceOrderId={id} />
                  </Popover.Content>
                  <Popover.Trigger className={styles[status]}>
                    {handleStatusLabel(status)}
                    <InfoIcon />
                  </Popover.Trigger>
                </Popover.Root>
              </div>

              <small>
                {`${patrimony?.address?.district}, ${patrimony?.address?.city}, ${patrimony?.address?.acronym}`}
              </small>
            </div>
          )
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'account',
        header: 'Conta',
        cell: (info) =>
          `${info.getValue().account?.code} - ${info.getValue().customer?.name}`,
      }),
      columnHelper.accessor('labels', {
        header: 'Origem / Motivo',
        cell: (info) => {
          const value = info.getValue()

          const reasonLabel = value?.find((label) => label.type === 'REASON')
          const originLabel = value?.find((label) => label.type === 'ORIGIN')

          return (
            <div className={styles.rowContainer}>
              {originLabel?.description || ''}

              <div>{reasonLabel?.description || ''}</div>
            </div>
          )
        },
      }),
      columnHelper.accessor('type', {
        header: 'Tipo',
        cell: (info) => (
          <>
            {
              SERVICE_ORDER_TYPE_EN_PT[
                info.getValue() as SERVICE_ORDER_TYPE_ENUM
              ]
            }
          </>
        ),
      }),
      columnHelper.accessor('createdAt', {
        size: 72,
        header: 'Abertura',
        cell: (info) => maskedDateTime(info.getValue()),
      }),
      columnHelper.accessor((row) => row, {
        size: 104,
        header: 'Agendamento',
        cell: (info) => {
          const { scheduleDate, schedulePeriod } = info.getValue()

          if (scheduleDate) {
            const formattedDateTime = maskedDateTimeWithPeriod(
              scheduleDate,
              schedulePeriod,
            )
            return formattedDateTime
          }
          return ''
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 16,
        cell: (info) => {
          const showDropList = actions.some((action) =>
            action.isVisible?.(info.getValue()),
          )

          const value = info.getValue()
          return showDropList ? (
            <Droplist
              trigger={<Icon name="menu-kebab" style={{ cursor: 'pointer' }} />}
            >
              <ul className={styles.dropListItem}>
                {actions.map((action) => {
                  const shouldShow = action.isVisible?.(value) ?? true

                  return (
                    shouldShow && (
                      <li
                        key={action.label}
                        onClick={() => action.handler(value)}
                      >
                        <Icon
                          name={action.Icon}
                          style={{ cursor: 'pointer' }}
                        />
                        {action.label}
                      </li>
                    )
                  )
                })}
              </ul>
            </Droplist>
          ) : (
            <Droplist
              trigger={<Icon name="menu-kebab" style={{ cursor: 'pointer' }} />}
            >
              <ul className={styles.dropListItem}>
                {actions.slice(0, 2).map((action) => {
                  return (
                    <li
                      key={action.label}
                      onClick={() => action.handler(value)}
                    >
                      <Icon name={action.Icon} style={{ cursor: 'pointer' }} />
                      {action.label}
                    </li>
                  )
                })}
              </ul>
            </Droplist>
          )
        },
      }),
    ],
    [columnHelper, actions],
  )

  const table = useReactTable({
    data: data?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <>
      {scheduleServiceOrderComponent.isVisible && (
        <ScheduleServiceOrderModal
          readonly={readonlySchedule}
          isVisible={scheduleServiceOrderComponent.isVisible}
          onClose={() => {
            form.reset()
            setSelectedServiceOrder(emptyServiceOrder)
            scheduleServiceOrderComponent.hide()
            setReadonlySchedule(false)
          }}
          serviceOrder={serviceOrderSchedule}
        />
      )}
      <FormProvider {...form}>
        <CancelServiceOrder
          isVisible={cancelServiceOrderComponent.isVisible}
          onClose={() => {
            setSelectedServiceOrder(emptyServiceOrder)
            cancelServiceOrderComponent.hide()
          }}
          serviceOrder={{
            id: selectedServiceOrder?.id || '',
            status: selectedServiceOrder?.status,
            number: selectedServiceOrder?.number || 0,
            account: selectedServiceOrder?.account,
            inProgressAt: selectedServiceOrder?.inProgressAt,
            technicianId: selectedServiceOrder?.technician?.id,
          }}
        />
        <div
          className={[
            styles.container,
            filterComponent.isVisible && styles.noClickable,
          ].join(' ')}
        >
          <span className={styles.title}>Ordens de serviço</span>
          <div className={styles.divider} />
          <form
            className={styles.inputsWrapper}
            onSubmit={(event) => {
              event.preventDefault()
              handleResetPagination()

              handleResetFilter()
              reset({
                number,
                applyAttendanceExclusivity,
              })
              fetchServiceOrders()
            }}
          >
            <div className={styles.innerWrapper}>
              <div className={styles.filtersButtons}>
                <Input
                  id="number"
                  placeholder="Digite o número identificador da OS"
                  label="Número da OS"
                  autoComplete="off"
                  {...form.register('number')}
                  type="number"
                  disabled={filterComponent.isVisible}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  disabled={filterComponent.isVisible}
                  htmlType="submit"
                />
                <Button
                  buttonTitle={'Adicionar filtros'}
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterComponent.show}
                />
              </div>
            </div>
          </form>
          {
            {
              view: (
                <Table.Root>
                  <Table.Header>
                    {table?.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Table.ResizableColumn
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.column.getSize() }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Table.ResizableColumn>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Header>
                  <Table.Body>
                    {table?.getRowModel().rows.map((row, index) => {
                      const previousRow = index
                        ? table?.getRowModel().rows[index - 1]
                        : undefined

                      const value = row.original
                      const currentGroup = value.technicianName
                      const previousGroup = previousRow?.original.technicianName

                      const shouldRenderSection = currentGroup !== previousGroup

                      return (
                        <Fragment key={row.id}>
                          {shouldRenderSection && (
                            <Table.Row>
                              <Table.Cell
                                style={{ padding: 0 }}
                                colSpan={row.getVisibleCells().length}
                              >
                                <div className={styles.headerContainer}>
                                  <span>
                                    {value.technicianName.toUpperCase()}
                                  </span>

                                  {!!value.technician && (
                                    <TooltipInfo
                                      technician={value.technician}
                                      tags={value.technician.tags}
                                      onInspectTechnician={() => {
                                        setReadonlySchedule(true)

                                        setServiceOrderSchedule(value)

                                        scheduleServiceOrderComponent.show()
                                      }}
                                    />
                                  )}
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          )}

                          <Table.Row>
                            {row.getVisibleCells().map((cell) => (
                              <Table.Cell
                                key={cell.id}
                                className={styles[cell.column.id]}
                                style={{ width: cell.column.getSize() }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        </Fragment>
                      )
                    })}
                  </Table.Body>
                </Table.Root>
              ),
              empty: <EmptyState type="EmptyDataFromBFF" />,
              error: <EmptyState type="ErrorToLoadInfos" />,
              loading: (
                <div className={styles.loader}>
                  <LoaderV2 />
                </div>
              ),
            }[handleRender(data?.data, isError, isFetching)]
          }
          <Pagination
            className={styles.pagination}
            recordsPerPage={data?.totalElements}
            totalElements={data?.totalElements}
          />

          <ServiceOrderFilters
            status={[
              'Aberta',
              'Agendada',
              'Em andamento',
              'Pausada',
              'Em validação',
              'Reprovada',
              'Reagendada',
            ]}
            hiddenFilters={['finishedAt']}
            isVisible={filterComponent.isVisible}
            onClose={filterComponent.hide}
            onApplyFilters={() => {
              const { number } = watch()

              if (number) {
                form.setValue('number', undefined)
              }

              filterComponent.hide()
              handleResetPagination()
            }}
          />
        </div>
      </FormProvider>
    </>
  )
}
