import { ButtonV2, FormItem, FormLabel, ModalV2, TextField } from 'components'

import styles from './styles.module.scss'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { PartitionFragmentPayload } from 'services/central/types'
import { partitionSchema } from 'domains/customer/schemas'

interface AddPartitionModalProps {
  onSave: (partition: PartitionFragmentPayload) => void
  partition?: PartitionFragmentPayload
  isUbisafe?: boolean
  isVisible: boolean
  onClose: () => void
}

const AddPartitionModal = ({
  partition,
  onSave,
  isUbisafe,
  isVisible,
  onClose,
}: AddPartitionModalProps) => {
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: joiResolver(partitionSchema),
    defaultValues: {
      id: partition?.id,
      code: partition?.code,
      name: partition?.name,
      keyNumber: partition?.keyNumber,
      status: partition?.status || 'D',
      externalId: partition?.externalId,
    },
  })

  return (
    <ModalV2.Root isOpen={isVisible} onClose={onClose}>
      <ModalV2.Content size="sm">
        <ModalV2.Title className={styles.title}>
          {partition ? 'Editar partição' : 'Nova partição'}
        </ModalV2.Title>
        <form className={styles.container} onSubmit={handleSubmit(onSave)}>
          <main>
            <FormItem>
              <FormLabel>Código</FormLabel>
              <TextField
                {...register('code', { required: true })}
                disabled={isUbisafe}
              />
            </FormItem>

            <FormItem>
              <FormLabel>Nome</FormLabel>
              <TextField {...register('name', { required: true })} />
            </FormItem>

            <FormItem>
              <FormLabel>Chave (opcional)</FormLabel>
              <TextField {...register('keyNumber')} />
            </FormItem>

            <FormItem>
              <FormLabel>ID externo (opcional)</FormLabel>
              <TextField {...register('externalId')} />
            </FormItem>
          </main>
          <ModalV2.Footer>
            <ModalV2.Close asChild>
              <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
            </ModalV2.Close>
            <ButtonV2 type="submit">
              {partition ? 'Confirmar' : 'Adicionar'}
            </ButtonV2>
          </ModalV2.Footer>
        </form>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default AddPartitionModal
