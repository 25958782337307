import HttpClient from 'services/httpClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { endpoints } from './endpoints'
import { AxiosResponse } from 'axios'
import { AccountPayload } from '../types'

const updateAccount = async (payload: AccountPayload): Promise<AxiosResponse> =>
  await HttpClient.put(endpoints.updateAccount, {
    ...payload,
    tags: payload.tags?.map((tag) => ({
      name: tag.name,
      type: tag.type,
    })),
    attendanceProfiles: undefined,
    active: payload.active === 'ACTIVE',
    paymentMethod: undefined,
    partitions: payload.partitions?.filter(
      (partition) => partition.securityAnswer,
    ),
  })

export const usePutAccount = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: AccountPayload) => {
      await updateAccount(payload)
    },
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries({
        queryKey: ['get-patrimony', payload.patrimonyId],
      })
      queryClient.invalidateQueries({ queryKey: ['accounts'] })
    },
  })
}
