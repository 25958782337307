import { Skeleton } from 'components'
import styles from './styles.module.scss'

const Loading = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.leftSection}>
        <div className={styles.header}>
          <Skeleton height="40px" />
          <Skeleton height="40px" />
        </div>
        <div className={styles.form}>
          <Skeleton width="200px" height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="100px" />
          <Skeleton height="40px" />
        </div>
      </div>
      <div className={styles.rightSection}>
        <Skeleton width="430px" height="600px" />
      </div>
    </div>
    <div className={styles.footer}>
      <Skeleton width="200px" height="40px" />
      <Skeleton width="200px" height="40px" />
    </div>
  </div>
)

export default Loading
