import { SchedulePeriod } from 'services/serviceOrder'

export const formatDecimal = (value: number): string => {
  return value.toString().padStart(2, '0')
}

export const dateNow = (timestamp?: number): string => {
  const date = timestamp ? new Date(timestamp) : new Date()
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const hour = date.getHours()
  const minutes = date.getMinutes()
  return `${formatDecimal(day)}/${formatDecimal(
    month + 1,
  )}/${year} às ${formatDecimal(hour)}:${formatDecimal(minutes)}`
}

export const dateNowWithSeconds = (timestamp?: number): string => {
  const date = timestamp ? new Date(timestamp) : new Date()
  const day = formatDecimal(date.getDate())
  const month = formatDecimal(date.getMonth() + 1)
  const year = date.getFullYear()
  const hour = formatDecimal(date.getHours())
  const minutes = formatDecimal(date.getMinutes())
  const seconds = formatDecimal(date.getSeconds())
  return `${day}/${month}/${year} às ${hour}:${minutes}:${seconds}`
}

export const maskedDate = (datetime?: number): string => {
  let formattedDate = '00/00/0000'
  if (datetime) {
    const date = new Date(datetime)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    formattedDate = `${formatDecimal(day)}/${formatDecimal(month)}/${year}`
  }

  return formattedDate
}

export const maskedDateTime = (datetime?: number): string => {
  let formattedDate = '00/00/0000 às 00:00'
  if (datetime) {
    const date = new Date(datetime)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const hour = date.getHours()
    const minutes = date.getMinutes()

    formattedDate = `${formatDecimal(day)}/${formatDecimal(
      month,
    )}/${year} às ${formatDecimal(hour)}:${formatDecimal(minutes)}`
  }

  return formattedDate
}

export const maskedDateTimeWithPeriod = (
  datetime: number,
  period: SchedulePeriod,
): string => {
  const periodMap = {
    BUSINESS_HOURS: 'Horário comercial',
    AFTERNOON: 'Tarde',
    MORNING: 'Manhã',
  }

  const periodText = periodMap[period]
  return periodText
    ? `${maskedDate(datetime)} ${periodText}`
    : maskedDateTime(datetime)
}

export const maskedDateTimeHour = (dateTime: number): string => {
  const date = new Date(dateTime)
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

export const convertDate = (value: Date): string => {
  const day = formatDecimal(value.getDate())
  const month = formatDecimal(value.getMonth() + 1)
  const year = value.getFullYear()
  const hours = formatDecimal(value.getHours())
  const minutes = formatDecimal(value.getMinutes())
  const seconds = formatDecimal(value.getSeconds())
  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

export const maskedInputDate = (value: string): string => {
  const newValue = value.replace(/\D/g, '')
  if (value.length > 2 && value.length <= 5) {
    return newValue.replace(/(\d{2})(\d{1,})/g, '$1/$2')
  } else if (value.length > 5) {
    return newValue.replace(/(\d{2})(\d{2})(\d{1,})/g, '$1/$2/$3')
  }
  return newValue
}

export const dateToMilliseconds = (dateString: string): number | undefined => {
  const regex = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/
  const match = dateString.match(regex)

  if (match) {
    const [, year, month, day, hours, minutes, seconds] = match
    const date = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hours),
      Number(minutes),
      Number(seconds),
    )
    const milliseconds = date.getTime()

    return milliseconds
  }

  return undefined
}

const monthNames = [
  'jan',
  'fev',
  'mar',
  'abril',
  'maio',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
]

export const formatDateWithMonth = (timestamp?: number): string => {
  const date = timestamp ? new Date(timestamp) : new Date()
  const day = formatDecimal(date.getDate())
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  const hour = formatDecimal(date.getHours())
  const minutes = formatDecimal(date.getMinutes())
  return `${day} ${month} ${year} às ${hour}:${minutes}`
}

export const formatFullDateWithMonth = (timestamp?: number): string => {
  const date = timestamp ? new Date(timestamp) : new Date()
  const day = formatDecimal(date.getDate())
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  const hour = formatDecimal(date.getHours())
  const minutes = formatDecimal(date.getMinutes())
  const seconds = formatDecimal(date.getSeconds())
  return `${day} ${month} ${year} às ${hour}:${minutes}:${seconds}`
}

export const convertSecondsToMinutes = (time: number): number => time * 60
