import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import HttpClient from '../httpClient'
import { Result } from 'services/types'
import {
  CentralAggregatedResponse,
  CentralFindByIdResult,
  CentralResponse,
  CreateCentralPayload,
} from './types'
import { endpoints } from './endpoints'
import buildUrl from 'utilities/buildUrl'

const queryCentralByAccounts = async (accountIds?: string[]) => {
  if (!accountIds) return
  const response = await HttpClient.get<Result<CentralAggregatedResponse>>(
    '/central/queryByAccounts',
    {
      params: {
        accountIds,
      },
    },
  )
  return response.data
}

export function useQueryCentralByAccounts(
  accountIds?: string[],
): UseQueryResult<Result<CentralAggregatedResponse>> {
  return useQuery({
    queryKey: ['queryCentralByAccounts', accountIds],
    queryFn: async () => await queryCentralByAccounts(accountIds),
    enabled: !!accountIds?.length,
  })
}

const getCentral = async (centralId?: string) => {
  if (!centralId) return
  const response = await HttpClient.get<CentralResponse>(
    `/central/${centralId}`,
  )
  return response.data
}

export function useGetCentral(
  centralId: string,
): UseQueryResult<CentralFindByIdResult> {
  return useQuery({
    queryKey: ['central', centralId],
    queryFn: () => getCentral(centralId),
    enabled: !!centralId,
  })
}

type CentralCreateResponse = {
  central: {
    id: string
  }
}

export const usePostCentral = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: CreateCentralPayload) => {
      const response = await HttpClient.post<CentralCreateResponse>(
        '/central',
        payload,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'queryCentralByAccounts'
        },
      })
    },
  })
}

export const useActivateCentral = (centralId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const response = await HttpClient.post(
        buildUrl({
          route: endpoints.activate,
          params: { id: centralId },
        }),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'queryCentralByAccounts'
        },
      })
      queryClient.invalidateQueries({ queryKey: ['central', centralId] })
    },
  })
}

export const useDeactivateCentral = (centralId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (centralId: string) => {
      const response = await HttpClient.post(
        buildUrl({
          route: endpoints.deactivate,
          params: { id: centralId },
        }),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'queryCentralByAccounts'
        },
      })
      queryClient.invalidateQueries({ queryKey: ['central', centralId] })
    },
  })
}

export const usePatchCentral = (centralId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: CreateCentralPayload) => {
      const response = await HttpClient.patch(`/central/${centralId}`, payload)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'queryCentralByAccounts'
        },
      })
      queryClient.invalidateQueries({ queryKey: ['central', centralId] })
    },
  })
}
