import { useQuery } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'
import HttpClient from 'services/httpClient'

import { endpoints } from './endpoints'
import { ServiceOrderDetails } from './type'

export const serviceOrderFormReport = async ({
  serviceOrderId,
  userId,
}: ServiceOrderDetails) => {
  const response = await HttpClient.get(
    buildUrl({
      route: endpoints.form,
      queryParams: { serviceOrderId, userId },
    }),
    { responseType: 'blob' },
  )

  return response.data
}

export const useServiceOrderFormReport = ({
  serviceOrderId,
  userId,
}: ServiceOrderDetails) => {
  return useQuery({
    queryKey: ['report-service-order-form', serviceOrderId, userId],
    queryFn: async () => {
      await serviceOrderFormReport({ serviceOrderId, userId })
    },
    enabled: !!serviceOrderId,
  })
}
