import buildUrl from 'utilities/buildUrl'
import HttpClient from 'services/httpClient'

import { endpoints } from './endpoints'
import { AccountEventsQuery } from './types'

export const accountEventsReport = async (
  query: AccountEventsQuery,
): Promise<Blob | MediaSource> => {
  const response = await HttpClient.get(
    buildUrl({
      route: endpoints.events,
      queryParams: query,
    }),
    { responseType: 'blob' },
  )

  return response.data
}
