import {
  useRef,
  useState,
  useCallback,
  ComponentProps,
  MouseEvent as ReactMouseEvent,
} from 'react'

import { Tooltip } from 'components'

import styles from './styles.module.scss'
import joinClassNames from 'utilities/joinClassNames'

const Root = ({ className, ...props }: ComponentProps<'table'>) => (
  <div className={styles.container}>
    <table {...props} className={[styles.root, className].join(' ')} />
  </div>
)

const Header = (props: ComponentProps<'thead'>) => <thead {...props} />

const Column = (props: ComponentProps<'th'>) => <th {...props} />

const Body = (props: ComponentProps<'tbody'>) => <tbody {...props} />

interface RowProps extends ComponentProps<'tr'> {
  active?: boolean
}

const Row = ({ active = true, className, ...props }: RowProps) => (
  <tr
    {...props}
    className={[!active && styles.inactive, className].join(' ')}
  />
)

const Cell = ({ children, ...props }: ComponentProps<'td'>) => {
  const ref = useRef<HTMLTableCellElement>(null)
  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const handleMouseEnter = useCallback(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setTooltipVisible(true)
    }
  }, [])

  const handleMouseLeave = useCallback(() => {
    setTooltipVisible(false)
  }, [])

  return (
    <td
      ref={ref}
      {...props}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}

      {isTooltipVisible && (
        <Tooltip
          parentRef={ref}
          type="informative"
          isVisible={isTooltipVisible}
        >
          {children}
        </Tooltip>
      )}
    </td>
  )
}

interface ActionProps extends ComponentProps<'td'> {
  isHidden?: boolean
}

const Action = ({
  children,
  className,
  isHidden = true,
  ...props
}: ActionProps) => {
  return (
    <td
      {...props}
      className={joinClassNames(
        className,
        styles.action,
        isHidden && styles.isHidden,
      )}
    >
      {children}
    </td>
  )
}

const ResizableColumn = ({
  children,
  className,
  ...props
}: ComponentProps<'th'>) => {
  const ref = useRef<HTMLTableCellElement>(null)
  const [initialWidth, setInitialWidth] = useState(0)

  const onMouseDown = (event: ReactMouseEvent<HTMLTableCellElement>) => {
    const startX = event.pageX

    const startWidth = ref.current?.style.width
      ? Number(ref.current?.style.width.replace('px', ''))
      : ref.current?.offsetWidth || 0

    !initialWidth && setInitialWidth(startWidth)

    const handleMouseMove = (event: MouseEvent) => {
      if (ref.current) {
        ref.current.style.width = startWidth + (event.pageX - startX) + 'px'
      }
    }

    document.addEventListener('mousemove', handleMouseMove)

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleDoubleClick = () => {
    if (ref.current) {
      ref.current.style.width = initialWidth + 'px'
    }
  }

  return (
    <th
      ref={ref}
      {...props}
      className={[styles.resizable, className].join(' ')}
    >
      {children}
      <div
        className={styles.resizer}
        onMouseDown={onMouseDown}
        onDoubleClick={handleDoubleClick}
      />
    </th>
  )
}

const TableComponent = {
  Root,
  Header,
  Column,
  Body,
  Row,
  Cell,
  Action,
  ResizableColumn,
}

export default TableComponent
