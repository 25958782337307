import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { HolidayDriver } from 'services/holiday/holiday.driver'
import { HolidayPayload } from 'services/holiday/types'

export type UpdateHolidayRequest = {
  holidayId: string
  payload: HolidayPayload
}

function updateHoliday(request: UpdateHolidayRequest): Promise<void> {
  return HolidayDriver.update(request.holidayId, request.payload)
}

export const usePutHoliday = (
  holidayId: string,
): UseMutationResult<void, unknown, HolidayPayload, unknown> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (request: HolidayPayload) => {
      await updateHoliday({ payload: request, holidayId })
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['holiday', holidayId] }),
  })
}
