import {
  ButtonV2,
  Datepicker,
  FormItem,
  FormLabel,
  FormMessage,
  ModalV2,
  TextareaV2,
  Toggle,
} from 'components'

import styles from './styles.module.scss'
import { ChangeEvent, useCallback } from 'react'
import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { useForm } from 'react-hook-form'
import {
  PeriodicEventControl,
  PeriodicEventControlDeactivateRequest,
} from 'services/central/types'
import { useDeactivatePeriodicEventControl } from 'services/periodicEventControl/useDeactivatePeriodicEventControl'
import { useToast, useUserInfo } from 'shared/hooks'
import { schema } from './schema'
import { joiResolver } from '@hookform/resolvers/joi'
import { Form } from 'services/periodicEventControl/types'

interface DisableMonitoringParametersModalProps {
  event?: PeriodicEventControl
  centralId: string
  isVisible: boolean
  onClose: () => void
}

const DisableMonitoringParametersModal = ({
  event,
  centralId,
  isVisible,
  onClose,
}: DisableMonitoringParametersModalProps) => {
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()
  const userId = userInfo.id

  const { register, handleSubmit, setValue, watch } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schema),
    defaultValues: {
      expirationDate:
        !!(!event?.active && event?.reactivationDateTime) || false,
      disableReason: (!event?.active && event?.disableReason) || '',
      reactivationDateTime:
        (!event?.active && event?.reactivationDateTime) || undefined,
    },
  })

  const handleChangeScheduleDateInput = useCallback(
    (formKey: string, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)
        setValue(formKey, date.getTime())
        return value
      } else {
        setValue(formKey, undefined)
        return value
      }
    },
    [setValue],
  )

  const title =
    event?.type === 'KEEPALIVE'
      ? 'Desabilitação de "Keep-Alive"'
      : 'Desabilitação de testes automáticos'

  const { mutate: deactivatePeriodicEventControl } =
    useDeactivatePeriodicEventControl(centralId, event?.id ?? '')

  const submit = (form: Form) => {
    const payload: PeriodicEventControlDeactivateRequest = {
      disabledByUser: userId,
      disableReason: form.disableReason,
      reactivationDateTime: form.reactivationDateTime,
    }
    deactivatePeriodicEventControl(payload, {
      onSuccess: () => {
        addToast({
          message: `Central editada com sucesso`,
          type: 'success',
        })
        onClose()
      },
      onError: () => {
        addToast({
          message: `Erro ao editar. Tente novamente.`,
          type: 'alert',
        })
      },
    })
  }

  return (
    <ModalV2.Root isOpen={isVisible} onClose={onClose}>
      <ModalV2.Content size="md">
        <ModalV2.Title className={styles.title}>{title}</ModalV2.Title>
        <form className={styles.container} onSubmit={handleSubmit(submit)}>
          <FormItem>
            <FormLabel>Justificativa</FormLabel>
            <TextareaV2
              disabled={!!event && !event.active}
              rows={5}
              {...register('disableReason')}
              maxLength={5000}
            />
            <FormMessage>
              {5000 - (watch('disableReason')?.length || 0)} caracteres
              restantes
            </FormMessage>
          </FormItem>
          <section>
            <FormItem>
              <div>
                <Toggle
                  disabled={!!event && !event.active}
                  onChange={(event) => {
                    const checked = event.target.checked
                    setValue('expirationDate', checked)

                    if (!checked) {
                      setValue('reactivationDateTime', undefined)
                    }
                  }}
                  checked={watch('expirationDate')}
                />
                <FormLabel>Com data de validade</FormLabel>
              </div>
            </FormItem>
            <div>
              <FormItem>
                <div>
                  <FormLabel htmlFor="reactivationDateTime">até</FormLabel>
                  <Datepicker
                    disabled={!watch('expirationDate') || !event?.active}
                    id="reactivationDateTime"
                    initialValueInput={
                      watch('reactivationDateTime')
                        ? convertDateToString(
                            new Date(watch('reactivationDateTime')),
                          )
                        : undefined
                    }
                    onChangeInput={(event) =>
                      handleChangeScheduleDateInput(
                        'reactivationDateTime',
                        event,
                      )
                    }
                    initialDate={new Date()}
                    selectEnd
                    onChangeDate={(date) => {
                      if (!date) {
                        setValue('reactivationDateTime', undefined)
                        return
                      }
                      const now = new Date()
                      const sameDay =
                        date.getDate() === now.getDate() &&
                        date.getMonth() === now.getMonth() &&
                        date.getFullYear() === now.getFullYear()

                      if (sameDay) {
                        date.setHours(23, 59, 59, 999)
                      }
                      setValue('reactivationDateTime', date.getTime())
                    }}
                  />
                </div>
              </FormItem>
            </div>
          </section>
          <ModalV2.Footer>
            <ModalV2.Close asChild>
              <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
            </ModalV2.Close>
            <ButtonV2
              type="submit"
              color="red"
              disabled={!!event && !event.active}
            >
              Desabilitar
            </ButtonV2>
          </ModalV2.Footer>
        </form>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default DisableMonitoringParametersModal
