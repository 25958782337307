import { Breadcrumbs, ContainerScreen, Loader } from 'components'

import { useNavigate } from 'react-router-dom'
import { useToast } from 'shared/hooks'
import { CreateCentralPayload } from 'services/central/types'
import { usePostCentral } from 'services/central'
import ImageDriver from 'services/image'
import { CentralForm } from 'domains/customer/components/CentralForm'
import { Form } from 'domains/customer/components/CentralForm/types'
import { convertSecondsToMinutes } from 'utilities/date'

export const CreateCentral = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()

  const { mutate: mutatePostCentral, status: statusPostCentral } =
    usePostCentral()

  const customerName = localStorage.getItem('customerName') || ''
  const customerId = localStorage.getItem('customerId') || ''
  const patrimonyId = localStorage.getItem('patrimonyId') || ''

  const submit = (data: Form) => {
    const payload: CreateCentralPayload = {
      accountId: data.account.id,
      partitions: data.partitions,
      central: {
        active: data.central.active,
        modelId: data.central?.model?.id,
        versionCode: data.central?.versionCode,
        topicGroup: data.central?.topicGroup,
        code: data.central.code?.replace(/:/g, ''),
        externalId: data.central?.externalId,
        commandPassword: data.central?.commandPassword,
        modelDescription: data.central?.modelDescription,
        gprsModuleModelId: data.central?.gprsModuleModel?.id,
      },
      monitoring: {
        ...(data?.monitoring?.autoTest?.active && {
          autoTest: {
            interval:
              data.monitoring.autoTest.interval?.type === 'minutes'
                ? data.monitoring.autoTest.interval?.time
                : convertSecondsToMinutes(
                    data.monitoring.autoTest.interval?.time ?? 0,
                  ),
            tolerance:
              data.monitoring.autoTest.tolerance?.type === 'minutes'
                ? data.monitoring.autoTest.tolerance?.time
                : convertSecondsToMinutes(
                    data.monitoring.autoTest.tolerance?.time ?? 0,
                  ),
            failEventRecurrenceInterval: 0,
            type: data.monitoring.autoTest.type,
          },
        }),
        ...(data?.monitoring?.keepAlive?.active && {
          keepAlive: {
            interval:
              data.monitoring.keepAlive.interval?.type === 'minutes'
                ? data.monitoring.keepAlive.interval?.time
                : convertSecondsToMinutes(
                    data.monitoring.keepAlive.interval?.time ?? 0,
                  ),
            failEventRecurrenceInterval:
              data.monitoring.keepAlive.failEventRecurrenceInterval?.type ===
              'minutes'
                ? data.monitoring.keepAlive.failEventRecurrenceInterval?.time
                : convertSecondsToMinutes(
                    data.monitoring.keepAlive.failEventRecurrenceInterval
                      ?.time ?? 0,
                  ),
            tolerance: 0,
            type: data.monitoring.keepAlive.type,
          },
        }),
      },
    }
    mutatePostCentral(payload, {
      onSuccess: async (response) => {
        if (data.image) {
          try {
            await ImageDriver.persist(data.image, {
              principalOwner: {
                id: String(response.central.id),
                name: 'Central',
              },
              owners: [
                {
                  id: customerId,
                  name: 'Customer',
                },
                {
                  id: patrimonyId,
                  name: 'Patrimony',
                },
                {
                  id: data.account.id,
                  name: 'Account',
                },
                {
                  id: response.central.id,
                  name: 'Central',
                },
                {
                  id: response.central.id,
                  name: 'InstalledDevice',
                },
              ],
            })
          } catch (error) {
            console.debug('Erro ao cadastrar imagem')
          }
        }

        addToast({
          message: 'Central cadastrada com sucesso.',
          type: 'success',
        })
        localStorage.setItem('accountId', data.account?.id)
        navigate('/account/management/central')
      },
      onError: () =>
        addToast({
          message: 'Erro ao cadastrar central. Tente novamente.',
          error: true,
          type: 'alert',
        }),
    })
  }

  return (
    <ContainerScreen
      clickable
      renderBreadcrumbs={
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Cliente', href: '/account/management/account' },
            { title: `${customerName}`, href: '/account/management/account' },
            { title: 'NOVA CENTRAL', href: '#' },
          ]}
        />
      }
    >
      <Loader isVisible={statusPostCentral === 'pending'} />
      <CentralForm onSubmit={submit} />
    </ContainerScreen>
  )
}
