import buildUrl from 'utilities/buildUrl'
import HttpClient from 'services/httpClient'

import { endpoints } from './endpoints'
import { AccountDetailQuery } from './types'

export const accountDetailsReport = async (
  query: AccountDetailQuery,
): Promise<Blob | MediaSource> => {
  const response = await HttpClient.get(
    buildUrl({
      route: endpoints.details,
      queryParams: query,
    }),
    { responseType: 'blob' },
  )

  return response.data
}
