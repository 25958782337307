import { useMutation, useQueryClient } from '@tanstack/react-query'

import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'

export const deleteCentralRefused = async (centralId: string) => {
  const response = await HttpClient.delete(
    buildUrl({
      route: endpoints.delete,
      params: { centralId },
    }),
  )

  return response.data
}

export const useDeleteCentralRefused = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (centralId: string) =>
      await deleteCentralRefused(centralId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'get-central-refused'
        },
      })
    },
  })
}
