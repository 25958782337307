import { ServiceOrderLabelInput } from 'services/serviceOrderLabel/types'
import { AccountResponse } from 'services/account/types'
import { PhoneType } from 'services/contact/types'
import { UserResponse } from 'services/auth'
import { Timestamps } from 'services/types'
import { ImageResponse } from 'services/image'

const interventionTypes = {
  STATUS_CHANGE: 'STATUS_CHANGE',
  SO_CHANGE: 'SO_CHANGE',
  PHONE_CALL: 'PHONE_CALL',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_IMAGE: 'SEND_IMAGE',
  MANUAL_RECORD: 'MANUAL_RECORD',
  SO_CREATED: 'SO_CREATED',
}

export type InterventionTypeName = keyof typeof interventionTypes

export const serviceOrderStatuses = {
  OPEN: 'Aberta',
  SCHEDULED: 'Agendada',
  IN_PROGRESS: 'Em execução',
  PAUSED: 'Pausada',
  IN_VALIDATION: 'Em validação',
  DISAPPROVED: 'Reprovada',
  FINISHED: 'Finalizada',
  CANCELED: 'Cancelada',
  RESCHEDULED: 'Reagendada',
}

export type ServiceOrderStatus = keyof typeof serviceOrderStatuses

export const serviceOrderTypes = {
  INSTALL: 'Instalação',
  MAINTENANCE: 'Manutenção',
  REMOTE_MAINTENANCE: 'Manutenção remota',
  UNINSTALL: 'Desinstalação',
}

export type ServiceOrderTypes = keyof typeof serviceOrderTypes

export const schedulePeriods = {
  BUSINESS_HOURS: 'Horário comercial',
  MORNING: 'Manhã',
  AFTERNOON: 'Tarde',
}

export type SchedulePeriod = keyof typeof schedulePeriods

export type StatusChangeIntervention = {
  typeName: 'STATUS_CHANGE'
  attributes: {
    status: ServiceOrderStatus
    technician?: {
      id: string
      name: string
    }
    scheduleDate?: number
    schedulePeriod?: SchedulePeriod
    contact?: {
      id: string
      name: string
    }
    labels?: {
      type: string
      description: string
    }[]
  }
}

export type SOChangeIntervention = {
  typeName: 'SO_CHANGE'
}

export type SendImageIntervention = {
  typeName: 'SEND_IMAGE'
}

export type ManualRecordIntervention = {
  typeName: 'MANUAL_RECORD'
  attributes: {
    hidden?: boolean
  }
}

export type SOCreatedIntervention = {
  typeName: 'SO_CREATED'
  attributes: {
    status: ServiceOrderStatus
    technician?: {
      id: string
      name: string
    }
    scheduleDate?: number
    schedulePeriod?: SchedulePeriod
    contact?: {
      id: string
      name: string
    }
    labels?: {
      type: string
      description: string
    }[]
  }
}

export type PhoneCallIntervention = {
  typeName: 'PHONE_CALL'
  attributes: {
    name: string
    countryCode: string
    provinceCode: string
    number: number
  }
}

export type SendMessageIntervention = {
  typeName: 'SEND_MESSAGE'
  attributes: {
    name: string
    countryCode: string
    provinceCode: string
    number: number
  }
}

export type InterventionNote = {
  id: string
  note: string
  datetime: number
  user: {
    id: string
    name: string
  }
  images?: ImageResponse[]
  hidden?: boolean
  updatedBy?: {
    id?: string
    name?: string
  }
  authorizedBy?: {
    id?: string
    name?: string
  }
  tags?: { id: string; name: string }[]
}

export const SOFields = {
  technician: 'Técnico',
  serviceOrderType: 'Tipo de OS',
  note: 'Descrição',
  contact: 'Contato',
  origin: 'Solicitante',
  reason: 'Motivo',
} as const

export type SOChangeFields = keyof typeof SOFields

export type ChangeEntry = {
  field: SOChangeFields
  oldValue: string
  updatedValue: string
}

export type Intervention = {
  id: string
  title?: string
  datetime: number
  user?: {
    id: string
    name: string
  }
  notes?: InterventionNote[]
  tags?: { id: string; name: string }[]
  previousVersions: ChangeEntry[]
} & (
  | StatusChangeIntervention
  | SOChangeIntervention
  | PhoneCallIntervention
  | SendMessageIntervention
  | SendImageIntervention
  | ManualRecordIntervention
  | SOCreatedIntervention
)

export type ServiceOrderContact = {
  id?: string
  name: string
  countryCode?: number
  provinceCode?: number
  number?: number
}

export type TagInput = {
  name: string
}

export type ServiceOrderPayload = {
  account?: {
    id: string
    aggregatedName: string
    code: string
  }
  labels: ServiceOrderLabelInput[]
  technician?: {
    id?: string
    name: string
  }
  schedulePeriod?: SchedulePeriod
  contact?: ServiceOrderContact
  status: ServiceOrderStatus
  type?: ServiceOrderTypes
  scheduleDate?: number
  tags?: TagInput[]
  userId: string
  number: number
  id: string
  customer: {
    id: string
    name: string
  }
  note: string
}

export const contactTypes = {
  ADMIN: 'Administrador',
  EMERGENCY: 'Emergencial',
  GUEST: 'Convidado',
}

export type ContactType = keyof typeof contactTypes

export type Label = {
  type: string
  description: string
}

export type ServiceOrder = {
  id: string
  note?: string
  number: number
  accountId: string
  centralId?: string
  tags: { id: string; name: string }[]
  scheduleDate?: number
  origin?: Label
  reason?: Label
  schedulePeriod?: SchedulePeriod
  contact?: {
    id: string
    name: string
    countryCode: number
    number: number
    provinceCode: number
  }
  operator?: { id: string; name: string }
  technician?: { id: string; name: string; image?: string }
  status: ServiceOrderStatus
  labels?: {
    id?: string
    type: string
    description: string
  }[]
  serviceOrderType: ServiceOrderTypes
}

export type Contact = {
  id: string
  name: string
  type: ContactType
  phone: {
    id: string
    type: PhoneType
    countryCode: number
    provinceCode: number
    number: number
    extension?: number
    priorityOrder: number
  }
  hasRemoteControl: boolean
}

export type ServiceOrderAccount = {
  account: {
    id: string
    code: string
    name: string
    serviceType?: {
      id: string
      name: string
    }
  }
  patrimony: {
    id: string
    name: string
    image: string
    address: {
      address: string
      number: string
      city: string
      district: string
      state: string
      acronym: string
    }
  }
  contacts: Contact[]
  customer: {
    id: string
    name: string
  }
}

export type SOQuery = {
  id: string
  number: number
  accountId: string
  technicianId: string
  status: ServiceOrderStatus
  serviceOrderType: ServiceOrderTypes
  createdAt: number
  updatedAt: number
  closedAt?: number
}

export type QuerySO = {
  account: AccountResponse
  technician: UserResponse
} & SOQuery

export type Tag = {
  id: string
  name: string
}

type TagOutput = {
  id: string
  totalLinks: number
} & TagInput &
  Timestamps

export type ServiceOrderAggregatedResponse = {
  id: string
  number: number
  status: ServiceOrderStatus
  serviceOrderType: ServiceOrderTypes
  scheduleDate?: number
  schedulePeriod?: SchedulePeriod
  contact?: ServiceOrderContact
  account?: {
    id: string
    code: string
    aggregatedName: string
  }
  technician?: {
    id: string
    name: string
  }
  customer?: {
    id: string
    name: string
  }
  note?: string
  patrimony?: {
    id: string
    name: string
    address?: {
      address: string
      number: string
      district: string
      city: string
      acronym: string
    }
  }
  tags?: TagOutput[]
  reason?: string
  labels: ServiceOrderLabelInput[]
} & Timestamps

export type StatusChangePayload = {
  typeName: 'STATUS_CHANGE'
  attributes: {
    status: ServiceOrderStatus
    technicianId?: string
    scheduleDate?: number
    schedulePeriod?: SchedulePeriod
    contact?: {
      name?: string
      phone?: string
      number?: number
      countryCode?: number
      provinceCode?: number
    }
    labelIds?: string[]
  }
}

export type SOChangePayload = {
  typeName: 'SO_CHANGE'
}

export type SendImagePayload = {
  typeName: 'SEND_IMAGE'
}

export type ManualRecordPayload = {
  typeName: 'MANUAL_RECORD'
  attributes: {
    hidden?: boolean
  }
}

export type SOCreatedPayload = {
  typeName: 'SO_CREATED'
}

export type PhoneCallPayload = {
  typeName: 'PHONE_CALL'
  attributes: {
    number: string
    contactId: string
  }
}

export type SendMessagePayload = {
  typeName: 'SEND_MESSAGE'
  attributes: {
    number: string
    contactId: string
  }
}

export type InterventionNotePayload = {
  note: string
  userId: string
  hidden: boolean
  images?: File[]
  updatedBy?: string
  authorizedBy?: string
  tags?: { name: string }[]
}

export type InterventionPayload = {
  note?: InterventionNotePayload
  title?: string
  userId: string
  typeName: InterventionTypeName
} & (
  | PhoneCallPayload
  | SendMessagePayload
  | SOCreatedPayload
  | ManualRecordPayload
  | SendImagePayload
  | SOChangePayload
  | StatusChangePayload
)
