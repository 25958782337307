import { AccountDetails, SODetails, TimeLine } from './containers'

import styles from './styles.module.scss'

const Details = () => {
  return (
    <div className={styles.container}>
      <AccountDetails />
      <div className={styles.content}>
        <SODetails />
        <hr className={styles.divider} />
        <TimeLine />
      </div>
    </div>
  )
}

export default Details
