import { useQuery } from '@tanstack/react-query'
import { AuthDriver } from 'services/auth'

const fetchUserInfo = async (userId: string) => {
  if (userId) {
    const userInfoResponse = await AuthDriver.getUserInfo(userId)
    const userPermissionsResponse = await AuthDriver.getUserPermissions(userId)

    return {
      ...userInfoResponse,
      permissions: {
        ...userPermissionsResponse,
      },
    }
  }
}

export const useGetUserInfo = (userId: string, enabled?: boolean) =>
  useQuery({
    queryKey: ['fetch-user-info', userId],
    queryFn: async () => {
      const response = await fetchUserInfo(userId)
      return response
    },
    enabled,
  })
