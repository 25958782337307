import Joi from '@hapi/joi'
import { Form } from 'services/periodicEventControl/types'

export const schema = Joi.object<Form>({
  expirationDate: Joi.boolean().optional().allow(null, ''),
  disableReason: Joi.string().required(),
  reactivationDateTime: Joi.when('expirationDate', {
    is: true,
    then: Joi.number().required(),
    otherwise: Joi.number().optional().allow(null, ''),
  }),
})
