import {
  Button,
  Checkbox,
  Combobox,
  ComboboxItem,
  Datepicker,
  RadioGroup,
} from 'components'
import { Combobox as ComboboxV2 } from 'components/ComboboxV2/Combobox'
import { useOSFilter } from 'domains/serviceOrders/utilities'
import React, { ChangeEvent, useCallback, useState } from 'react'
import ReactDOM from 'react-dom'

import { Pagination } from 'services/types'

import {
  SERVICE_ORDER_STATUS_ENUM,
  SERVICE_ORDER_STATUS_LABEL,
  SERVICE_ORDER_TYPE_ENUM,
  ServiceOrderDriver,
  TagOutput,
  serviceOrderStatusLabel,
} from 'services/serviceOrder'
import AccountDriver from 'services/account'
import { CustomerDriver } from 'services/customer'
import { SortedCustomer } from 'services/customer/types'
import { UserQueryResult } from 'services/auth'

import { ReactComponent as CloseButton } from 'assets/svg/close.svg'

import { useFormContext } from 'react-hook-form'
import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'

import { parseDataToCombobox, parseDataToComboboxV2 } from 'utilities/combobox'

import styles from './ServiceOrderFilters.module.scss'
import { AccountTag, AggregatedAccount } from 'services/account/types'
import { PatrimonyDriver } from 'services/patrimony'
import { PatrimonyFragment } from 'services/patrimony/types'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { ServiceOrderFiltersSchema } from 'components/ServiceOrderFilters/schemas/searchServiceOrderSchema'
import {
  HiddenFilters,
  technicianStatusType,
} from 'components/ServiceOrderFilters/types'
import { useInfiniteServiceOrderLabel } from 'services/serviceOrderLabel'
import { ServiceOrderLabelOutput } from 'services/serviceOrderLabel/types'
import { useDebounce, useGetAccountTags } from 'shared/hooks'
import {
  convertedServiceOrderStatusLabel,
  convertedServiceOrderTypeLabel,
  serviceOrderTypeLabel,
} from 'components/ServiceOrderFilters/data'
import {
  getTechnicianStatus,
  handleCheckboxSelectedOption,
  technicianStatusOptions,
} from 'components/ServiceOrderFilters/utilities'
import { useGetUsers } from 'shared/hooks/services'
import { RadioButton } from 'components/RadioButton/RadioButton'
import { useGetServiceType } from 'shared/hooks/services/serviceType/useGetServiceType'
import { ServiceType } from 'services/serviceType/types'

export type ServiceOrderFiltersProps = {
  isVisible: boolean
  onClose: () => void
  onApplyFilters: () => void
  hiddenFilters?: Array<HiddenFilters>
  onValidateFilters?: () => boolean
  status?: SERVICE_ORDER_STATUS_LABEL[]
}

export const ServiceOrderFilters: React.FC<ServiceOrderFiltersProps> = ({
  isVisible,
  onApplyFilters,
  onClose,
  hiddenFilters,
  status = Object.values(serviceOrderStatusLabel),
}: ServiceOrderFiltersProps) => {
  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const { setValue, watch, reset, register } =
    useFormContext<ServiceOrderFiltersSchema>()
  const [technicianStatus, setTechnicianStatus] =
    useState<technicianStatusType>('BOTH')

  const {
    customer,
    patrimony,
    account,
    createdAtFrom,
    createdAtTo,
    scheduleDateFrom,
    scheduleDateTo,
    closedAtFrom,
    closedAtTo,
    applyAttendanceExclusivity,
    tag,
    accountTag,
    serviceType,
    type: serviceOrderTypes,
    status: serviceOrderStatus,
  } = watch()

  const handleFetchCustomers = useCallback(
    async (pagination: Pagination, filter?: string) => {
      const response = await CustomerDriver.querySortedCustomers({
        ...(filter && { name: filter }),
        ...pagination,
      })
      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  const handleFetchPatrimony = useCallback(
    async (pagination: Pagination, filter?: string) => {
      let response = { data: [], totalElements: 0 }

      if (customer.id) {
        response = await PatrimonyDriver.queryPatrimoniesFragmentList(
          customer.id,
          {
            ...(filter && { name: filter }),
            ...pagination,
          },
        )
      }

      return parseDataToCombobox(response, 'name')
    },
    [customer],
  )

  const handleFetchAccounts = useCallback(
    async (pagination: Pagination, code?: string) => {
      const response = await AccountDriver.queryAggregatedAccounts({
        ...(code && { code }),
        ...pagination,
      })

      return parseDataToCombobox(response, 'aggregatedAccountName')
    },
    [],
  )

  const [filters, setfilters] = useState({
    origin: '',
    reason: '',
    pauseReason: '',
    issue: '',
    resolution: '',
    technician: '',
    accountTag: '',
    serviceType: '',
  })

  const {
    isError: isErrorTechnicians,
    isFetching: isFetchingTechnicians,
    users: technicians,
    fetchNextUsersPage: fetchNextTechniciansPage,
  } = useGetUsers({
    ...(!!filters.technician && { name: filters.technician }),
  })

  const handleFetchTags = useCallback(
    async (pagination: Pagination, filter?: string) => {
      const response = await ServiceOrderDriver.queryTag({
        ...(filter && { name: filter }),
        ...pagination,
      })
      return parseDataToCombobox(response, 'name')
    },
    [],
  )

  const {
    isError: isOriginError,
    isFetching: isFetchingOrigins,
    serviceOrderLabels: origins,
    fetchNextPage: fetchNextOriginPage,
  } = useInfiniteServiceOrderLabel({
    type: 'ORIGIN',
    description: filters?.origin,
    active: true,
  })

  const {
    isError: isReasonError,
    isFetching: isFetchingReasons,
    serviceOrderLabels: reasons,
    fetchNextPage: fetchNextReasonPage,
  } = useInfiniteServiceOrderLabel({
    type: 'REASON',
    description: filters.reason,
    active: true,
  })

  const {
    isError: isPauseReasonError,
    isFetching: isFetchingPauseReasons,
    serviceOrderLabels: pauseReasons,
    fetchNextPage: fetchNextPauseReasonPage,
  } = useInfiniteServiceOrderLabel({
    type: 'PAUSE_REASON',
    description: filters.pauseReason,
    active: true,
  })

  const {
    isError: isIssueError,
    isFetching: isFetchingIssue,
    serviceOrderLabels: issues,
    fetchNextPage: fetchNextIssuesPage,
  } = useInfiniteServiceOrderLabel({
    type: 'ISSUE',
    description: filters.issue,
    active: true,
  })

  const {
    isError: isResolutionError,
    isFetching: isFetchingResolution,
    serviceOrderLabels: resolutions,
    fetchNextPage: fetchNextResolutionsPage,
  } = useInfiniteServiceOrderLabel({
    type: 'RESOLUTION',
    description: filters.resolution,
    active: true,
  })

  const {
    accountTags,
    fetchNextTagsPage,
    isError: isErrorAccountTags,
    isFetching: isFetchingAccountTags,
  } = useGetAccountTags(filters.accountTag)

  const {
    serviceTypes,
    isError: isErrorServiceTypes,
    isFetching: isFetchingServiceTypes,
    fetchNextServiceTypesPage,
  } = useGetServiceType(filters.serviceType)

  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setfilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  const handleChangeScheduleDateInput = useCallback(
    (formKey: string, event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        const date = convertStringToDate(value)
        setValue(formKey, date.getTime())
        return value
      } else {
        return value
      }
    },
    [setValue],
  )

  const checkSoType = useCallback(
    (type: string) => {
      const selectedType = convertedServiceOrderTypeLabel[type]

      const values = Object.values(serviceOrderTypes || [])

      return values.includes(selectedType as SERVICE_ORDER_TYPE_ENUM)
    },
    [serviceOrderTypes],
  )

  const checkSoStatus = useCallback(
    (status: string) => {
      const selectedStatus = convertedServiceOrderStatusLabel[status]

      const values = Object.values(serviceOrderStatus || [])

      return values.includes(selectedStatus as SERVICE_ORDER_STATUS_ENUM)
    },
    [serviceOrderStatus],
  )

  const { handleOSFilter } = useOSFilter()

  return ReactDOM.createPortal(
    <div
      className={[styles.container, isVisible ? styles.show : styles.hide].join(
        ' ',
      )}
      data-testid="filters-component"
    >
      <div className={styles.innerWrapper}>
        <div className={styles.header}>
          <span>Filtros</span>
          <CloseButton
            height={16}
            width={16}
            onClick={onClose}
            className={styles.closeIcon}
            data-testid="filter-close-button"
          />
        </div>
        <div className={styles.contentWrapper}>
          <div
            className={styles.gridWrapper}
            {...register('applyAttendanceExclusivity')}
          >
            <Checkbox
              id="applyAttendanceExclusivity"
              checked={applyAttendanceExclusivity}
              label="Aplicar exclusividade"
              onChange={() => {
                setValue(
                  'applyAttendanceExclusivity',
                  !watch('applyAttendanceExclusivity'),
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                )
              }}
            />
          </div>

          {!hiddenFilters?.includes('customer') && (
            <Combobox
              {...register('customer')}
              id="customer-input"
              label="Cliente"
              disabled={Boolean(account)}
              value={
                customer
                  ? {
                      label: 'name',
                      value: customer,
                    }
                  : undefined
              }
              searchable
              fetcher={handleFetchCustomers}
              getSelected={(selected) => {
                const customer = selected as ComboboxItem<SortedCustomer>
                setValue('customer', {
                  id: customer?.value.id,
                  name: customer?.value.name,
                  accounts: customer?.value.accounts,
                })
                if (patrimony) {
                  setValue('patrimony', undefined)
                }
              }}
            />
          )}
          {!hiddenFilters?.includes('patrimony') && (
            <Combobox
              {...register('patrimony')}
              id="patrimony-input"
              label="Patrimônio"
              disabled={!customer || Boolean(account)}
              value={
                patrimony
                  ? {
                      label: 'name',
                      value: patrimony,
                    }
                  : undefined
              }
              searchable
              fetcher={handleFetchPatrimony}
              getSelected={(selected) => {
                const patrimony = selected as ComboboxItem<PatrimonyFragment>
                setValue('patrimony', {
                  id: patrimony?.value.id,
                  name: patrimony?.value.name,
                  accountId: patrimony?.value.accountId,
                })
              }}
            />
          )}
          {!hiddenFilters?.includes('account') && (
            <Combobox
              {...register('account')}
              id="account-input"
              label="Conta"
              value={
                account
                  ? {
                      label: 'aggregatedAccountName',
                      value: account,
                    }
                  : undefined
              }
              searchable
              fetcher={handleFetchAccounts}
              getSelected={(selected) => {
                const account = selected as ComboboxItem<AggregatedAccount>
                setValue('account', {
                  id: account.value?.id,
                  aggregatedAccountName: account.value?.aggregatedAccountName,
                })
                setValue('patrimony', undefined)
                setValue('customer', undefined)
              }}
            />
          )}
          <ComboboxV2<AccountTag>
            id="accountTag"
            label={{ text: 'Tag da conta' }}
            value={
              accountTag
                ? {
                    label: 'name',
                    value: accountTag,
                  }
                : undefined
            }
            items={parseDataToComboboxV2(accountTags || [], 'name')}
            onEndReached={fetchNextTagsPage}
            isLoading={isFetchingAccountTags}
            isError={isErrorAccountTags}
            onSearch={(value) => {
              if (value === '') {
                setValue('accountTag', undefined)
                return
              }

              handleFilter({ accountTag: value })
            }}
            {...register('accountTag')}
            onChange={(selected) => {
              const selectedTag = selected as ComboboxItem<AccountTag>
              if (selectedTag) {
                setValue('accountTag', selectedTag.value)
              }
            }}
          />
          <ComboboxV2
            id="serviceType"
            label={{ text: 'Tipo de serviço' }}
            value={
              serviceType
                ? {
                    label: 'name',
                    value: serviceType,
                  }
                : undefined
            }
            items={parseDataToComboboxV2(
              serviceTypes
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((serviceType) => ({
                  id: serviceType.id,
                  name: serviceType.name,
                })) || [],
              'name',
            )}
            {...register('serviceType')}
            onEndReached={fetchNextServiceTypesPage}
            isLoading={isFetchingServiceTypes}
            isError={isErrorServiceTypes}
            onSearch={(value) => {
              if (value === '') {
                setValue('serviceType', undefined)
                return
              }

              handleFilter({ serviceType: value })
            }}
            onChange={(selected) => {
              const selectedServiceType = selected as ComboboxItem<
                Pick<ServiceType, 'id' | 'name'>
              >
              if (selectedServiceType)
                setValue('serviceType', selectedServiceType.value)
            }}
          />
          <ComboboxV2
            id="technician-input"
            label={{ text: 'Técnicos' }}
            {...register('technicians')}
            value={
              watch('technicians')?.map((technician) => ({
                label: 'name',
                value: technician,
              })) || []
            }
            multiple
            searchable
            onSearch={(value) => {
              if (value === '') {
                setValue('technician', undefined)
                return
              }

              handleFilter({ technician: value })
            }}
            items={parseDataToComboboxV2(
              technicians.map((technician) => ({
                id: technician.id,
                name: technician.name,
              })) || [],
              'name',
            )}
            isLoading={isFetchingTechnicians}
            isError={isErrorTechnicians}
            onEndReached={fetchNextTechniciansPage}
            onChange={(selected) => {
              const technicians = selected as ComboboxItem<UserQueryResult>[]

              setValue(
                'technicians',
                technicians.map((label) => ({
                  id: label.value.id,
                  name: label.value.name,
                })),
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              )
            }}
          />
          <div className={styles.radioButtons}>
            <RadioGroup title="Situação">
              {technicianStatusOptions.map((option) => (
                <RadioButton
                  {...register('hasTechnician')}
                  value={option.label}
                  onChange={() => {
                    const status = getTechnicianStatus(option.key)
                    if (status) {
                      setTechnicianStatus(status.key)
                      setValue('hasTechnician', status.value, {
                        shouldDirty: true,
                      })
                    }
                  }}
                  checked={technicianStatus === option.key}
                  name={'hasTechnician'}
                  key={option.key}
                />
              ))}
            </RadioGroup>
          </div>
          <Combobox
            {...register('tag')}
            id="tag-input"
            label="Tag"
            value={
              tag
                ? {
                    label: 'name',
                    value: tag,
                  }
                : undefined
            }
            searchable
            fetcher={handleFetchTags}
            getSelected={(selected) => {
              const tag = selected as ComboboxItem<TagOutput>
              setValue('tag', {
                id: tag?.value.id,
                name: tag?.value.name,
              })
            }}
          />

          <ComboboxV2
            {...register('origin')}
            id="reason-input"
            label={{
              text: 'Solicitante',
            }}
            onSearch={(search) => handleFilter({ origin: search })}
            items={parseDataToComboboxV2(origins, 'description')}
            value={
              watch('origin')?.map((origin) => ({
                label: 'description',
                value: origin,
              })) || []
            }
            onChange={(selected) => {
              const origin = selected as ComboboxItem<ServiceOrderLabelOutput>[]

              setValue(
                'origin',
                origin.map((label) => ({
                  id: label.value.id,
                  description: label.value.description,
                })),
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              )
            }}
            isLoading={isFetchingOrigins}
            isError={isOriginError}
            onEndReached={fetchNextOriginPage}
            multiple
          />

          <ComboboxV2
            {...register('reason')}
            id="origin-input"
            label={{
              text: 'Motivo',
            }}
            onSearch={(search) => handleFilter({ reason: search })}
            items={parseDataToComboboxV2(reasons, 'description')}
            value={
              watch('reason')?.map((reason) => ({
                label: 'description',
                value: reason,
              })) || []
            }
            onChange={(selected) => {
              const reason = selected as ComboboxItem<ServiceOrderLabelOutput>[]

              setValue(
                'reason',
                reason.map((label) => ({
                  id: label.value.id,
                  description: label.value.description,
                })),
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              )
            }}
            isLoading={isFetchingReasons}
            isError={isReasonError}
            onEndReached={fetchNextReasonPage}
            multiple
          />

          <ComboboxV2
            {...register('pauseReason')}
            id="pauseReason-input"
            label={{
              text: 'Motivo da pausa',
            }}
            onSearch={(search) => handleFilter({ pauseReason: search })}
            value={
              watch('pauseReason')?.map((pauseReason) => ({
                label: 'description',
                value: pauseReason,
              })) || []
            }
            items={parseDataToComboboxV2(pauseReasons, 'description')}
            onChange={(selected) => {
              const pauseReason =
                selected as ComboboxItem<ServiceOrderLabelOutput>[]

              setValue(
                'pauseReason',
                pauseReason.map((label) => ({
                  id: label.value.id,
                  description: label.value.description,
                })),
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              )
            }}
            isLoading={isFetchingPauseReasons}
            isError={isPauseReasonError}
            onEndReached={fetchNextPauseReasonPage}
            multiple
          />

          <ComboboxV2
            {...register('issue')}
            id="issue-input"
            label={{
              text: 'Problema',
            }}
            onSearch={(search) => handleFilter({ issue: search })}
            items={parseDataToComboboxV2(issues, 'description')}
            value={
              watch('issue')?.map((issue) => ({
                label: 'description',
                value: issue,
              })) || []
            }
            onChange={(selected) => {
              const issue = selected as ComboboxItem<ServiceOrderLabelOutput>[]

              setValue(
                'issue',
                issue.map((label) => ({
                  id: label.value.id,
                  description: label.value.description,
                })),
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              )
            }}
            isLoading={isFetchingIssue}
            isError={isIssueError}
            onEndReached={fetchNextIssuesPage}
            multiple
          />

          <ComboboxV2
            {...register('resolution')}
            id="resolution-input"
            label={{
              text: 'Solução dada',
            }}
            onSearch={(search) => handleFilter({ resolution: search })}
            items={parseDataToComboboxV2(resolutions || [], 'description')}
            value={
              watch('resolution')?.map((resolution) => ({
                label: 'description',
                value: resolution,
              })) || []
            }
            onChange={(selected) => {
              const selectedResolution =
                selected as ComboboxItem<ServiceOrderLabelOutput>[]

              setValue(
                'resolution',
                selectedResolution.map((label) => ({
                  id: label.value.id,
                  description: label.value.description,
                })),
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              )
            }}
            isLoading={isFetchingResolution}
            isError={isResolutionError}
            onEndReached={fetchNextResolutionsPage}
            multiple
          />

          <div className={styles.gridWrapper}>
            <p className={styles.sectionLabel}>Tipo</p>
            <div className={styles.gridWrapper}>
              {Object.values(serviceOrderTypeLabel).map((type) => {
                return (
                  <Checkbox
                    key={type}
                    checked={checkSoType(type)}
                    id={type}
                    label={type}
                    onChange={() => {
                      const selectedType = convertedServiceOrderTypeLabel[type]

                      const previousValues = Object.values(
                        serviceOrderTypes || [],
                      )
                      const selectedOptions = handleCheckboxSelectedOption(
                        selectedType,
                        previousValues,
                      )

                      setValue('type', selectedOptions, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                )
              })}
            </div>
          </div>
          {!hiddenFilters?.includes('status') && (
            <div className={styles.gridWrapper}>
              <p className={styles.sectionLabel}>Status</p>
              <div
                className={styles.statusInnerWrapper}
                {...register('status')}
              >
                {status.map((status) => {
                  return (
                    <Checkbox
                      key={status}
                      checked={checkSoStatus(status)}
                      id={status}
                      label={status}
                      onChange={() => {
                        const selectedStatus =
                          convertedServiceOrderStatusLabel[status]

                        const previousValues = Object.values(
                          serviceOrderStatus || [],
                        )
                        const selectedOptions = handleCheckboxSelectedOption(
                          selectedStatus,
                          previousValues,
                        )

                        setValue('status', selectedOptions, {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }}
                    />
                  )
                })}
              </div>
            </div>
          )}

          <div
            className={styles.datePickerWrapper}
            data-testid="create-date-filter-wrapper"
          >
            <p className={styles.sectionLabel}>Cadastro</p>
            <div className={styles.datePickerInnerWrapper}>
              <span className={styles.dateLabel}>De</span>
              <Datepicker
                id="createdAtFrom"
                initialValueInput={
                  createdAtFrom
                    ? convertDateToString(new Date(createdAtFrom))
                    : undefined
                }
                initialDate={
                  createdAtFrom ? new Date(createdAtFrom) : undefined
                }
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdAtFrom', event)
                }
                onChangeDate={(date) =>
                  setValue('createdAtFrom', date?.getTime())
                }
                {...register('createdAtFrom')}
              />
              <span className={styles.dateLabel}>até</span>
              <Datepicker
                id="createdAtTo"
                disabled={!createdAtFrom}
                selectEnd
                initialValueInput={
                  createdAtTo
                    ? convertDateToString(new Date(createdAtTo))
                    : undefined
                }
                initialDate={
                  createdAtFrom ? new Date(createdAtFrom) : undefined
                }
                onChangeInput={(event) =>
                  handleChangeScheduleDateInput('createdAtTo', event)
                }
                onChangeDate={(date) =>
                  setValue('createdAtTo', date?.getTime())
                }
                errorMessage={
                  createdAtFrom && !createdAtTo ? 'Campo obrigatório.' : ''
                }
                {...register('createdAtTo')}
              />
            </div>
          </div>

          {!hiddenFilters?.includes('schedule') && (
            <div
              className={styles.datePickerWrapper}
              data-testid="schedule-date-filter-wrapper"
            >
              <p className={styles.sectionLabel}>Agendamento</p>
              <div className={styles.datePickerInnerWrapper}>
                <span className={styles.dateLabel}>De</span>
                <Datepicker
                  id="scheduleDateFrom"
                  initialValueInput={
                    scheduleDateFrom
                      ? convertDateToString(new Date(scheduleDateFrom))
                      : undefined
                  }
                  initialDate={
                    scheduleDateFrom ? new Date(scheduleDateFrom) : undefined
                  }
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('scheduleDateFrom', event)
                  }
                  onChangeDate={(date) =>
                    setValue('scheduleDateFrom', date?.getTime())
                  }
                  {...register('scheduleDateFrom')}
                />
                <span className={styles.dateLabel}>até</span>
                <Datepicker
                  id="scheduleDateTo"
                  disabled={!scheduleDateFrom}
                  selectEnd
                  initialValueInput={
                    scheduleDateTo
                      ? convertDateToString(new Date(scheduleDateTo))
                      : undefined
                  }
                  initialDate={
                    scheduleDateFrom ? new Date(scheduleDateFrom) : undefined
                  }
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('scheduleDateTo', event)
                  }
                  onChangeDate={(date) =>
                    setValue('scheduleDateTo', date?.getTime())
                  }
                  errorMessage={
                    scheduleDateFrom && !scheduleDateTo
                      ? 'Campo obrigatório.'
                      : ''
                  }
                  {...register('scheduleDateTo')}
                />
              </div>
            </div>
          )}

          {!hiddenFilters?.includes('finishedAt') && (
            <div
              className={styles.datePickerWrapper}
              data-testid="close-date-filter-wrapper"
            >
              <p className={styles.sectionLabel}>Fechamento</p>
              <div
                className={[
                  styles.datePickerInnerWrapper,
                  styles.closeDateDatePicker,
                ].join(' ')}
              >
                <span className={styles.dateLabel}>De</span>
                <Datepicker
                  id="closedAtFrom"
                  initialValueInput={
                    closedAtFrom
                      ? convertDateToString(new Date(closedAtFrom))
                      : undefined
                  }
                  initialDate={
                    closedAtFrom ? new Date(closedAtFrom) : undefined
                  }
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('closedAtFrom', event)
                  }
                  onChangeDate={(date) =>
                    setValue('closedAtFrom', date?.getTime())
                  }
                  {...register('closedAtFrom')}
                />
                <span className={styles.dateLabel}>até</span>
                <Datepicker
                  id="closedAtTo"
                  disabled={!closedAtFrom}
                  selectEnd
                  initialValueInput={
                    closedAtTo
                      ? convertDateToString(new Date(closedAtTo))
                      : undefined
                  }
                  initialDate={
                    closedAtFrom ? new Date(closedAtFrom) : undefined
                  }
                  onChangeInput={(event) =>
                    handleChangeScheduleDateInput('closedAtTo', event)
                  }
                  onChangeDate={(date) =>
                    setValue('closedAtTo', date?.getTime())
                  }
                  errorMessage={
                    closedAtFrom && !closedAtTo ? 'Campo obrigatório.' : ''
                  }
                  {...register('closedAtTo')}
                />
              </div>
            </div>
          )}

          <div className={styles.gradient} />
          <div className={styles.fadeout} />
        </div>
        <div className={styles.footer}>
          <Button
            buttonTitle="Limpar filtros"
            type="secondary"
            width="162px"
            onClick={() => {
              clearAllLocalStorageFilters()
              reset({ applyAttendanceExclusivity: true })
            }}
          />
          <Button
            buttonTitle="Aplicar Filtros"
            type="primary"
            onClick={() => {
              if (createdAtFrom && !createdAtTo) {
                setValue('createdAtTo', new Date().getTime())
              }

              if (scheduleDateFrom && !scheduleDateTo) {
                setValue('scheduleDateTo', new Date().getTime())
              }

              if (closedAtFrom && !closedAtTo) {
                setValue('closedAtTo', new Date().getTime())
              }
              const { number, ...restFields } = watch()

              onApplyFilters()
              handleOSFilter(restFields)
            }}
            width="162px"
          />
        </div>
      </div>
    </div>,
    document.getElementById('main-container') || document.body,
  )
}
