import { useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonV2, ErrorState, Icon, SelectV2 as Select } from 'components'
import {
  Intervention,
  SOChangeFields,
  SOFields,
  useGetInterventions,
} from 'services'
import { maskedDate } from 'utilities/date'
import { handleRenderState } from 'utilities/handleRenderState'
import Loading from './Loading'
import joinClassNames from 'utilities/joinClassNames'
import { Item } from './components'
import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'

type ContentProps = {
  context?: 'drawer' | 'modal'
  intervention?: Intervention
}

const Content = ({ context, intervention }: ContentProps) => {
  const { serviceOrderId } = useParams()

  const isDrawer = context === 'drawer'

  const [selectedIntervention, setSelectedIntervention] = useState(intervention)

  const { data, isError, isLoading } = useGetInterventions(serviceOrderId)

  const [filters, setFilters] = useState<{
    user: string
    date: string
    field: SOChangeFields | undefined
  }>({
    user: '',
    date: '',
    field: undefined,
  })

  useEffect(() => {
    if (selectedIntervention) {
      setFilters({
        user: selectedIntervention.user?.name || '',
        date: selectedIntervention.datetime.toString(),
        field: selectedIntervention.previousVersions?.[0]?.field,
      })
    }
  }, [selectedIntervention])

  const soChangeInterventions = useMemo(
    () =>
      data?.data.filter(
        (intervention) => intervention.typeName === 'SO_CHANGE',
      ) || [],
    [data],
  )

  const userOptions = useMemo(
    () =>
      Array.from(
        new Set(
          soChangeInterventions.map((intervention) => intervention.user?.name),
        ),
      ),
    [soChangeInterventions],
  )

  const dateOptions = useMemo(
    () =>
      Array.from(
        new Set(
          soChangeInterventions.map((intervention) => {
            const date = new Date(intervention.datetime)
            return new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
            ).getTime()
          }),
        ),
      )
        .map((date) => ({
          value: date,
          label: maskedDate(date),
        }))
        .sort((a, b) => a.value - b.value),
    [soChangeInterventions],
  )

  const fieldOptions = useMemo(
    () =>
      Object.entries(SOFields)
        .filter(([key]) =>
          soChangeInterventions?.some((intervention) =>
            intervention.previousVersions?.some(
              (change) => change.field === key,
            ),
          ),
        )
        .map(([key, label]) => ({
          key,
          label,
        })),
    [soChangeInterventions],
  )

  const handleFilterChange = useCallback((filterName, value) => {
    setSelectedIntervention(undefined)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }))
  }, [])

  const clearFilters = () => {
    setFilters({ user: '', date: '', field: undefined })
    setSelectedIntervention(undefined)
  }

  const filteredInterventions = useMemo(() => {
    if (selectedIntervention) {
      return [selectedIntervention]
    }
    return data?.data?.filter((intervention) => {
      const matchesUser =
        !filters.user || intervention.user?.name === filters.user
      const matchesDate =
        !filters.date ||
        new Date(
          new Date(intervention.datetime).setHours(0, 0, 0, 0),
        ).getTime() ===
          new Date(new Date(filters.date).setHours(0, 0, 0, 0)).getTime()
      const matchesField =
        !filters.field ||
        intervention.previousVersions?.some(
          (change) => change.field === filters.field,
        )
      return matchesUser && matchesDate && matchesField
    })
  }, [data, filters, selectedIntervention])

  return {
    view: (
      <>
        <div
          className={joinClassNames(
            styles.container,
            isDrawer && styles.drawer,
          )}
        >
          <div
            className={joinClassNames(
              styles.filters,
              isDrawer && styles.drawer,
            )}
          >
            <div className={styles.filterGroup}>
              <Select.Root
                data={userOptions.map((name) => ({ name }))}
                valueKey="name"
              >
                <Select.Field
                  value={filters.user}
                  onChange={(e) => handleFilterChange('user', e.target.value)}
                  placeholder="Usuário"
                />
                <Select.Group>
                  {userOptions.map((user) => (
                    <Select.Option
                      key={user}
                      onClick={() => handleFilterChange('user', user)}
                    >
                      {user}
                    </Select.Option>
                  ))}
                </Select.Group>
              </Select.Root>
              <Select.Root data={dateOptions}>
                <Select.Field
                  value={filters.date ? maskedDate(Number(filters.date)) : ''}
                  onChange={(e) => handleFilterChange('date', e.target.value)}
                  placeholder="Data da alteração"
                />
                <Select.Group>
                  {dateOptions.map(({ value, label }) => (
                    <Select.Option
                      key={value}
                      onClick={() => handleFilterChange('date', value)}
                    >
                      {label}
                    </Select.Option>
                  ))}
                </Select.Group>
              </Select.Root>
            </div>
            <div className={styles.filterGroup}>
              <Select.Root data={fieldOptions}>
                <Select.Field
                  value={filters.field ? SOFields[filters.field] : ''}
                  onChange={(e) => handleFilterChange('field', e.target.value)}
                  placeholder="Campo alterado"
                />
                <Select.Group>
                  {fieldOptions.map(({ key, label }) => (
                    <Select.Option
                      key={key}
                      onClick={() => handleFilterChange('field', key)}
                    >
                      {label}
                    </Select.Option>
                  ))}
                </Select.Group>
              </Select.Root>
              <ButtonV2
                disabled={!(filters.user || filters.date || filters.field)}
                onClick={clearFilters}
                appearance="tertiary"
              >
                <Icon name="delete" />
                Limpar filtros
              </ButtonV2>
            </div>
          </div>
          <ul
            className={joinClassNames(styles.item, isDrawer && styles.drawer)}
          >
            {filteredInterventions?.map((intervention) => (
              <Item
                key={intervention.id}
                intervention={intervention}
                context={context}
              />
            ))}
          </ul>
        </div>
      </>
    ),
    loading: <Loading />,
    error: <ErrorState />,
  }[handleRenderState({ data, isLoading, isError })]
}

export default Content
