const PREFIX = '/account/management'

enum RouteEntity {
  'Account' = 'Account',
  'Patrimonies' = 'Patrimonies',
  'Equipments' = 'Equipments',
  'Central' = 'Central',
  'Contacts' = 'Contacts',
  'History' = 'History',
  'OfficeHours' = 'OfficeHours',
  'Reports' = 'Reports',
}

export const CustomerPaths: Record<RouteEntity, string> = {
  [RouteEntity.Account]: `${PREFIX}/account`,
  [RouteEntity.Patrimonies]: `${PREFIX}/patrimonies`,
  [RouteEntity.Equipments]: `${PREFIX}/equipments`,
  [RouteEntity.Central]: `${PREFIX}/central`,
  [RouteEntity.Contacts]: `${PREFIX}/contacts?page=1`,
  [RouteEntity.History]: `${PREFIX}/history?page=1`,
  [RouteEntity.OfficeHours]: `${PREFIX}/officeHours?page=1`,
  [RouteEntity.Reports]: `${PREFIX}/reports`,
}
