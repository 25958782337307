import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  schedulePeriods,
  serviceOrderTypes,
  useGetInterventions,
  useGetSO,
} from 'services/serviceOrderV2'
import {
  ButtonV2,
  Icon,
  Info,
  SOTag,
  Tag,
  ErrorState,
  Avatar,
  ModalV2 as Modal,
} from 'components'
import {
  dateNow,
  formatDateWithMonth,
  formatFullDateWithMonth,
  maskedDate,
} from 'utilities/date'
import { handleRenderState } from 'utilities/handleRenderState'

import Loading from './Loading'

import styles from './styles.module.scss'
import { useServiceOrderDetails } from '../../../../context'
import { Actions, Gallery, TechniciansSchedule } from './components'
import PreviousVersions from '../PreviousVersions'

const Details = () => {
  const { serviceOrderId } = useParams()
  const { data, isLoading, isError } = useGetSO(serviceOrderId)
  const { data: interventions } = useGetInterventions(serviceOrderId)

  const SOChangeInterventions = interventions?.data.filter(
    (item) => item.typeName === 'SO_CHANGE',
  )

  const { setServiceOrder } = useServiceOrderDetails()

  useEffect(() => {
    if (data) {
      setServiceOrder(data)
    }
  }, [data])

  const handleFormatScheduleDate = () => {
    if (!!data && data.scheduleDate) {
      return !data.schedulePeriod
        ? dateNow(data.scheduleDate)
        : `${maskedDate(data.scheduleDate)}${data.schedulePeriod ? ` - ${schedulePeriods[data.schedulePeriod]}` : ''}`
    }

    return 'Não há agendamento para esta ordem de serviço'
  }

  return (
    <div className={styles.container}>
      {
        {
          view: !!data && (
            <section>
              <div className={styles.title}>
                <div>
                  <h2>Detalhes da OS</h2>
                  <span>
                    Detalhes sobre o serviço a ser realizado durante a execução
                    da ordem de serviço
                  </span>
                </div>
                <Actions />
              </div>

              <div className={styles.infoGrid}>
                <Info.Root>
                  <Info.Title>Status</Info.Title>
                  <Info.Content>
                    <SOTag status={data.status} />
                  </Info.Content>
                </Info.Root>

                <Info.Root>
                  <Info.Title>Número</Info.Title>
                  <Info.Content>{data.number}</Info.Content>
                </Info.Root>

                <Info.Root>
                  <Info.Title>Data agendada</Info.Title>
                  <Info.Content>{handleFormatScheduleDate()}</Info.Content>
                </Info.Root>

                <Info.Root>
                  <Info.Title>Tipo</Info.Title>
                  <Info.Content>
                    {serviceOrderTypes[data.serviceOrderType] || '--'}
                  </Info.Content>
                </Info.Root>

                <Info.Root>
                  <Info.Title>Motivo</Info.Title>
                  <Info.Content>
                    {data.reason?.description || '--'}
                  </Info.Content>
                </Info.Root>

                <Info.Root>
                  <Info.Title>Solicitante</Info.Title>
                  <Info.Content>
                    {data.origin?.description || '--'}
                  </Info.Content>
                </Info.Root>
                <Info.Root>
                  <Info.Title>Operador</Info.Title>
                  <Info.Content>{data.operator?.name || '--'}</Info.Content>
                </Info.Root>
                <Info.Root>
                  <Info.Title>Contato responsável</Info.Title>
                  <Info.Content>{data.contact?.name || '--'}</Info.Content>
                </Info.Root>
                <Info.Root>
                  <Info.Title>Técnico</Info.Title>
                  <Info.Content className={styles.tactical}>
                    {data.technician ? (
                      <>
                        <Avatar
                          alt="foto do técnico"
                          name={data.technician?.name}
                          imageId={data.technician?.image}
                        />
                        <div>
                          <Modal.Root>
                            <span>{data.technician?.name}</span>
                            <Modal.Trigger>
                              <ButtonV2 appearance="tertiary" size="md">
                                <Icon name="open" /> Ver agenda
                              </ButtonV2>
                            </Modal.Trigger>
                            <Modal.Content size="lg">
                              <TechniciansSchedule
                                technician={data.technician}
                              />
                            </Modal.Content>
                          </Modal.Root>
                        </div>
                      </>
                    ) : (
                      '--'
                    )}
                  </Info.Content>
                </Info.Root>
                <Info.Root>
                  <Info.Title>Descrição</Info.Title>
                  <Info.Content className={styles.description}>
                    {data.note}
                  </Info.Content>
                </Info.Root>
                <Info.Root>
                  <Info.Title>Tags</Info.Title>
                  <Info.Content>
                    {data.tags?.length ? (
                      <div className={styles.tagsContainer}>
                        {data.tags.map((tag) => (
                          <Tag size="md" key={tag.id}>
                            {tag.name}
                          </Tag>
                        ))}
                      </div>
                    ) : (
                      '--'
                    )}
                  </Info.Content>
                </Info.Root>
              </div>
            </section>
          ),
          loading: <Loading />,
          error: <ErrorState />,
        }[handleRenderState({ data, isLoading, isError })]
      }
      <Gallery />
      <i>
        {SOChangeInterventions?.length ? (
          <div className={styles.previousVersions}>
            Última alteração em{' '}
            {formatDateWithMonth(SOChangeInterventions?.[0]?.datetime)}{' '}
            <PreviousVersions triggerTitle="Versões anteriores" />
          </div>
        ) : (
          <i>
            Aberta em{' '}
            {formatFullDateWithMonth(
              interventions?.data[interventions.data.length - 1]?.datetime,
            )}
          </i>
        )}
      </i>
    </div>
  )
}

export default Details
