import EventDriver from 'services/event'

export const queryEventGroup = async (offset = 0, filter?: string) => {
  const eventGroups = await EventDriver.queryEventGroups({
    recordsPerPage: 15,
    offset,
    ...(filter && { description: filter }),
  })

  return eventGroups
}
