import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { CustomerDetails } from './components'
import {
  ComboBoxV3,
  FormLabel,
  FormItem,
  ButtonV2,
  SelectV2,
  Icon,
  Info,
  Tag,
  TextField,
  DropDown,
  IconButton,
  ScheduleServiceOrderModal,
} from 'components'

import { fetchTechnicians } from 'services/serviceOrder/hooks/useGetTechnicians'
import { fetchAccounts } from 'shared/hooks/accounts/useGetAccounts'
import { fetchServiceOrderLabel } from 'services/serviceOrderLabel'
import { getCustomer } from 'shared/hooks/services'
import { serviceOrderSchema } from './schemas'
import { useToggle } from 'shared/hooks'
import { dateNow, maskedDate } from 'utilities/date'
import {
  getServiceOrderTags,
  ServiceOrderPayload,
  serviceOrderTypes,
  schedulePeriods,
  ServiceOrder,
  useGetAccount,
} from 'services/serviceOrderV2'

import { contactQuery } from 'services/contact'

import styles from './styles.module.scss'
import { SERVICE_ORDER_TYPE_ENUM } from 'services/serviceOrder'
import { formatGenericPhone } from 'domains/serviceOrders/utilities/mask/phone'
import { useRewriteSentence } from 'services/openIa'
import joinClassNames from 'utilities/joinClassNames'

type ServiceOrderFormProps = {
  onSubmit: (data: ServiceOrderPayload) => void
  serviceOrder?: ServiceOrder
  accountId?: string
}

const ServiceOrderForm = ({
  onSubmit,
  serviceOrder,
  accountId,
}: ServiceOrderFormProps) => {
  const navigate = useNavigate()
  const [rewrittenSentence, setRewrittenSentence] = useState('')

  const { refetch } = useGetAccount(serviceOrder?.accountId || accountId || '')

  const form = useForm<Omit<ServiceOrderPayload, 'userId'>>({
    resolver: joiResolver(serviceOrderSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: async () => {
      const { data } = await refetch()

      if (data) {
        const { account, customer } = data

        return {
          customer,
          id: serviceOrder?.id,
          note: serviceOrder?.note,
          labels: serviceOrder?.labels?.length
            ? serviceOrder.labels.map((label) => ({
                id: label?.id,
                type: label.type,
                description: label.description,
              }))
            : undefined,
          number: serviceOrder?.number,
          technician: serviceOrder?.technician
            ? {
                id: serviceOrder?.technician.id,
                name: serviceOrder?.technician.name,
              }
            : undefined,
          type: serviceOrder?.serviceOrderType,
          status: serviceOrder?.status || 'OPEN',
          scheduleDate: serviceOrder?.scheduleDate,
          schedulePeriod: serviceOrder?.schedulePeriod,
          account: {
            id: account.id,
            code: account.code,
            aggregatedName: `${account.code} ${account.serviceType?.name ? `(${account.serviceType?.name})` : ''} - ${account.name}`,
          },
          ...(serviceOrder?.contact && {
            contact: {
              id: serviceOrder?.contact.id,
              name: serviceOrder?.contact.name,
              countryCode: serviceOrder?.contact.countryCode,
              number: serviceOrder?.contact.number,
              provinceCode: serviceOrder?.contact.provinceCode,
            },
          }),
          ...(serviceOrder?.tags && {
            tags: serviceOrder.tags.map((tag) => ({ name: tag.name })),
          }),
        }
      }
    },
  })

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = form
  const {
    tags,
    labels,
    account,
    customer,
    technician,
    scheduleDate,
    schedulePeriod,
    contact,
    note,
    id,
    number,
    type,
    status,
  } = watch()

  const {
    isVisible: isScheduleModalOpen,
    hide: hideScheduleModal,
    show: showScheduleModal,
  } = useToggle()

  const handleGetOriginLabel = useCallback(
    async (searchFilter?: string, offset = 0) => {
      const response = await fetchServiceOrderLabel({
        offset,
        active: true,
        type: 'ORIGIN',
        recordsPerPage: 15,
        ...(searchFilter && { description: searchFilter }),
      })

      return response
    },
    [],
  )
  const handleGetReasonLabel = useCallback(
    async (searchFilter?: string, offset = 0) => {
      const response = await fetchServiceOrderLabel({
        offset,
        active: true,
        type: 'REASON',
        recordsPerPage: 15,
        ...(searchFilter && { description: searchFilter }),
      })

      return response
    },
    [],
  )

  const handleGetContacts = useCallback(
    async (searchFilter: string) => {
      const response = await contactQuery({
        customerId: customer?.id,
        ...(searchFilter && {
          name: searchFilter,
        }),
        hasPhone: true,
      })

      return {
        data: [{ name: 'Outro' }, ...response.data],
        totalElements: response.totalElements + 1,
      }
    },
    [customer],
  )

  const handleGetTechnicians = useCallback(
    async (
      searchFilter: string,
      offset = 0,
      serviceOrderType: SERVICE_ORDER_TYPE_ENUM,
    ) => {
      return await fetchTechnicians(
        account?.id,
        type || serviceOrderType,
        offset,
        searchFilter,
      )
    },
    [type, account],
  )

  const handleGetAccounts = useCallback(
    async (searchFilter: string, offset = 0) =>
      await fetchAccounts(offset, 15, customer?.id, {
        ...(searchFilter && {
          code: searchFilter,
        }),
      }),
    [customer],
  )

  const reasonLabel = useMemo(
    () => labels?.find((label) => label.type === 'REASON'),
    [labels],
  )

  const originLabel = useMemo(
    () => labels?.find((label) => label.type === 'ORIGIN'),
    [labels],
  )

  const handleSchedule = useCallback(
    (serviceOrder: ServiceOrderPayload) => {
      hideScheduleModal()
      setValue('schedulePeriod', serviceOrder.schedulePeriod)
      setValue(
        'tags',
        serviceOrder.tags?.map((tag) => ({ name: tag.name })),
      )
      setValue('contact', {
        id: serviceOrder.contact?.id || '',
        number: serviceOrder.contact?.number,
        name: serviceOrder.contact?.name || '',
        countryCode: serviceOrder.contact?.countryCode,
        provinceCode: serviceOrder.contact?.provinceCode,
      })
      setValue('scheduleDate', serviceOrder.scheduleDate)
      setValue('technician', serviceOrder.technician)
      setValue('status', serviceOrder.status)
      setValue('note', serviceOrder.note, {
        shouldValidate: true,
      })
    },
    [hideScheduleModal, setValue],
  )

  const handleResetOptionalValues = () => {
    setValue('contact', undefined)
    setValue('technician', undefined)
    setValue('scheduleDate', undefined)
    setValue('schedulePeriod', undefined)
  }

  const serviceOrderForSchedule = {
    customer,
    account: {
      code: account?.code,
      id: account?.id,
    },
    technician,
    type,
    contact,
    number,
    schedulePeriod,
    tags,
    scheduleDate,
    status,
    note,
    id,
  }

  const handleFormatScheduleDate = () => {
    if (scheduleDate) {
      return !schedulePeriod
        ? dateNow(scheduleDate)
        : `${maskedDate(scheduleDate)}${schedulePeriod ? ` - ${schedulePeriods[schedulePeriod]}` : ''}`
    }

    return 'Não há agendamento para esta ordem de serviço'
  }

  const { mutate: mutateRewriteSentence, isPending } =
    useRewriteSentence('serviceOrder')

  const handleMagicNote = {
    rewrite: () => {
      mutateRewriteSentence(note, {
        onSuccess: (data) => setRewrittenSentence(data.rewrittenSentence),
      })
    },
    accept: () => {
      setValue('note', rewrittenSentence, {
        shouldValidate: true,
      })
      setRewrittenSentence('')
    },
    complement: () => {
      setValue('note', `${note}. \n${rewrittenSentence}`, {
        shouldValidate: true,
      })
      setRewrittenSentence('')
    },
    discard: () => setRewrittenSentence(''),
  }

  const expandSentenceRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleExpandClick = () => {
    if (expandSentenceRef.current && textareaRef.current) {
      expandSentenceRef.current.style.display = 'none'
      textareaRef.current.style.display = 'block'
    }
  }

  const checkOverflow = () => {
    if (textareaRef.current) {
      const maxTextareaHeight = 508
      const combinedText = `${note}${rewrittenSentence ? ` ${rewrittenSentence}` : ''}`
      textareaRef.current.value = combinedText
      const isOverflowing = textareaRef.current.scrollHeight > maxTextareaHeight
      textareaRef.current.value = note
      return isOverflowing
    }
    return false
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [note])

  return (
    <>
      <FormProvider {...form}>
        {isScheduleModalOpen && (
          <ScheduleServiceOrderModal
            onSave={handleSchedule}
            onClose={hideScheduleModal}
            serviceOrder={serviceOrderForSchedule}
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.container}>
            <div>
              <div>
                <div className={styles.header}>
                  <FormItem>
                    <FormLabel htmlFor="customer">Cliente</FormLabel>
                    <ComboBoxV3.Root valueKey="name" findOptions={getCustomer}>
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            id="customer"
                            value={customer?.name || ''}
                            disabled={!!serviceOrder?.id}
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={() => {
                                  setValue(
                                    'customer',
                                    {
                                      id: option.id,
                                      name: option.name,
                                    },
                                    {
                                      shouldValidate: true,
                                    },
                                  )

                                  if (option.accounts?.length === 1) {
                                    const account = option.accounts[0]

                                    setValue(
                                      'account',
                                      {
                                        id: account.id,
                                        code: account?.code || '',
                                        aggregatedName: account.aggregatedName,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  } else {
                                    setValue('account', undefined, {
                                      shouldValidate: true,
                                    })
                                  }

                                  handleResetOptionalValues()
                                }}
                              >
                                {option.name}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>
                  <FormItem>
                    <FormLabel htmlFor="account">Conta</FormLabel>
                    <ComboBoxV3.Root
                      findOptions={handleGetAccounts}
                      valueKey="aggregatedAccountName"
                    >
                      {({ unSelectedOptions }) => (
                        <>
                          <ComboBoxV3.Field
                            id="account"
                            disabled={!!serviceOrder?.id}
                            value={watch('account')?.aggregatedName || ''}
                          />
                          <ComboBoxV3.Group>
                            {unSelectedOptions?.map((option) => (
                              <ComboBoxV3.Option
                                key={option.id}
                                onClick={() => {
                                  setValue(
                                    'account',
                                    {
                                      id: option.id,
                                      code: option?.code || '',
                                      aggregatedName:
                                        option.aggregatedAccountName,
                                    },
                                    {
                                      shouldValidate: true,
                                    },
                                  )

                                  if (option.customer) {
                                    setValue(
                                      'customer',
                                      {
                                        id: option.customer?.id,
                                        name: option.customer?.name,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }

                                  handleResetOptionalValues()
                                }}
                              >
                                {option.aggregatedAccountName}
                              </ComboBoxV3.Option>
                            ))}
                          </ComboBoxV3.Group>
                        </>
                      )}
                    </ComboBoxV3.Root>
                  </FormItem>
                </div>
                <section>
                  <div className={styles.head}>
                    <span>1</span>
                    <div>
                      <Info.Root>
                        <Info.Title className={styles.headTitle}>
                          Dados gerais
                        </Info.Title>
                        <Info.Content>
                          Informações básicas sobre o serviço a ser realizado
                        </Info.Content>
                      </Info.Root>
                    </div>
                  </div>
                  <div className={styles.sectionItens}>
                    <FormItem>
                      <FormLabel htmlFor="serviceOrderType">
                        Tipo de OS
                      </FormLabel>
                      <SelectV2.Root
                        valueKey="name"
                        data={Object.keys(serviceOrderTypes).map(
                          (serviceOrderType) => ({
                            name: serviceOrderType,
                          }),
                        )}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <SelectV2.Field
                              id="serviceOrderType"
                              value={type ? serviceOrderTypes[type] : ''}
                            />
                            <SelectV2.Group>
                              {unSelectedOptions?.map((option) => (
                                <SelectV2.Option
                                  key={option.name}
                                  onClick={async () => {
                                    setValue('type', option.name, {
                                      shouldValidate: true,
                                    })

                                    if (!serviceOrder?.technician?.id) {
                                      const result = await handleGetTechnicians(
                                        '',
                                        0,
                                        option.name as SERVICE_ORDER_TYPE_ENUM,
                                      )

                                      if (result?.data?.length) {
                                        setValue(
                                          'technician',
                                          {
                                            id: result.data[0].id,
                                            name: result.data[0].name,
                                          },
                                          { shouldValidate: true },
                                        )
                                      }
                                    }
                                  }}
                                >
                                  {serviceOrderTypes[option.name]}
                                </SelectV2.Option>
                              ))}
                            </SelectV2.Group>
                          </>
                        )}
                      </SelectV2.Root>
                    </FormItem>
                    <FormItem>
                      <FormLabel htmlFor="reason">Motivo</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="description"
                        findOptions={handleGetReasonLabel}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              id="reason"
                              value={reasonLabel?.description || ''}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id}
                                  onClick={() => {
                                    const newLabel = [
                                      {
                                        id: option.id,
                                        type: option.type,
                                        active: option.active,
                                        description: option.description,
                                      },
                                    ]

                                    originLabel && newLabel.push(originLabel)

                                    setValue('labels', newLabel, {
                                      shouldValidate: true,
                                    })
                                  }}
                                >
                                  {option.description}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                    <FormItem>
                      <FormLabel htmlFor="origin">Solicitante</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="description"
                        findOptions={handleGetOriginLabel}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              id="origin"
                              value={originLabel?.description || ''}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id}
                                  onClick={() => {
                                    const newLabel = [
                                      {
                                        id: option.id,
                                        type: option.type,
                                        active: option.active,
                                        description: option.description,
                                      },
                                    ]

                                    reasonLabel && newLabel.push(reasonLabel)

                                    setValue('labels', newLabel, {
                                      shouldValidate: true,
                                    })
                                  }}
                                >
                                  {option.description}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                    <FormItem>
                      <FormLabel htmlFor="contact">
                        Contato responsável
                      </FormLabel>
                      <ComboBoxV3.Root
                        valueKey="name"
                        findOptions={handleGetContacts}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              id="contact"
                              value={
                                contact && !contact?.id
                                  ? 'Outro'
                                  : contact?.name || ''
                              }
                              disabled={!account}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id || 'outro'}
                                  onClick={() => {
                                    setValue(
                                      'contact',
                                      {
                                        ...(!!option?.id && {
                                          id: option.id,
                                        }),
                                        name: option.name,
                                        number: option.phones?.[0].number,
                                        countryCode:
                                          option.phones?.[0].countryCode,
                                        provinceCode:
                                          option.phones?.[0].provinceCode,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                      {contact && !contact?.id && (
                        <div className={styles.contact}>
                          <FormItem>
                            <FormLabel>Nome</FormLabel>
                            <TextField
                              {...register('contact.name')}
                              value={
                                contact && contact.name !== 'Outro'
                                  ? contact.name
                                  : ''
                              }
                            />
                          </FormItem>
                          <FormItem>
                            <FormLabel>Telefone</FormLabel>
                            <TextField
                              maxLength={15}
                              value={
                                contact
                                  ? formatGenericPhone(
                                      String(contact.provinceCode || '') +
                                        String(contact.number || ''),
                                    )
                                  : ''
                              }
                              onChange={(event) =>
                                setValue('contact', {
                                  countryCode: 55,
                                  name: contact?.name || '',
                                  provinceCode: Number(
                                    event.target?.value
                                      .replace(/\D/g, '')
                                      .substring(0, 2),
                                  ),
                                  number: Number(
                                    event.target?.value
                                      .replace(/\D/g, '')
                                      .substring(2),
                                  ),
                                })
                              }
                            />
                          </FormItem>
                        </div>
                      )}
                    </FormItem>
                    <FormItem>
                      <FormLabel htmlFor="note">Descrição do serviço</FormLabel>
                      <div className={styles.noteContent}>
                        {!!rewrittenSentence?.length && checkOverflow() && (
                          <div
                            ref={expandSentenceRef}
                            className={joinClassNames(
                              styles.outdatedSentence,
                              rewrittenSentence && styles.hasSentence,
                            )}
                          >
                            {note.length >= 400
                              ? `${note.slice(0, 400)}... `
                              : note}
                            <ButtonV2
                              type="button"
                              color="element"
                              appearance="tertiary"
                              onClick={handleExpandClick}
                              className={styles.expandSentence}
                            >
                              Expandir
                            </ButtonV2>
                          </div>
                        )}

                        <textarea
                          id="note"
                          rows={1}
                          {...register('note')}
                          value={note}
                          ref={textareaRef}
                          maxLength={5000}
                          className={joinClassNames(
                            styles.outdatedSentence,
                            rewrittenSentence && styles.hasSentence,
                            !!rewrittenSentence?.length && checkOverflow()
                              ? styles.displayNone
                              : styles.displayBlock,
                          )}
                          onChange={(event) => {
                            if (rewrittenSentence?.length) {
                              setRewrittenSentence('')
                            }
                            setValue('note', event?.target.value, {
                              shouldValidate: true,
                            })
                          }}
                        />

                        {!!rewrittenSentence?.length && note && (
                          <>
                            <div className={styles.rewrittenSentence}>
                              <DropDown.Root isOpen key={rewrittenSentence}>
                                <DropDown.Trigger>
                                  <IconButton className={styles.triggerContent}>
                                    <>
                                      <Icon
                                        name="magic-wand"
                                        width={14}
                                        height={12}
                                      />
                                      <span>{rewrittenSentence}</span>
                                    </>
                                  </IconButton>
                                </DropDown.Trigger>
                                <DropDown.Content className={styles.dropDown}>
                                  <DropDown.Item
                                    onClick={handleMagicNote.accept}
                                  >
                                    Aceitar
                                  </DropDown.Item>
                                  <DropDown.Item
                                    onClick={handleMagicNote.discard}
                                  >
                                    Descartar
                                  </DropDown.Item>
                                  <DropDown.Item
                                    onClick={handleMagicNote.rewrite}
                                  >
                                    Tentar novamente
                                  </DropDown.Item>
                                  <DropDown.Item
                                    onClick={handleMagicNote.complement}
                                  >
                                    Inserir abaixo
                                  </DropDown.Item>
                                </DropDown.Content>
                              </DropDown.Root>
                            </div>
                          </>
                        )}
                        <div className={styles.actions}>
                          <IconButton
                            onClick={handleMagicNote.rewrite}
                            isLoading={isPending}
                            disabled={!note}
                          >
                            <Icon name="magic-wand" width={20} />
                          </IconButton>
                        </div>
                      </div>
                    </FormItem>
                    <FormItem>
                      <FormLabel htmlFor="tags">Tags</FormLabel>
                      <ComboBoxV3.Root
                        valueKey="name"
                        findOptions={getServiceOrderTags}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field id="tags" value={tags} />
                            {!!watch('tags')?.length && (
                              <>
                                <ComboBoxV3.Options className={styles.options}>
                                  {watch('tags')?.map((tag) => (
                                    <Tag key={tag.name}>
                                      <span>{`${tag.name}`}</span>
                                      <Icon
                                        name="close-xlg"
                                        width={8}
                                        height={8}
                                        onClick={() =>
                                          setValue(
                                            'tags',
                                            watch('tags')?.filter(
                                              (item) => item.name !== tag.name,
                                            ),
                                            {
                                              shouldValidate: true,
                                            },
                                          )
                                        }
                                      />
                                    </Tag>
                                  ))}
                                </ComboBoxV3.Options>
                                <ButtonV2
                                  size="md"
                                  appearance="tertiary"
                                  onClick={() =>
                                    setValue('tags', [], {
                                      shouldValidate: true,
                                    })
                                  }
                                >
                                  <Icon name="delete" width={12} height={12} />
                                  Remover tudo
                                </ButtonV2>
                              </>
                            )}
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.id}
                                  shouldCloseGroup={false}
                                  onClick={() => {
                                    setValue(
                                      'tags',
                                      [
                                        ...(watch('tags') || []),
                                        {
                                          name: option.name,
                                        },
                                      ],
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                  </div>
                </section>
                <section>
                  <div className={styles.head}>
                    <span>2</span>
                    <div>
                      <Info.Root>
                        <Info.Title className={styles.headTitle}>
                          Agendamento
                        </Info.Title>
                        <Info.Content>
                          Data e hora da execução da ordem de serviço
                        </Info.Content>
                      </Info.Root>
                    </div>
                  </div>
                  <div className={styles.sectionItens}>
                    <FormItem>
                      <FormLabel htmlFor="technician">
                        Técnico responsável
                      </FormLabel>
                      <ComboBoxV3.Root
                        valueKey="name"
                        findOptions={handleGetTechnicians}
                      >
                        {({ unSelectedOptions }) => (
                          <>
                            <ComboBoxV3.Field
                              id="technician"
                              value={watch('technician.name')}
                            />
                            <ComboBoxV3.Group>
                              {unSelectedOptions?.map((option) => (
                                <ComboBoxV3.Option
                                  key={option.name}
                                  hasLabel
                                  onClick={() => {
                                    setValue(
                                      'technician',
                                      {
                                        id: option.id,
                                        name: option.name,
                                      },
                                      {
                                        shouldValidate: true,
                                      },
                                    )
                                  }}
                                >
                                  {option.name}
                                  <span>
                                    {option.isAccountTechnician &&
                                      'Responsável'}
                                  </span>
                                </ComboBoxV3.Option>
                              ))}
                            </ComboBoxV3.Group>
                          </>
                        )}
                      </ComboBoxV3.Root>
                    </FormItem>
                    {!serviceOrder?.id && (
                      <>
                        <Info.Root>
                          <Info.Title>Agendamentos</Info.Title>
                          <Info.Content>
                            {handleFormatScheduleDate()}
                          </Info.Content>
                        </Info.Root>
                        <ButtonV2
                          appearance="tertiary"
                          onClick={showScheduleModal}
                          disabled={!customer?.id || !account?.id}
                        >
                          <Icon name={scheduleDate ? 'edit' : 'add'} />
                          {scheduleDate
                            ? 'Editar agendamento da ordem de serviço'
                            : 'Agendar ordem de serviço'}
                        </ButtonV2>
                      </>
                    )}
                  </div>
                </section>
              </div>
            </div>
            <div>
              <CustomerDetails accountId={account?.id} />
            </div>
          </div>

          <footer>
            <ButtonV2 appearance="secondary" onClick={() => navigate(-1)}>
              Voltar
            </ButtonV2>
            <ButtonV2 type="submit" disabled={!isValid}>
              Salvar
            </ButtonV2>
          </footer>
        </form>
      </FormProvider>
    </>
  )
}

export default ServiceOrderForm
