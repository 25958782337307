import { FC, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ComboBoxV3,
  FormItem,
  FormLabel,
  Tag,
  Icon,
  ButtonV2,
} from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { CentralContactList } from 'services/central/types'

import styles from './styles.module.scss'
import { useGetContacts } from 'shared/hooks/services'
import { ContactQueryResponse } from 'services/contact/types'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { useDebounce } from 'shared/hooks'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { CentralContact } from './types'
import { queryPartitions } from 'services/partition'
import { ContactModal } from 'domains/customer/screens/Contacts/components'

type AddContactModalProps = {
  onClose: () => void
  onSave: (contact: CentralContactList) => void
  selectedContact?: CentralContactList
  contacts?: CentralContactList[]
}

export const AddContactModal: FC<AddContactModalProps> = ({
  onSave,
  onClose,
  selectedContact,
  contacts,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const accountId = localStorage.getItem('accountId') || ''
  const [currentContact, setCurrentContact] = useState<CentralContact>({
    id: '',
    name: '',
    code: '',
    partitions: [],
  })
  const [contactName, setContactName] = useState('')

  const customerId = localStorage.getItem('customerId') || ''

  const { contacts: contactsList, isFetchingContacts } = useGetContacts(
    {
      customerId,
      name: contactName,
    },
    !!customerId,
  )

  const contactIds = contacts?.map((contact) => contact.id)
  const handleFilter = useDebounce(setContactName)

  useEffect(() => {
    if (selectedContact) {
      setCurrentContact({
        id: selectedContact?.id || '',
        name: selectedContact?.name || '',
        code: selectedContact?.code || '',
        partitions: selectedContact?.partitions || [],
      })
    }
  }, [selectedContact])

  const [originalContactId, setOriginalContactId] = useState<string | null>(
    null,
  )

  useEffect(() => {
    if (currentContact.id && originalContactId === null) {
      setOriginalContactId(currentContact.id)
    }
  }, [currentContact.id])

  const isCodeAlreadyExists = useMemo(() => {
    const cleanedCurrentCode = currentContact.code.replace(/^0+/, '')

    return contacts?.some((contact) => {
      const cleanedCode = contact.code.replace(/^0+/, '')

      return (
        cleanedCode === cleanedCurrentCode && contact.id !== originalContactId
      )
    })
  }, [currentContact.code, contacts, originalContactId])

  useEffect(() => {
    if (location?.state?.contactName) {
      setContactName(location?.state?.contactName)
    }
  }, [location])

  return (
    <Modal
      isVisible
      onClose={onClose}
      title={selectedContact ? 'Editar usuário' : 'Novo usuário'}
    >
      <ContactModal
        isVisible={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault()
          navigate({ state: '' })
          const partitionIds = currentContact.partitions?.map(
            (partition) => partition.id,
          )

          const contactToSave = {
            id: currentContact.id,
            name: currentContact.name,
            code: currentContact.code,
            partitionIds,
          }
          onSave(contactToSave)
          setCurrentContact({
            id: '',
            name: '',
            code: '',
            partitions: [],
          })
        }}
      >
        <div className={styles.comboBoxWrapper}>
          <Combobox
            id="contactId"
            isLoading={isFetchingContacts}
            label={{ text: 'Contato' }}
            onSearch={handleFilter}
            items={parseDataToComboboxV2(
              contactsList
                ?.filter((contact) => !contactIds?.includes(contact.id))
                .sort((a, b) => a.name.localeCompare(b.name)) || [],
              'name',
            )}
            value={currentContact.name}
            onChange={(selected) => {
              const contact = selected as ComboboxItem<ContactQueryResponse>

              setCurrentContact({
                id: contact.value.id,
                name: contact.value.name,
                code: currentContact.code,
                partitions: currentContact.partitions,
              })
            }}
          />
          <Button
            type="tertiary"
            buttonTitle="Criar contato"
            onClick={() => setIsContactModalOpen(true)}
          />
          <Outlet />
        </div>
        <Input
          value={currentContact.code}
          label="Posição"
          name="code"
          id="code"
          type="number"
          onChange={(e) => {
            const value = e.target.value
            setCurrentContact((old) => ({
              ...old,
              code: value,
            }))
          }}
          errorMessage={
            isCodeAlreadyExists
              ? 'Não é possível cadastrar dois usuários na mesma posição'
              : ''
          }
        />
        <FormItem>
          <FormLabel htmlFor="partitions">Partições</FormLabel>
          <ComboBoxV3.Root
            findOptions={queryPartitions(accountId)}
            valueKey="aggregatedName"
          >
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field
                  value={currentContact.partitions}
                  id="partitions"
                />
                {!!currentContact.partitions?.length && (
                  <>
                    <ComboBoxV3.Options className={styles.options}>
                      {currentContact.partitions?.map((partition) => (
                        <Tag key={partition.id}>
                          <span>{`${partition.code} - ${partition.name}`}</span>
                          <Icon
                            name="close-xlg"
                            width={8}
                            height={8}
                            onClick={() =>
                              setCurrentContact({
                                ...currentContact,
                                partitions: currentContact.partitions?.filter(
                                  (item) => item.id !== partition.id,
                                ),
                              })
                            }
                          />
                        </Tag>
                      ))}
                    </ComboBoxV3.Options>
                    <ButtonV2
                      size="md"
                      appearance="tertiary"
                      onClick={() =>
                        setCurrentContact({ ...currentContact, partitions: [] })
                      }
                    >
                      <Icon name="delete" width={12} height={12} />
                      Remover tudo
                    </ButtonV2>
                  </>
                )}
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map((option) => (
                    <ComboBoxV3.Option
                      hasLabel={false}
                      key={option.id}
                      onClick={() => {
                        const selectedPartitions = [
                          ...currentContact.partitions,
                          option,
                        ]
                        setCurrentContact({
                          ...currentContact,
                          partitions: selectedPartitions,
                        })
                      }}
                    >
                      {option.aggregatedName}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Cancelar"
            onClick={() => {
              onClose()
              navigate({ state: '' })
            }}
            type="secondary"
          />
          <Button
            width="172px"
            buttonTitle="Salvar"
            type="primary"
            htmlType="submit"
            disabled={isCodeAlreadyExists}
          />
        </Modal.Footer>
      </form>
    </Modal>
  )
}
