import { Gallery } from '../Modals/Gallery'
import { useModal } from '../../../../context'
import { Modal } from '../../../../context/types'

const handleRenderModal = (modal: Modal) => {
  switch (modal.type) {
    case 'GALLERY':
      return <Gallery {...modal.data} />
  }
}

const ModalsContainer = () => {
  const { modal } = useModal()

  return <>{modal && handleRenderModal(modal)}</>
}

export default ModalsContainer
