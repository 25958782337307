import { useMutation, useQueryClient } from '@tanstack/react-query'
import HttpClient from '../httpClient'

import { endpoints } from './endpoints'
import buildUrl from 'utilities/buildUrl'

export const useActivatePeriodicEventControl = (
  centralId: string,
  eventId: string,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const response = await HttpClient.post(
        buildUrl({
          route: endpoints.activate,
          params: { id: eventId },
        }),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['central', centralId] })
    },
  })
}
