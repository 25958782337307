import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ButtonV2, WizardV2 } from 'components'
import { ServiceOrderDetailsProvider, ModalsProvider } from './context'
import { SOInformation, Equipments, Header } from './components'

import { Modals } from './components/SOInformation/containers'

import styles from './styles.module.scss'

const Details = () => {
  const [wizard, setWizard] = useState('so')

  const navigate = useNavigate()

  return (
    <ServiceOrderDetailsProvider>
      <main className={styles.main}>
        <div>
          <Header />
          <WizardV2.Root defaultValue="so" value={wizard} setValue={setWizard}>
            <WizardV2.List>
              <WizardV2.Trigger value="so">Informações da OS</WizardV2.Trigger>
              <WizardV2.Trigger value="equipments">
                Equipamentos
              </WizardV2.Trigger>
            </WizardV2.List>
            <ModalsProvider>
              <Modals />
              <WizardV2.Content value="so">
                <SOInformation />
              </WizardV2.Content>
              <WizardV2.Content value="equipments">
                <Equipments />
              </WizardV2.Content>
            </ModalsProvider>
          </WizardV2.Root>
        </div>

        <footer>
          <ButtonV2
            appearance="tertiary"
            onClick={() => {
              if (wizard === 'so') {
                navigate(-1)
              } else {
                setWizard('so')
              }
            }}
          >
            Voltar{wizard === 'so' && ' à lista'}
          </ButtonV2>
          {wizard === 'so' && (
            <ButtonV2 onClick={() => setWizard('equipments')}>Próximo</ButtonV2>
          )}
        </footer>
      </main>
    </ServiceOrderDetailsProvider>
  )
}

export default Details
