export function calcTimestamp(date: number, hora: string) {
  const [hours, minutes] = hora.split(':')

  const timestamp = new Date(date).setHours(Number(hours), Number(minutes))

  return timestamp
}

export const ensureMilliseconds = (date: number): number => {
  if (date.toString().length === 10) return date * 1000
  return date
}
