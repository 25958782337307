import { useQuery } from '@tanstack/react-query'

import { Result } from 'services/types'
import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { CentralRefused, CentralRefusedQuery } from './types'

export const getCentralRefused = async ({
  accountCode,
  macAddress,
}: CentralRefusedQuery) => {
  const response = await HttpClient.get<Result<CentralRefused>>(
    buildUrl({
      route: endpoints.get,
      queryParams: {
        ...(accountCode && { accountCode }),
        ...(macAddress && { macAddress }),
      },
    }),
  )

  return response.data
}

export const useGetCentralRefused = ({
  accountCode,
  macAddress,
}: CentralRefusedQuery) => {
  return useQuery({
    queryKey: ['get-central-refused', accountCode, macAddress],
    refetchInterval: 10000,
    queryFn: async () => await getCentralRefused({ accountCode, macAddress }),
  })
}
