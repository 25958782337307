import { useInfiniteQuery } from '@tanstack/react-query'
import { PatrimonyDriver } from 'services/patrimony'
import {
  flattenPages,
  getNextPageOffSet,
} from '../services/utilities/pagination'
import { PatrimonyFragment } from 'services/patrimony/types'
import { useMemo } from 'react'

export const getPatrimonyFragment = async (offset = 0, filter?: string) => {
  const response = await PatrimonyDriver.queryPatrimoniesFragmentList(
    localStorage.getItem('customerId') || '',
    {
      ...(filter && { name: filter }),
      offset,
      recordsPerPage: 15,
    },
  )
  return response
}

export const useGetPatrimonyFragment = (filter?: string) => {
  const query = useInfiniteQuery({
    queryKey: [
      'patrimony-fragment',
      localStorage.getItem('customerId'),
      filter || '',
    ],
    queryFn: ({ pageParam }) => getPatrimonyFragment(pageParam, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<PatrimonyFragment>(lastPage, allPages),
  })

  const patrimonyFragment = useMemo(
    () => flattenPages<PatrimonyFragment>(query.data),
    [query],
  )

  return { ...query, data: patrimonyFragment }
}
