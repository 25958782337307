import { ButtonV2, Icon, ModalV2 } from 'components'

import styles from './styles.module.scss'
import { PeriodicEventControl } from 'services/central/types'
import { useToast } from 'shared/hooks'
import { useActivatePeriodicEventControl } from 'services/periodicEventControl/useActivatePeriodicEventControl'

interface EnableMonitoringParametersModalProps {
  event?: PeriodicEventControl
  centralId: string
  isVisible: boolean
  onClose: () => void
}

const EnableMonitoringParametersModal = ({
  event,
  centralId,
  isVisible,
  onClose,
}: EnableMonitoringParametersModalProps) => {
  const { addToast } = useToast()

  const title =
    event?.type === 'KEEPALIVE'
      ? 'Habilitar "Keep-Alive"'
      : 'Habilitar testes automáticos'

  const content =
    event?.type === 'KEEPALIVE'
      ? 'Tem certeza que deseja habilitar o monitoramento de “Keep-alive” para esta central?'
      : 'Tem certeza que deseja habilitar o monitoramento de testes automáticos para esta central?'

  const { mutate: activatePeriodicEventControl } =
    useActivatePeriodicEventControl(centralId, event?.id ?? '')

  const enableMonitoringParameter = () => {
    activatePeriodicEventControl(undefined, {
      onSuccess: () => {
        addToast({
          message: `Central editada com sucesso`,
          type: 'success',
        })
        onClose()
      },
      onError: () => {
        addToast({
          message: `Erro ao editar central. Tente novamente.`,
          type: 'alert',
        })
      },
    })
  }

  return (
    <ModalV2.Root isOpen={isVisible} onClose={onClose}>
      <ModalV2.Content size="sm" className={styles.container}>
        <ModalV2.Title className={styles.title}>
          <Icon name="warning" width={19.79} height={17.5} />
          {title}
        </ModalV2.Title>
        {content}
        <ModalV2.Footer>
          <ModalV2.Close>
            <ButtonV2 appearance="tertiary">Cancelar</ButtonV2>
          </ModalV2.Close>
          <ButtonV2 onClick={() => enableMonitoringParameter()}>
            Habilitar
          </ButtonV2>
        </ModalV2.Footer>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default EnableMonitoringParametersModal
