import { ReactNode, createContext, useContext, FC } from 'react'

import {
  useFetchTacticals,
  useFetchQuickAlerts,
  useGetOccurrencesByUserCoverageType,
} from 'services/displacementMap'
import {
  QuickAlertResponse,
  OccurrenceMarker,
  TacticalMarker,
  VehicleIgnition,
  TacticalStatus,
} from 'services/displacementMap/types'
import { useUserInfo } from 'shared/hooks'

interface Provider {
  children: ReactNode
}

type Data<T> = {
  data: T
  isFetched: boolean
}

interface DisplacementDataContextProps {
  tacticals: Data<TacticalMarker[]>
  occurrences: Data<OccurrenceMarker[]>
  quickAlerts: Data<QuickAlertResponse[]>
}

const defaultValue = {
  data: [],
  isFetched: false,
}

export const DisplacementDataContext =
  createContext<DisplacementDataContextProps | null>({
    occurrences: defaultValue,
    quickAlerts: defaultValue,
    tacticals: defaultValue,
  })

type TacticalStatusFn = {
  speed: number
  lastPositionDate: number
  ignitionStatus: VehicleIgnition
  isPaused: boolean
}

const handleTacticalStatus = ({
  lastPositionDate,
  ignitionStatus,
  speed,
  isPaused,
}: TacticalStatusFn): TacticalStatus => {
  const limitedDate = new Date()
  limitedDate.setMinutes(limitedDate.getMinutes() + 3)

  const isCommunicating = lastPositionDate <= limitedDate.getTime()

  if (isPaused) return 'PAUSED'

  if (isCommunicating && speed > 0) {
    return 'DISPLACEMENT'
  }

  if (isCommunicating && ignitionStatus === 'OFF') {
    return 'COMMUNICATION_OFF'
  }

  if (isCommunicating && speed === 0 && ignitionStatus === 'ON') {
    return 'COMMUNICATION_ON'
  }

  return 'WITHOUT_COMMUNICATION'
}

export function useDisplacementData(): DisplacementDataContextProps {
  const context = useContext(DisplacementDataContext)
  if (!context)
    throw new Error(
      'You need to use DisplacementDataContext within a DisplacementDataProvider',
    )

  return context
}

export const DisplacementDataProvider: FC<Provider> = ({ children }) => {
  const { userInfo } = useUserInfo()

  const userId = userInfo.id
  const { data: agentDuty, isFetched: agentDutyFetched } = useFetchTacticals(
    userId,
    10000,
  )
  const { data: occurrencesByUserCoverage, isFetched: occurrenceFetched } =
    useGetOccurrencesByUserCoverageType(userId)

  const { data: quickAlerts, isFetched: quickAlertFetched } =
    useFetchQuickAlerts(userId)

  const tacticals = agentDuty
    ?.map((duty) => {
      if (duty) {
        const currentAlert = quickAlerts?.find(
          (alert) => alert.agent.id === duty?.agentId,
        )

        const tacticalFormatted: TacticalMarker = {
          id: duty.id,
          allowMultipleLinks: duty.allowMultipleLinks,
          agent: {
            name: duty.agentName,
            id: duty.agentId,
          },
          vehicle: {
            id: duty.vehicle.id,
            category: duty.vehicle.vehicleCategory,
            licensePlate: duty.vehicle.licensePlate,
          },
          alert: !!currentAlert,
          position: duty.position,
          color: duty.vehicle.iconColor,
          status: handleTacticalStatus({
            speed: duty.speed,
            lastPositionDate: duty.lastPositionDate,
            ignitionStatus: duty.vehicle.ignitionStatus,
            isPaused: duty.status === 'PAUSED',
          }),
          occurrencesLinked: duty.occurrencesLinked,
        }

        return tacticalFormatted
      }
    })
    .filter((duty) => duty !== undefined)

  const occurrences = occurrencesByUserCoverage?.map((occ) => {
    const currentTactical = tacticals?.find(
      (agent) => agent.agent.id === occ.agentId,
    )

    const occurrenceFormatted: OccurrenceMarker = {
      id: occ.id,
      number: occ.number,
      typeName: occ.typeName,
      position: occ.position,
      accountId: occ.accountId,
      stateName: occ.stateName,
      ...(currentTactical?.agent.id && {
        agent: {
          id: currentTactical?.agent.id,
          name: currentTactical?.agent.name,
        },
      }),
      tacticalId: currentTactical?.id,
      alert: !!currentTactical?.alert,
      color: currentTactical?.color,
    }

    return occurrenceFormatted
  })

  return (
    <DisplacementDataContext.Provider
      value={{
        tacticals: {
          isFetched: agentDutyFetched,
          data: tacticals || [],
        },
        occurrences: {
          isFetched: occurrenceFetched,
          data: occurrences || [],
        },
        quickAlerts: {
          isFetched: quickAlertFetched,
          data: quickAlerts || [],
        },
      }}
    >
      {tacticals && occurrences && children}
    </DisplacementDataContext.Provider>
  )
}
