import { useQuery } from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import buildUrl from '../utils/buildUrl'
import { OccurrenceOnAttendance } from './types'

const getOccurrenceOnAttendance = async (occurrenceId = '') => {
  const result = await HttpClient.get<OccurrenceOnAttendance>(
    buildUrl({
      route: endpoints.occurrence,
      params: { occurrenceId },
    }),
  )

  return result.data
}

export const useGetOccurrenceOnAttendance = (occurrenceId = '') => {
  return useQuery({
    queryKey: ['occurrence-attendance', occurrenceId],
    queryFn: async () => await getOccurrenceOnAttendance(occurrenceId),
    enabled: !!occurrenceId,
    staleTime: 0,
    refetchInterval: 10000,
  })
}
