import { useQuery } from '@tanstack/react-query'

import { Result } from 'services/types'
import buildUrl from 'utilities/buildUrl'

import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import { PeriodicEvent } from './types'

interface GetPeriodicEvent {
  centralId: string
}

export const getPeriodicEvents = async ({ centralId }: GetPeriodicEvent) => {
  const response = await HttpClient.get<Result<PeriodicEvent>>(
    buildUrl({
      route: endpoints.get,
      params: {
        centralId,
      },
    }),
  )

  return response.data
}

export const useGetPeriodicEvents = ({ centralId }: GetPeriodicEvent) => {
  return useQuery({
    queryKey: ['periodic-events', centralId],
    queryFn: async () => await getPeriodicEvents({ centralId }),
  })
}
