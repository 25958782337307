import { useRef, useState, useEffect } from 'react'
import { useToggle } from 'shared/hooks'

import { DeviceInformationCard } from 'domains/serviceOrders/components/DeviceInformationCard/DeviceInformationCard'
import { ButtonV2, Icon } from 'components'

import { sortDevices } from 'domains/serviceOrders/utilities'

import { PartitionDevice } from 'services/partition/types'
import { Partitions } from 'services/serviceOrder/types'

import styles from './DevicesInformation.module.scss'
import { ContactsModal, SecretsModal } from './components'

interface DevicesInformationProps {
  partitions: Partitions[]
  handleSelect?: (device: PartitionDevice) => void
}

export function DevicesInformation({
  partitions,
  handleSelect,
}: DevicesInformationProps): JSX.Element {
  const [sortedPartitions, setSortedPartitions] = useState<Partitions[]>()
  const [selectedPartition, setSelectedPartition] = useState<Partitions>()

  const devicesWithoutChanges =
    selectedPartition?.devices && sortDevices(selectedPartition?.devices)
  const devicesWithChanges =
    selectedPartition?.changedDevices &&
    sortDevices(selectedPartition?.changedDevices)

  const contactsModal = useToggle()
  const secretsModal = useToggle()

  const rightScrollRef = useRef<HTMLDivElement>(null)

  const hasDeviceIssues = (partition: Partitions): boolean => {
    return partition.devices.some(
      (device) => device.lowBattery || device.signal === 0,
    )
  }

  useEffect(() => {
    if (partitions) {
      const sortedPartitions = partitions
        .sort((previous, next) => {
          return Number(previous.code) - Number(next.code)
        })
        .sort((previous, next) => {
          if (hasDeviceIssues(previous) && !hasDeviceIssues(next)) {
            return -1
          }
          return 1
        })

      setSortedPartitions(sortedPartitions)
      setSelectedPartition(sortedPartitions[0])
    }
  }, [partitions])

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>Equipamentos</h1>

        {sortedPartitions && (
          <section
            className={
              sortedPartitions.length < 5
                ? styles.partitionsWrapper
                : styles.partitionsWrapperWithFade
            }
          >
            <div
              className={
                sortedPartitions.length < 5
                  ? styles.partitionsWithoutScroll
                  : styles.partitions
              }
              ref={rightScrollRef}
            >
              {sortedPartitions.map((partition) => {
                return (
                  <div
                    key={partition.id}
                    className={
                      selectedPartition === partition
                        ? styles.activePartition
                        : styles.partition
                    }
                    data-testid="partition"
                    onClick={() => {
                      setSelectedPartition(partition)
                    }}
                  >
                    <p>{partition.name}</p>
                    {(hasDeviceIssues(partition) ||
                      !!partition.changedDevices?.length) && (
                      <Icon name="exclamation" className={styles.issuesIcon} />
                    )}
                  </div>
                )
              })}
            </div>
          </section>
        )}

        <div className={styles.contactsButtonWrapper}>
          <div>
            <ButtonV2
              appearance="tertiary"
              className={styles.contactsButton}
              onClick={contactsModal.show}
              disabled={!selectedPartition?.contacts.length}
            >
              <Icon name="profile-badge" />
              Lista de contatos da partição
            </ButtonV2>
          </div>
          <div>
            <ButtonV2
              appearance="tertiary"
              className={styles.contactsButton}
              onClick={secretsModal.show}
              disabled={!selectedPartition?.contacts.length}
            >
              <Icon name="key" />
              Palavras chave
            </ButtonV2>
          </div>
        </div>

        <section className={styles.devicesWrapper} data-testid="devices">
          {devicesWithoutChanges?.length
            ? devicesWithoutChanges.map((device) => (
                <DeviceInformationCard
                  key={device.id}
                  device={device}
                  partitions={partitions}
                  onClick={(device: PartitionDevice) => handleSelect?.(device)}
                />
              ))
            : null}

          {devicesWithChanges?.length ? (
            <>
              <h1 className={styles.changesTitle}>Alterações</h1>

              {devicesWithChanges.map((device) => (
                <DeviceInformationCard
                  key={device.id}
                  device={device}
                  partitions={partitions}
                  onClick={(device: PartitionDevice) => handleSelect?.(device)}
                />
              ))}
            </>
          ) : null}
        </section>
      </div>
      {contactsModal.isVisible && (
        <ContactsModal
          onClose={contactsModal.hide}
          selectedPartition={selectedPartition}
          partitions={partitions}
        />
      )}
      {secretsModal.isVisible && (
        <SecretsModal
          onClose={secretsModal.hide}
          partitions={partitions}
          selectedPartition={selectedPartition}
        />
      )}
    </>
  )
}
