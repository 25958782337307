import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  InterventionPayload,
  OccurrenceByUserCoverageQuery,
  OccurrenceListByCoverage,
} from 'services/occurrence/types'
import OccurrenceService from 'services/occurrence'
import { Result } from 'services/types'

function createIntervention(
  occurrenceId: string,
  data: InterventionPayload,
): Promise<void> {
  return OccurrenceService.createIntervention(occurrenceId, data)
}

export const useChangeState = (
  occurrenceId = '',
  queryParams: OccurrenceByUserCoverageQuery,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: InterventionPayload) => {
      if (occurrenceId) {
        return await createIntervention(occurrenceId, data)
      }
    },
    retry: false,
    onSuccess: async (_data, variables) => {
      const previousOccurrences = queryClient.getQueryData<
        Result<OccurrenceListByCoverage>
      >(['occurrences-management', queryParams])

      if (previousOccurrences) {
        queryClient.setQueryData<Result<OccurrenceListByCoverage>>(
          ['occurrences-management', queryParams],
          {
            data: previousOccurrences.data.map((occurrence) =>
              occurrence.id === occurrenceId
                ? {
                    ...occurrence,
                    stateName: variables.attributes.stateName,
                  }
                : occurrence,
            ),
            totalElements: previousOccurrences?.totalElements,
          },
        )
      } else {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey[0] === 'occurrences-management'
          },
        })
      }
    },
  })
}
