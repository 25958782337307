export const times = {
  hours: 'Horas',
  minutes: 'Minutos',
}

export type TimeType = keyof typeof times

export interface Form {
  customer: {
    id: string
    name: string
  }
  account: {
    id: string
    aggregatedName: string
  }
  central: {
    model: {
      id: string
      name: string
    }
    manufacture: {
      id: string
      name: string
    }
  }
  module: {
    model: {
      id: string
      name: string
    }
    manufacture: {
      id: string
      name: string
    }
  }
  keepAlive?: {
    interval: number
    type?: TimeType
    failEventRecurrenceInterval: {
      time: number
      type?: TimeType
    }
  }
  automaticTest?: {
    interval: number
    type?: TimeType
    tolerance: {
      time: number
      type?: TimeType
    }
  }
}
