import { AxiosInstance } from 'axios'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import HttpClient from '../httpClient'
import { Result } from 'services/types'
import buildUrl from 'utilities/buildUrl'
import {
  flattenPages,
  getNextPageOffSet,
} from 'shared/hooks/services/utilities/pagination'
import { ConfigurationProfileResponse } from 'services/partition/types'

import {
  DeviceDetailsResponse,
  DevicePayload,
  DevicePutPayload,
  DeviceResponse,
  DevicesOutput,
  DeviceTagQuery,
  DeviceTagResponse,
  DeviceTypeFragment,
  DeviceTypeQuery,
  DeviceTypeResponse,
  EquipmentResponse,
  EquipmentsQuery,
  InfiniteDevicesQuery,
  TagsPayload,
  TagsResponse,
  UpdateDeviceRequest,
} from './types'
import { endpoints } from './endpoints'

interface DeviceDriverApi {
  update: (
    deviceId: string,
    centralId: string,
    payload: UpdateDeviceRequest,
  ) => Promise<void>
  getProfiles: (
    deviceTypeCode: string,
  ) => Promise<Result<ConfigurationProfileResponse>>
  queryDeviceTags: (
    payload: DeviceTagQuery,
  ) => Promise<Result<DeviceTagResponse>>
  queryDeviceType: (
    payload: DeviceTypeQuery,
  ) => Promise<Result<DeviceTypeFragment>>
  removeDevice: (id: string) => Promise<void>
  queryEquipments(query: EquipmentsQuery): Promise<EquipmentResponse>
}

class DeviceDriverImpl implements DeviceDriverApi {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}
  public async queryEquipments(
    query: EquipmentsQuery,
  ): Promise<EquipmentResponse> {
    const result = await this.httpClient.get<EquipmentResponse>(
      `/equipments/query`,
      {
        params: query,
      },
    )

    return result.data
  }

  public async getProfiles(
    deviceTypeCode: string,
  ): Promise<Result<ConfigurationProfileResponse>> {
    const result = await this.httpClient.get<
      Result<ConfigurationProfileResponse>
    >('device/profiles', {
      params: {
        deviceTypeCode,
      },
    })
    return result.data
  }

  public async update(
    deviceId: string,
    centralId: string,
    payload: UpdateDeviceRequest,
  ): Promise<void> {
    return this.httpClient.patch(`/device/${deviceId}/${centralId}`, payload)
  }

  public async queryDeviceTags(
    payload: DeviceTagQuery,
  ): Promise<Result<DeviceTagResponse>> {
    const result = await this.httpClient.get<Result<DeviceTagResponse>>(
      '/central/device/tag',
      {
        params: payload,
      },
    )

    return result.data
  }

  public async queryDeviceType(
    payload: DeviceTypeQuery,
  ): Promise<Result<DeviceTypeResponse>> {
    const result = await this.httpClient.get<Result<DeviceTypeResponse>>(
      '/device/type',
      {
        params: payload,
      },
    )

    return result.data
  }

  public async removeDevice(id: string): Promise<void> {
    await this.httpClient.delete(`/device/${id}`)
  }
}

export const DeviceDriver = new DeviceDriverImpl()

const getDevices = async (offset = 0, query: InfiniteDevicesQuery) => {
  const response = await HttpClient.get<Result<DevicesOutput>>(
    endpoints.getType,
    {
      params: {
        recordsPerPage: 15,
        offset,
        ...query,
      },
    },
  )
  return response.data
}

export const useInfiniteDevices = (query: InfiniteDevicesQuery) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['devices', query],
    queryFn: ({ pageParam }) => getDevices(pageParam, query),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<DevicesOutput>(lastPage, allPages),
  })

  return { ...infiniteQuery, data: flattenPages(infiniteQuery.data) }
}

const getDevice = async (deviceId = '') => {
  const response = await HttpClient.get<DeviceDetailsResponse>(
    buildUrl({ route: endpoints.get, params: { deviceId } }),
  )

  const { partitions } = response.data

  return {
    ...response.data,
    partitions: partitions.map((partition) => ({
      id: partition.id,
      aggregatedName: `${partition.code} - ${partition.name}`,
    })),
  }
}

export const useGetDevice = (deviceId = '') =>
  useQuery({
    queryKey: ['get-device', deviceId],
    queryFn: () => getDevice(deviceId),
    enabled: !!deviceId,
    gcTime: 0,
  })

const createDevice = async (data: DevicePayload) => {
  const response = await HttpClient.post<DeviceResponse>(endpoints.create, data)

  return response.data
}

const updateDevice = async (payload: DevicePutPayload) => {
  const response = await HttpClient.put<DeviceResponse>(
    buildUrl({
      route: endpoints.update,
      params: { deviceId: payload.deviceId },
    }),
    payload.data,
  )

  return response.data
}

export const useCreateDevice = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: DevicePayload) => {
      return await createDevice(data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    },
  })
}

const getDeviceTags = async ({ name = '', offset = 0 }: TagsPayload) => {
  const response = await HttpClient.get<Result<TagsResponse>>(
    buildUrl({
      route: endpoints.getTags,
      queryParams: { name, offset },
    }),
  )

  return response.data
}

export const useInfiniteTags = ({ name }: Omit<TagsPayload, 'offset'>) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['device-tags', name],
    queryFn: ({ pageParam }) =>
      getDeviceTags({ offset: pageParam as number, name }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      getNextPageOffSet<TagsResponse>(lastPage, allPages),
  })

  return { ...infiniteQuery, data: flattenPages(infiniteQuery.data) }
}

export const useUpdateDevice = (deviceId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: DevicePayload) => {
      return await updateDevice({ data: payload, deviceId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipments'] })
    },
  })
}
