import { AccountPayload } from 'services/account/types'
import HttpClient from 'services/httpClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { endpoints } from './endpoints'
import { AxiosResponse } from 'axios'

const createAccount = async (payload: AccountPayload): Promise<AxiosResponse> =>
  await HttpClient.post(endpoints.createAccount, payload)

export const usePostAccount = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: AccountPayload) => {
      await createAccount(payload)
    },
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries({
        queryKey: ['get-patrimony', payload.patrimonyId],
      })
      queryClient.invalidateQueries({ queryKey: ['accounts'] })
    },
  })
}
