import { ComponentProps, forwardRef } from 'react'
import fieldStyles from '../field.module.scss'
import styles from './styles.module.scss'

const Input = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<'input'>, 'type'>
>(({ className, onKeyDown, ...props }, ref) => (
  <input
    {...props}
    ref={ref}
    type="number"
    onKeyDown={(event) => {
      onKeyDown?.(event)

      if (['e', 'E', '+', '-'].includes(event.key)) {
        return event.preventDefault()
      }

      return event
    }}
    className={[styles.input, fieldStyles.field, className].join(' ')}
  />
))

Input.displayName = 'Input'

export default Input
