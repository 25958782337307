import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useToggle, useUserInfo } from 'shared/hooks'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { DropDown, Icon, IconButton, ModalV2 } from 'components'
import {
  serviceOrderDetailsReport,
  serviceOrderFormReport,
} from 'services/report/ServiceOrder'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import { Execute, Finish, Pause, Schedule, ValidateSO } from './components'
import { ATTENDANCE_ROLE } from 'services/coverages'

type Loading = {
  form?: boolean
  details?: boolean
}

const Actions = () => {
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()
  const { serviceOrderId } = useParams()
  const { serviceOrder } = useServiceOrderDetails()

  const { replaceUrl } = usePersistentTab('service-order')

  const {
    hide: hideScheduleModal,
    show: showScheduleModal,
    isVisible: isScheduleModalOpen,
  } = useToggle()

  const [downloadLoading, setDownloadLoading] = useState<Loading>({
    details: false,
    form: false,
  })

  const hasServiceOrderAttendantRole = useMemo(
    () =>
      userInfo?.userCoverages?.primary?.some(
        (coverage) =>
          coverage.coverageType === ATTENDANCE_ROLE.SERVICE_ORDER_ATTENDANT,
      ),
    [userInfo],
  )

  if (!serviceOrder) return null

  const OS_MANAGER_URL = `https://os-manager.orsegups.com.br/ubisafe/${serviceOrder.number}`

  const canEdit = !['FINISHED', 'CANCELED'].includes(serviceOrder.status)

  const canExecute = [
    'OPEN',
    'SCHEDULED',
    'RESCHEDULED',
    'PAUSED',
    'DISAPPROVED',
  ].includes(serviceOrder.status)

  const canReschedule = [
    'SCHEDULED',
    'RESCHEDULED',
    'PAUSED',
    'DISAPPROVED',
  ].includes(serviceOrder.status)

  const handleDownload = (url: string) => {
    const tempLink = document.createElement('a')
    tempLink.href = url
    tempLink.setAttribute('download', `report_${serviceOrderId}.pdf`)

    document.body.appendChild(tempLink)
    tempLink.click()

    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(url)
  }

  const handleDownloadDetails = async () => {
    setDownloadLoading((old) => ({ ...old, details: true }))

    if (!downloadLoading.details) {
      const response = await serviceOrderDetailsReport({
        serviceOrderId,
        userId: userInfo.id,
      })

      setDownloadLoading((old) => ({ ...old, details: false }))

      const url = URL.createObjectURL(response)

      handleDownload(url)
    }
  }

  const handleDownloadForm = async () => {
    setDownloadLoading((old) => ({ ...old, form: true }))

    if (!downloadLoading.form) {
      const response = await serviceOrderFormReport({
        serviceOrderId,
        userId: userInfo.id,
      })

      setDownloadLoading((old) => ({ ...old, form: false }))

      const url = URL.createObjectURL(response)

      handleDownload(url)
    }
  }

  return (
    <>
      <DropDown.Root>
        <DropDown.Trigger>
          <IconButton>
            <Icon name="menu-kebab" color="element" />
          </IconButton>
        </DropDown.Trigger>
        <DropDown.Content>
          {serviceOrder.status === 'IN_VALIDATION' && (
            <DropDown.Sub>
              <DropDown.SubTrigger>Validar</DropDown.SubTrigger>
              <DropDown.SubContent>
                <ModalV2.Root>
                  <ModalV2.Trigger disabled={!hasServiceOrderAttendantRole}>
                    <DropDown.Item>Aprovar</DropDown.Item>
                  </ModalV2.Trigger>
                  <ValidateSO status="APPROVE" />
                </ModalV2.Root>
                <ModalV2.Root>
                  <ModalV2.Trigger>
                    <DropDown.Item>Reprovar</DropDown.Item>
                  </ModalV2.Trigger>
                  <ValidateSO status="DISAPPROVE" />
                </ModalV2.Root>
              </DropDown.SubContent>
            </DropDown.Sub>
          )}

          {serviceOrder.status === 'IN_PROGRESS' && (
            <>
              <ModalV2.Root>
                <ModalV2.Trigger>
                  <DropDown.Item>Concluir</DropDown.Item>
                </ModalV2.Trigger>
                <Finish />
              </ModalV2.Root>
              <ModalV2.Root>
                <ModalV2.Trigger>
                  <DropDown.Item>Pausar</DropDown.Item>
                </ModalV2.Trigger>
                <Pause />
              </ModalV2.Root>
            </>
          )}

          {canExecute && (
            <ModalV2.Root>
              <ModalV2.Trigger>
                <DropDown.Item>Executar</DropDown.Item>
              </ModalV2.Trigger>
              <Execute />
            </ModalV2.Root>
          )}

          {serviceOrder.status === 'OPEN' && (
            <>
              <DropDown.Item onClick={showScheduleModal}>Agendar</DropDown.Item>
              {isScheduleModalOpen && <Schedule onClose={hideScheduleModal} />}
            </>
          )}

          {canReschedule && (
            <>
              <DropDown.Item onClick={showScheduleModal}>
                Reagendar
              </DropDown.Item>
              {isScheduleModalOpen && <Schedule onClose={hideScheduleModal} />}
            </>
          )}

          <DropDown.Separator />

          {canEdit && (
            <DropDown.Item
              onClick={() => navigate(`/so/update/${serviceOrderId}`)}
            >
              Editar
            </DropDown.Item>
          )}

          <DropDown.Sub>
            <DropDown.SubTrigger>Exportar</DropDown.SubTrigger>
            <DropDown.SubContent>
              <DropDown.Item onClick={() => handleDownloadForm()}>
                Formulário do técnico
              </DropDown.Item>
              {!['SCHEDULED', 'RESCHEDULED', 'OPEN'].includes(
                serviceOrder.status,
              ) && (
                <DropDown.Item onClick={() => handleDownloadDetails()}>
                  Relatório do cliente
                </DropDown.Item>
              )}
            </DropDown.SubContent>
          </DropDown.Sub>
          <DropDown.Separator />

          <DropDown.Item onClick={() => replaceUrl(OS_MANAGER_URL)}>
            {`Ir para "Compras/FAP"`}
          </DropDown.Item>
        </DropDown.Content>
      </DropDown.Root>
    </>
  )
}

export default Actions
